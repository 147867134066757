<template>
  <div>
    <!-- v-if="filterList.length" -->
    <cccAutoRecommend
      :sticky-top="stickyTop"
      code="goodsDetail"
      :poskey="poskey"
      class="index-mgds-recommend"
      :ccc-config="cccRequestConfig"
      :config="config"
      :holder-config="{
        show: !hideHolder
      }"
      :pde-extend-params="{
        cate_ids: detail.cat_id,
        goods_ids: detail.goods_id
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import cccAutoRecommend from 'public/src/pages/goods_detail/components/Recommend/recommend/cccAutoRecommend.vue'

export default {
  name: 'ProductCccRecommend',
  components: {
    cccAutoRecommend
  },
  props: {
    poskey: {
      type: String,
      required: true
    },
    /**
     * @param {String} 在页面中出现的位置code：DEFAULT, GOODS-1, GOODS-2, GOODS-3, GOODS-4
     */
    positionCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    hideHolder: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      cccRequestConfig: {
        params: {
          pageType: 'GOODS',
          positionCode: this.positionCode
        }
      }
    }
  },
  computed: {
    // ...mapState('productDetail', ['detail']),
    ...mapState('newProductDetail/common', ['isFoldDetail']),
    ...mapGetters('newProductDetail/common', ['detail']),
    stickyTop () {
      if (!this.isFoldDetail) return 2.3
      return 1.1467
    },
    config() {
      return {
        product: {
          // 对应商品项的配置
          items: {},
          filter: this.filterGoodsList
        }
      }
    }
  },
  methods: {
    // 过滤商品
    filterGoodsList(list) {
      if (this.orderProductInfo.goodsIds.length) {
        return list.filter(i => this.detail.goods_id != i.goods_id)
      }
      return list
    },
  },
}
</script>
