var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'short-brand-container': true,
    'follow-have-list': _vm.isStore && _vm.hasRecommendList,
    'local-type': _vm.isStore,
    'store-left-pad': _vm.showStoreQualityTags,
  }},[_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({ level: 1, pos: [0] }),expression:"{ level: 1, pos: [0] }"}],staticClass:"mgds-brand",class:{
      'j-sa-brand-store local-shop': _vm.isStore,
      'j-sa-brand-store-view-products': _vm.isStore
    },attrs:{"aria-label":_vm.sign_data.name,"data-name":_vm.sign_data.name,"data-brand_code":_vm.sign_data.id,"data-goods_id":_vm.sign_data.goods_id,"data-brand_type":_vm.sign_data.type,"data-designer_id":_vm.sign_data.designerId,"data-sc_id":_vm.sign_data.scId,"data-abt_ana_info":_vm.extAnalysisInfo.abtAnaInfo,"data-brand_info":_vm.extAnalysisInfo.brandInfo,"data-brand_label":_vm.extAnalysisInfo.brandLabel,"data-button_label":_vm.extAnalysisInfo.buttonLabel,"data-store_popularity":_vm.extAnalysisInfo.storePopularity,"data-flash_sale_stype":_vm.flashEntryMap[_vm.flashEntryType] || 0,"data-is_alone_store_brand":false,"data-store_quality_label":_vm.store_quality_label,"role":"text"},on:{"click":function($event){return _vm.viewProducts()}}},[_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({ level: 1, pos: [0] }),expression:"{ level: 1, pos: [0] }"}],class:['mgds-brand__detail', { center: !_vm.descriptionsShow }],attrs:{"aria-label":_vm.sign_data.name,"role":"text","aria-hidden":"true"}},[(_vm.showBrandLogo)?_c('div',{staticClass:"short-brand-container__detail-logo"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.sign_data.logo}})]),_vm._v(" "),(_vm.formatShopScore)?_c('div',{staticClass:"rate-box"},[_c('Icon',{staticClass:"rate-box-star",attrs:{"name":"sui_icon_star_5_15px_2","size":"14px","color":"#FACF19"}}),_vm._v("\n          "+_vm._s(_vm.formatShopScore)+"\n        ")],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mgds-brand__detail-info"},[_c('div',{staticClass:"mgds-brand__detail-info-title"},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"text_first",class:{
                'text_first_label': _vm.hasLabelsData
              }},[_vm._v(_vm._s(_vm.sign_data.name))]),_vm._v(" "),(_vm.showStoreAtmosphere)?_c('StoreAtmosphereSwiper',{staticClass:"short-brand-container__atmosphere",attrs:{"hasFollowInfo":_vm.hasLabelsData,"position":"showscroll"},scopedSlots:_vm._u([{key:"followInfo",fn:function(){return [(_vm.hasLabelsData)?_c('BrandSeriesTips',{staticClass:"brand-short-tips split-top",attrs:{"tips-data":_vm.tipsData,"display-three":true,"in-fashion-store-img":_vm.in_fashion_store_img,"in-quality-store-img":_vm.in_quality_store_img,"text-combo":_vm.sign_board_text}}):_vm._e()]},proxy:true}],null,false,2824440844)}):_vm._e(),_vm._v(" "),(!_vm.showStoreAtmosphere && _vm.hasLabelsData)?_c('BrandSeriesTips',{staticClass:"brand-short-tips split-top",attrs:{"tips-data":_vm.tipsData,"display-three":true,"in-fashion-store-img":_vm.in_fashion_store_img,"in-quality-store-img":_vm.in_quality_store_img,"text-combo":_vm.sign_board_text}}):_vm._e()],1)]),_vm._v(" "),(_vm.descriptionsShow && !_vm.showStoreSign)?[(_vm.storeRelativeInNewStyle)?_c('div',{staticClass:"desc-new",staticStyle:{"margin-top":"4px"}},[_vm._v("\n            "+_vm._s(_vm.sign_data.desc)+"\n          ")]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.storeRelativeInNewStyle)?_c('div',{staticClass:"short-brand-btn"},[_c('StoreFollowBtnBox',{attrs:{"scene":"detail","class-name":"follow-btn-box_new","store-id":_vm.storeId,"follow-state":_vm.storeFollowState,"auto-login":true,"text-combo":_vm.sign_board_text,"showLoginModal":_vm.show_login_modal},on:{"state-changed":_vm.handleFollowStateChange,"state-changing":_vm.handleFollowStateChanging,"state-change-fail":_vm.handleFollowStateChangeFail}},[_c('template',{slot:"button"},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-6-4-63', data: _vm.followBtnAnalysisData }),expression:"{ id: '1-6-4-63', data: followBtnAnalysisData }"}],class:{
                  'follow-btn': true,
                  'show-symbol': _vm.followBtnShowSymbol,
                  followed: _vm.storeFollowState
                },on:{"click":_vm.followBtnClick}},[_c('FollowStoreIcon'),_vm._v(" "),_c('b',{staticClass:"whitespace"}),_vm._v(" "),_c('span',{staticClass:"follow-btn-text"},[_vm._v(_vm._s(_vm.storeFollowBtnText || 'Follow'))]),_vm._v(" "),(!_vm.storeFollowState && _vm.store_follow_info.followCount)?_c('b',{staticClass:"whitespace"}):_vm._e(),_vm._v(" "),(!_vm.storeFollowState && _vm.store_follow_info.followCount)?_c('span',{staticClass:"follow-count"},[_vm._v("("+_vm._s(_vm.store_follow_info.followCount)+")")]):_vm._e()],1)])],2),_vm._v(" "),_c('div',{staticClass:"follow-btn-box_new follow-btn-allitems",attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.viewProducts(_vm.flashEntryType === 'showflashsale' || _vm.flashEntryType === 'showflashbutton' ? 'promotion' : 'items')}}},[(_vm.flashEntryType === 'showflashsale')?_c('div',{staticClass:"store_flash-sale"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"12","viewBox":"0 0 12 12","fill":"none"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z","fill":"#161616"}})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.sign_board_text.flashSaleLang || 'Flash Sale'))])]):_vm._e(),_vm._v(" "),(_vm.flashEntryType === 'showflashbutton')?_c('div',{staticClass:"follow-btn with-flash-icon"},[_c('img',{staticClass:"flash-gif-icon",attrs:{"src":`${_vm.PUBLIC_CDN}/pwa_dist/images/flash-sale/flash-gif-097c8939e4.gif`}}),_vm._v(" "),_c('div',{ref:"flashScrollBox",staticClass:"flash-scroll-wrap",class:{
                  'has-animation': _vm.hasAnimation
                }},[_c('span',{ref:"flashScrollContent",staticClass:"flash-scroll-item",class:{
                    'has-animation': _vm.hasAnimation
                  },style:({'animation-duration': _vm.millisecond })},[_vm._v("\n                  "+_vm._s(_vm.sign_board_text.storeFlashLang || 'Store Flash Sale')+"\n                ")])])]):_c('div',{staticClass:"follow-btn"},[_c('p',{staticClass:"follow-btn-text"},[_vm._v(_vm._s(_vm.sign_board_text.allItemLang || 'All Items')),(_vm.store_follow_info.salesVolume)?_c('b',{staticClass:"whitespace"}):_vm._e(),_vm._v(" "),(_vm.store_follow_info.salesVolume)?_c('span',{staticClass:"follow-count"},[_vm._v("("+_vm._s(_vm.store_follow_info.salesVolume)+")")]):_vm._e()])])])],1):_vm._e()],2)])]),_vm._v(" "),(_vm.showStoreQualityTags)?_c('StoreQualityTags',{attrs:{"store-quality-list":_vm.store_quality_list},on:{"goToStore":function($event){return _vm.viewProducts()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }