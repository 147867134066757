var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_vm.isFour?{ id: '1-6-4-88', data: _vm.storeSignExtraData }:{}),expression:"isFour?{ id: '1-6-4-88', data: storeSignExtraData }:{}"}],staticClass:"signboard-slide__swiper j-da-event-box",attrs:{"data-feed_type":_vm.storeJumpType === 'three' ? 2 : ''}},[_c('swiper-container',{ref:"swiper",staticClass:"s-swiper-wrapper",attrs:{"init":"false"}},_vm._l((_vm.filterProducts),function(item,index){return _c('swiper-slide',{key:`signboard-slide__item-${index}`,staticClass:"signboard-slide__item s-swiper-slide",class:{ 
        'signboard-slide__item-four': _vm.isFour,
        'signboard-slide__item-fourshort': _vm.storeJumpType==='fourshort'&& _vm.isStore,
        'signboard-slide__item-fourtall': _vm.storeJumpType==='fourtall' && _vm.isStore,
        'short-brand': _vm.store_price_in,
      }},[_c('SProductItemV3',{ref:"goodsItem",refInFor:true,attrs:{"item":item,"index":index,"goods-item-info":_vm.goodsItemInfo,"language":_vm.itemLanguage,"config":_vm.getPrdItemConfig(index),"constant-data":_vm.constantData,"column":_vm.isFour? 4.3 : 3.3,"cropRate":_vm.cropRate},on:{"clickItem":function($event){_vm.isFour ? _vm.goToStore(item) : _vm.clickItemHandle($event)},"openQuickAdd":_vm.clickAdd},scopedSlots:_vm._u([{key:"imgTopLeft",fn:function(){return [(item.is_flash_sale > 0 && !_vm.isHitComplianceMode)?_c('FlashProductDiscount',{attrs:{"item":item}}):_vm._e()]},proxy:true},(_vm.storeJumpType==='fourtall'&& _vm.isStore)?{key:"cardPriceTop",fn:function(){return [_c('ProductCardPriceSimple',{attrs:{"goodsInfo":item,"language":_vm.itemLanguage,"direction":"column","config":_vm.cPriceConfig,"isPaid":_vm.isPaidUser}})]},proxy:true}:null,(_vm.storeJumpType==='fourshort'&&!_vm.haveMore(index)&& _vm.isStore)?{key:"imgBottom",fn:function(){return [_c('ProductCardPriceSimple',{attrs:{"goodsInfo":item,"language":_vm.itemLanguage,"direction":"column","config":_vm.cPriceConfig,"isPaid":_vm.isPaidUser}})]},proxy:true}:null],null,true)}),_vm._v(" "),(_vm.haveMore(index))?_c('a',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: _vm.productItemsConfig.itemDAEventExposeViewMoreId,
          code: 'DetailStoreListMoreKey',
          data: {
            ..._vm.productItemsConfig.sa,
            src_identifier: _vm.moreSrcIdentifier
          }
        }),expression:"{\n          id: productItemsConfig.itemDAEventExposeViewMoreId,\n          code: 'DetailStoreListMoreKey',\n          data: {\n            ...productItemsConfig.sa,\n            src_identifier: moreSrcIdentifier\n          }\n        }"}],staticClass:"signboard-slide__mask",attrs:{"aria-label":_vm.language.SHEIN_KEY_PWA_22046},on:{"click":function($event){_vm.isFour ? _vm.goToStore(item,true) : _vm.clickShowMore(item)}}},[_c('div',{staticClass:"mask-content"},[_c('i',{staticClass:"iconfont icon-liebiaoviewmore icon"}),_vm._v(" "),_c('div',{staticClass:"mask-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_22046))])])]):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }