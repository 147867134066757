import RecommendService from 'public/src/pages/goods_detail/components/Recommend/service/index.js'
import schttp from 'public/src/services/schttp'
import { getCccAnalysisRecInfo, getAnalysisAbtInfo } from 'public/src/pages/goods_detail/components/Recommend/analysis/utils.js'
const { langPath = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
let timer = null

/**
 * 自动化推荐组件
 * 获取ccc配置 & 获取单个推荐的配置
 */
export class CccRecommendUIService {
  constructor({ abtInfo, channelId }) {
    this.rulesInfo = null
    this.abtInfo = abtInfo

    this.handleAbtRuleInfo(abtInfo, channelId)
  }

  /**
   * 处理abt信息
   * @param {*} abtInfo 
   */
  handleAbtRuleInfo(abtInfo, channelId) {
    if (!abtInfo || !abtInfo.param) return
    let rulesInfo = {}
    try {
      let currentChannelAbtInfo = ''
      const hasChannelId = abtInfo?.param?.includes('channelId')
      if(hasChannelId) {
        // const channelKey = `${lang}-pwa-home-channel`
        // const channelId = sessionStorage.getItem(channelKey) || contextForSSR.channelId // 首次进入页面不能获取到频道id，因为未发生tab切换行为，取首页
        const channelAbtInfo = (abtInfo.param.split(',') || []).find(item =>{
          if(item?.split('@')?.[0] === `channelId=${ channelId }`){
            return true
          }
        })
        // if(!channelAbtInfo) return
        // abtInfo.param = channelAbtInfo?.split('@')?.[1] || '' // 根据channelId取到CCC配置的对应频道的参数
        currentChannelAbtInfo = channelAbtInfo?.split('@')?.[1] || '' // 根据channelId取到CCC配置的对应频道的参数
      }
      //const param = hasChannelId ? (currentChannelAbtInfo?.split('&') || []) : (abtInfo?.param?.split('&') || [])
      const param = (hasChannelId ? currentChannelAbtInfo : abtInfo?.param)?.split('&') || []
      const rulesIdStr = param[1] || ''
      rulesInfo = rulesIdStr.split('#').reduce((acc, cur, index) => {
        const curItem = {}
        cur.split(',').forEach((item) => {
          // 如果配置的emarsys参数
          if (item.includes('emarsys') && !item.includes('=')) {
            curItem[`key_${index}`] = item
            return
          }
          const ruleArr = item.split('=')
          curItem[ruleArr[0]] = ruleArr[1]
        })
        acc[index] = curItem
        return acc
      }, {})
    } catch (e) {
      console.log(e)
    }
    this.rulesInfo = rulesInfo
  }

  /**
   * 请求ccc配置的楼层信息
   * @param {*} data 
   */
  async getCccConfig(data = {}) {
    if (!this.abtInfo || !this.abtInfo.param) return
    // 1. abt 分支
    const abtBranch = this.abtInfo.param.split('&').length > 0 && this.abtInfo.param.split('&')[0] || ''
    const cccConfig = await schttp({
      url: '/api/recommend/cccPositionConfig/get',
      method: 'POST',
      data: {
        ...data,
        abtBranch: abtBranch.replace(/#/g, ',')
      }
    })
    if (!cccConfig || Number(cccConfig.code) !== 1000 || !cccConfig.data || !cccConfig.data.content || cccConfig.data.content.length <= 0) return

    return cccConfig.data
  }

  /**
   * 获取请求的rulid
   */
  getRuleId (index, sceneId) {
    const ruleData = this.rulesInfo?.[index]
    return ruleData && (ruleData[`rule_id_${sceneId}`] || ruleData[`key_${index}`]) || ''
  }

}


const getTagsLastCate = (array, result) => {
  if (!array.length) return result
  for (let index = 0; index < array.length; index++) {
    const data = array[index]
    if (data.children.length) {
      getTagsLastCate(data.children, result)
    } else {
      result instanceof Array && result.push(data)
    }
  }

  return result
}

// 一键购资格校验
export async function checkOneClickPayQualification(data) {
  try {
    const res = await schttp({
      url: '/api/ocp/productListCheckQualificationForNoGoods/get',
      method: 'POST',
      data,
    })
    return Promise.resolve(res?.info || {})
  } catch(e) {
    return Promise.resolve({})
  }
}

/**
 * 单个推荐组件的推荐实例
 */

/**
 * 1. 有 tab 先请求tab数据
 * 2. 选中tab 之后再请求tags数据（如果有）/ product
 */
export default class RecommendUIService {
  /**
   * {
   *  request: {
   *    type: 'request',
   *    params: {},
   *    url: ''
   *  } // 请求配置
   *  backupRequest: {} // 容错的配置
   * }
   */
  constructor({
    config = {},  // { code, cccRecommend }
    analysis = {},
    tab = null,
    product = null,
    title = null,
    multiProduct = null,
    tags = null
  } = {}, RecommendDataService = null) {

    this.analysis = analysis
    this.config = config

    this.tab = tab
    this.product = product
    this.title = title
    this.tags = tags
    this.multiProduct = multiProduct

    // 推荐服务
    this.RecommendDataService = RecommendDataService || new RecommendService()
  }

  async init() {
    const dataConfig = {}
    let productExtParams = {}
    // 1. 有tab先请求tab
    const tabResult = await this.__initTabData()
    if (tabResult) {
      const { products: tabData, isFaultTolerant } = tabResult
      if (!tabData.length) return
      // 1.1 tab数据给配置
      dataConfig.tab = {
        data: tabData,
        isFaultTolerant: Boolean(isFaultTolerant),     // 当前tab是否是容错的数据
      }
      // 1.2 tab的第一个tab数据给商品请求参数
      const tab0 = tabData[0]
      productExtParams = this.productExtParams(tab0)
    }

    // 2. 请求商品信息
    if (this.multiProduct || this.product && (this.product.request || this.product.backupRequest)) {
      const { sourceConfig, products, end, dataType, isFaultTolerant, total } = await this.getProductData(Object.keys(productExtParams).length ? productExtParams : undefined)
      const { hideList, resetTabData } = sourceConfig || {}
      // 是否过滤当前的列表
      const canIfilterCurList = typeof hideList === 'function' && hideList(products)
      if (!canIfilterCurList) {
        // 2.1 商品数据给配置
        dataConfig.product = {
          end,
          dataType,
          isFaultTolerant,  // 当前商品是否是容错的数据
          data: products,
          total,
        }
      } else {
        // 如果有tab
        if (dataConfig.tab?.data?.length > 1) {
          // 2.2 如果需要过滤当前tab
          dataConfig.tab.data.shift()
          // 2.2.1 tab数据刷新
          if (typeof hideList === 'function') {
            dataConfig.tab.data = resetTabData(dataConfig.tab.data, canIfilterCurList)
          }
          // 2.2.2 tab的第一个tab数据给商品请求参数
          const tab0 = dataConfig.tab.data[0]
          productExtParams = this.productExtParams(tab0) // 当前的tab对应的信息
        }

        const { products, end, dataType, isFaultTolerant } = await this.getProductData(Object.keys(productExtParams).length ? productExtParams : undefined)
        dataConfig.product = {
          end,
          dataType,
          isFaultTolerant,  // 当前商品是否是容错的数据
          data: products,
          total,
        }
      }
    }

    // 3. 标题
    if (this.title) {
      dataConfig.title = {
        data: this.title
      }
    }

    return dataConfig
  }

  productExtParams (tabData) {
    let productExtParams = {}
    tabData._id && (productExtParams._id = tabData._id)
    if (!tabData || !tabData.sku_cate_id) return productExtParams
    if (tabData.sourceData == 'node') {
      return productExtParams
    } else if (tabData.sourceData != 'tags') {
      productExtParams.cateIds = tabData.sku_cate_id
      productExtParams.contextParams = {
        cate_ids: tabData.sku_cate_id
      }
    } else {
      // 如果是tags 相当于当前商品的筛选
      productExtParams.resultType = 'goods_filter'
      productExtParams.filter_cate_id = tabData.sku_cate_id
      productExtParams.contextParams = {
        filter_cate_id: tabData.sku_cate_id
      }
    }
    return productExtParams
  }

  async __initTabData() {
    if (!this.tab) return
    const tabRequest = this.tab.request
    let result = {}
    if (tabRequest) {
      const isTagsData = tabRequest.sourceData == 'tags'  // 一个预知的数据结果，原本是tags的数据，但是需要用tab来展示
      if (!isTagsData) {
        result = await this.getTabData()
      } else {
        // tags 的数据结构不一样
        this.tags = this.tab
        result = await this.getTagsData()
        result.products = result.products.map(i => {
          return {
            sku_cate_id: i.cat_id,
            sku_cate_nm: i.cat_name,
            sourceData: 'tags'
          }
        })
      }
    }

    const { products: tabData = [] } = result

    let tabList = this.tab.data ? this.tab.data.concat(tabData) : tabData
    const { filter } = this.tab.config || {}
    if (typeof filter == 'function') tabList = filter(tabList) || []

    return {
      ...result,
      products: tabList
    }
  }

  /**
   * 获取推荐数据
   * @param {*} param0 
   * @param {*} params 
   * @returns 
   */
  async fetchRecommend({ request, backupRequest = null } = {}, extendParams) {
    const { query = {} } = request || {}
    const extendConfig = Object.assign({}, {
      limitNum: query.reqNum || 100,
      limit: query.limit || 100,
    }, extendParams || {})

    const { recommendInfo } = await this.RecommendDataService.fetchRecommendData({ 
      request: request,
      backupRequest: backupRequest,
    }, extendConfig)

    return {
      ...recommendInfo,
      ...extendConfig
    }
  }

  /**
   * 请求参数的扩展
   * @param {*} param0 
   * @param {*} params 
   * @returns 
   */
  extendRequestParams({ request, backupRequest = null, ...arg } = {}, params) {
    if (params) {
      if (request) {
        // 设置了增加原子的信息请求
        if (request.atomic) {
          // 设置了参数
          if (request.params) {
            const { resultType } = params
            resultType && (request.params.resultType = resultType)
            request.params.contextParams = {
              ...(request.params.contextParams || {}),
              ...(params.contextParams || {}),
            }
          }
        } else {
          request.params && (request.params = Object.assign(request.params, params))
        }
      }
      backupRequest && backupRequest.params && (backupRequest.params = Object.assign(backupRequest.params, params))
    }
    return {
      request,
      backupRequest,
      ...arg
    }
  }

  /**
   * 
   * @param {*} params 
   * @returns tab Array
   */
  getTabData(params, extendParams) {
    return this.fetchRecommend(
      params ? this.extendRequestParams(this.tab, params) : this.tab, 
      extendParams
    )
  }

  /**
   * 标签云类型数据 （运营配置相关标签）
   * @param {*} params 
   * @param {*} extendParams 
   * @returns 
   */
  async getTagsData(params, extendParams) {
    let products = []
    const { responseInfo, ...arg } = await this.fetchRecommend(
      params ? this.extendRequestParams(this.tags, params) : this.tags, 
      extendParams
    )
    if (responseInfo?.info?.extra_data?.categories) {
      const categories = responseInfo?.info?.extra_data?.categories || []
      products = getTagsLastCate(categories, [])
    }

    return {
      ...arg,
      products,
    }
  }

  getRenderProductConfig(params) {
    let productConfig = this.product
    // 如果有配置多个商品选择
    if (this.tab && this.multiProduct && this.multiProduct.length) {
      const fn = params._id ? (n) => { return n._id == params._id } : (n) => { return !n._id }
      const da = this.multiProduct.filter(fn)
      productConfig = da[0] || this.multiProduct[0]
    }

    return productConfig
  }

  /**
   * 获取商品信息
   * @param {*} params 
   * @returns 
   */
  async getProductData(params, extendParams) {
    const productConfig = this.getRenderProductConfig(params)

    // 请求前参数处理
    if (typeof productConfig?.config?.beforeRequest === 'function') {
      productConfig.config.beforeRequest(productConfig, params)
    }

    // 删除无用的参数
    if (params && params._id) {
      delete params._id
    }

    // 商品的辅助信息
    let itemConfig = productConfig.config?.items || {}
    const { sceneConfig = {} } = this.config || {}

    const { products: list, isFaultTolerant, dataType, error = 0, ...arg } = await this.fetchRecommend(
      params ? this.extendRequestParams(productConfig, params) : productConfig, 
      Object.assign({
        rowNum: productConfig.rowNum || 2,
        pageKey: sceneConfig?.pageKey || '',
        itemConfig,
      }, extendParams || {})
    )

    let products = list
    let sourceConfig = productConfig.config || {}
    const { filter } = sourceConfig
    if (typeof filter === 'function') products = filter(products)

    return {
      sourceConfig,
      products,
      end: error === 1 ? false : products.length === 0,
      isFaultTolerant,
      dataType,
      error,
      ...arg
    }
  }

  /**
   * 自动化推荐组件的埋点信息
   * @param {*} 
   * @returns 
   */
  getCccAnalysisInfo() {
    const { config } = this
    const { code, index = 0, poskey, ruleId: cccRuleId, pageId, floor, comId, sku_cate_nm, sku_cate_id } = config
    const { gaAbt = 0 } = getAnalysisAbtInfo(poskey)

    return getCccAnalysisRecInfo({
      code,
      poskey,
      ruleId: cccRuleId,
      pageId,
      floorId: floor,
      comId,
      gaAbt,
      index: index + 1,
      cateNm: sku_cate_nm,
      cateId: sku_cate_id
    })
  }

  // 点击跳转详情
  onSliderItemClick(payload) {
    const { goodsId } = payload
    const { title, product } = this
    const { request } = product

    // this.getRenderProductConfig()
    const { ici } = this.getCccAnalysisInfo()

    // ccc推荐组件~才需要scici
    const params = request.params
    let linkParams = [
      `ici=${ici}`,
      `scene_id=${params.scene_id}`,
      `rule_id=${params.rule_id}`,  // 参数值
      `cate_ids=${request.atomic ? params.contextParams.cate_ids : params.cateIds}`,
      `goods_ids=${(request.atomic ? params.contextParams.goods_ids : params.goodsIds) || ''}`,
      `page_title=${title?.name || ''}`
    ]
    if (goodsId) linkParams.push(`top_goods_id=${goodsId}`)
    // fix: 跳转太快，埋点发送有可能被取消
    const { tplActivityName } = this.config
    const href = `${langPath}/campaign/${tplActivityName}?${linkParams.join('&')}`
    clearTimeout(timer)
    timer = setTimeout(() => {
      location.href = href
    }, 1000)
  }

}
