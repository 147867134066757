<template>
  <div
    class="store-quality-tags"
    @click="goToStore"
  >
    <swiper-container
      ref="storeQualitySwiper"
      init="false"
      class="store-quality-tags__swiper"
    >
      <swiper-slide
        v-for="(item, key) in storeQualityList"
        :key="key"
        class="store-quality-tags__slide"
      >
        <div class="store-quality-tags__item">
          <img
            :src="item.icon"
            class="tags-icon"
          />
          <span class="tags-text">{{ item.multiLanguageValue }}</span>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>
    
<script setup name="StoreQualityTags">
/**
 * @component 优秀店铺标签
 */
import { ref, nextTick, watchEffect } from 'vue'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()

const emits = defineEmits(['goToStore'])

const props = defineProps({
  storeQualityList: {
    type: Array,
    default: () => []
  }
})

const storeQualitySwiper = ref(null)
const swiperInstance = ref(null)

watchEffect(()=>{
  if (typeof window != 'undefined' && props.storeQualityList) {
    initSwiper()
  }
})

function initSwiper() {
  nextTick(() => {
    const swiperEl = storeQualitySwiper.value
    if (!swiperEl) return
    const swiperParams = {
      direction: 'horizontal',
      spaceBetween: 8,
      slidesPerView: 'auto',
      updateOnImagesReady: true,
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swipe
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
    swiperInstance.value = swiperEl.swiper
  })
}
function goToStore() {
  emits('goToStore')
}
</script>
    
<style lang="less">
  .store-quality-tags {
    position: relative;
    height: 0.3467rem;
    width: 100%;
    padding-top: 0.2667rem;
    box-sizing: content-box;
    &__swiper {
      display: flex;
    }
    &__slide {
      width: auto;
    }
    &__item {
      display: flex;
      align-items: center;
      .tags-icon {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.1067rem;
      }
      .tags-text {
        color: #222;
        font-weight: 400;
        .font-dpr(22px);
        font-style: normal;
        line-height: normal;
      }
    }
  }
</style>
