import { mapGetters, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SProductItemV3 from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import moreMixin from './more'
import FlashProductDiscount from '../components/FlashProductDiscount'

import { stringifyQueryString } from '@shein/common-function'
const { langPath } = gbCommonInfo
export default {
  components: { 
    SProductItemV3,
    FlashProductDiscount,
    ProductCardPriceSimple,
  },
  mixins: [moreMixin],
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    products: {
      type: Array,
      default() {
        return []
      }
    },
    goodsItemInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    productItemsConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    itemLanguage: {
      type: Object,
      default() {
        return {}
      }
    },
    constantData: {
      type: Object,
      default() {
        return {}
      }
    },
    signData: {
      type: Object,
      default() {
        return {}
      }
    },
    showMore: {
      type: Boolean
    },
    storeJumpType: {
      type: String,
      default: 'three'
    },
    isStore: {
      type: Boolean,
      default: false
    },
    showStoreBrand: {
      type: Boolean,
      default: false
    },
    storeBrandLabel: {
      type: String,
      default: ''
    },
    fourProdecutItemConfig: {
      type: Object,
      default: () => {}
    },
    isFour: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('newProductDetail/Middle', ['fsComments']),
    ...mapGetters('newProductDetail/common', ['detail', 'isNewSizeLocal', 'storeRecommendInfo', 'storeFollowInfo', 'isHitComplianceMode']),
    recommendAdp() {
      return this.storeRecommendInfo?.recommendAdp || ''
    },
    moreSrcIdentifier() {
      const { signData, detail } = this
      const src_identifier = [
        `on=${signData.type}`,
        `cn=${signData.name}`,
        `hz=0`,
        `ps=1_2`,
        `jc=${signData.type === 'store' ? 'thirdPartyStoreHome_' : 'itemPicking_'}${
          detail.goods_id
        }`
      ].join('`')
      return src_identifier
    },
    formatShopScore() {
      return (+this.fsComments?.shopRank)?.toFixed(2)
    },
    //只有店铺曝光
    storeSignExtraData(){
      const { storeFollowInfo } = this
      const { salesVolume, followCount } = storeFollowInfo || {}
      const { shopRank } = this.fsComments
      const brandInfo =  [
          `ratings_${shopRank}`,
          salesVolume && salesVolume !== '0' ? `${salesVolume}` : '-',
          followCount && followCount !== '0' ? `followers_${followCount}` : '-'
      ]
      const buttonLabel = '-'
      return  {
        brand_type: 'store',
        brand_code: this.signData.storeCode || this.signData.store_code,
        button_label: buttonLabel,
        src_module: 'DetailBrand',
        src_identifier: this.moreSrcIdentifier?.replace('ps=1_2', 'ps=0'),
        brand_info: brandInfo.join('`') || '',
        brand_label: this.storeBrandLabel,
      }
    }
  },
  methods: {
    ...mapMutations('newProductDetail/common', [
      'updateCurrentLocalCountry',
    ]),
    clickShowMore(item) {
      if(['fourshort', 'fourtall'].includes(this.storeJumpType)) {
        const params = {
          src_module,
          src_identifier: this.moreSrcIdentifier,
          src_tab_page_id: getSaPageInfo.tab_page_id || '',
          main_goods_id: item.goods_id || '',
          main_cate_id: item.cat_id || '',
          rule_poskey: 'DetailShopItemList',
        }
        const { langPath } = gbCommonInfo
        let url = `${langPath}/store/home?store_code=${this.signData.storeCode || this.signData.store_code}&tabId=items&isStoreTabSticky=1&adp=${this.recommendAdp}${stringifyQueryString({
          queryObj: params
        })}`
        this.$router.push(url)
      }
      const { link, id, type } = this.signData
      const { shopRank } = this.fsComments
      const { src_module = '' } = this.productItemsConfig?.sa
      const { detail } = this

      daEventCenter.triggerNotice({
        daId: this.productItemsConfig.itemDAEventClickViewMoreId,
        extraData: {
          ...(this.productItemsConfig?.sa || {}),
          src_identifier: this.moreSrcIdentifier
        }
      })
      const moreParams = {
        link,
        id,
        type,
        shopRank,
        adp: this.recommendAdp,
        goods_id: detail.goods_id,
        cat_id: detail.cat_id,
        analysis: { src_identifier: this.moreSrcIdentifier, src_module },
        tabId: 'allItem',
        isStoreTabSticky: '1'
      }
      // if (this.signData.storeBusinessType == 2) moreParams.tabId = 'items'
      this.boardMoreHandle(moreParams)
    },
    setAnalysis() {
      // productCardRef表示新商卡
      const refName = this.productItemsConfig._isNew || this.isFour ? 'productCardRef' : 'productItemWrapper'
      // 新店招
      if(this.isFour){
        this.$refs?.['goodsItem']?.forEach(_ => {
          const { brand_type, brand_code, button_label, src_module, src_identifier, brand_info, brand_label, mall_code } = this.fourProdecutItemConfig?.sa
          // 店铺推荐位的数据大于10才会有view more按钮，再绑定goodsList_length
          if(this.showMore){
            _.$refs?.[refName]?.setAttribute('data-products_length', this.products.length || 0)
            _.$refs?.[refName]?.setAttribute('data-view_more', 1)
          }
          // 闪购商品标识
          if(_?.curData.is_flash_sale > 0){
            _.$refs?.[refName]?.setAttribute('data-flash_sale', 'flash_sale')
          }
          _.$refs?.[refName]?.setAttribute('data-brand_type', brand_type)
          _.$refs?.[refName]?.setAttribute('data-brand_code', brand_code)
          _.$refs?.[refName]?.setAttribute('data-button_label', button_label)
          _.$refs?.[refName]?.setAttribute('data-brand_info', brand_info)
          _.$refs?.[refName]?.setAttribute('data-brand_label', brand_label)
          _.$refs?.[refName]?.setAttribute('data-src_module', src_module)
          _.$refs?.[refName]?.setAttribute('data-src_identifier', src_identifier)
          _.$refs?.[refName]?.setAttribute('data-mall_code', mall_code)
          this.isLocalMall(_?.curData?.pretreatInfo?.mallTagsInfo) && 
          _.$refs?.[refName]?.setAttribute('data-local-shipping', 'localshipping_1')
          _.setElAttr()
        })
        return
      }
      // 旧店招
      this.$refs?.['goodsItem']?.forEach(_ => {
        const { activity_from = '', style = '', src_module = '', src_identifier = '', abtest = '', mall_code = '', quickship_tp = '', localshipping_tp = '' } = this.productItemsConfig?.sa
        _.$refs?.[refName]?.setAttribute('data-activity_from', activity_from)
        _.$refs?.[refName]?.setAttribute('data-style', style)
        _.$refs?.[refName]?.setAttribute('data-src_module', src_module)
        _.$refs?.[refName]?.setAttribute('data-src_identifier', src_identifier)
        _.$refs?.[refName]?.setAttribute('data-abtest', abtest)
        _.$refs?.[refName]?.setAttribute('data-mall_code', mall_code)
        _.$refs?.[refName]?.setAttribute('data-main-quick-ship', quickship_tp)
        _.$refs?.[refName]?.setAttribute('data-main-local-ship', localshipping_tp)
        this.isLocalMall(_?.curData?.pretreatInfo?.mallTagsInfo) && 
        _.$refs?.[refName]?.setAttribute('data-local-shipping', 'localshipping_1')
        _.setElAttr()
      })
    },
    isLocalMall(mallTagsInfo = {}) {
      return mallTagsInfo?.localMall
    },
    // 外部列表内商品点击加车
    clickAdd({ item, index, target }) {
      this.handleQuickAdd({ ...item, index, target })
    },
    async handleQuickAdd(payload) {
      const { target, index } = payload
      const imgRatio = await getImageRatio(payload?.goods_img)
      this.$quickAdd.open({
        goods_id: payload.goods_id,
        imgRatio,
        mallCode: payload.mall_code,
        index,
        reducePrice: payload.reducePrice,
        addSource: 'detailPage',
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !this.isHitComplianceMode,
        analysisConfig: {
          sourceTarget: target,
          code: this.code,
          sa: {
            activity_from: this.productItemsConfig?.sa?.activity_from,
            location: '-',
            feed_type: this.storeJumpType === 'three' ? '2' : '',
          },
        },
        clickCallBack: {
          isActive: (status) => {
            if (status) {
              this.reportOpenQuickView(target, payload, index)
            }
          },
          // 加车成功后的回调
          complete: () => {
            setTimeout(() => {
              window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
          },
          handleChangeLocalCountry: (value) => {
            // 数据联动控制
            this.isNewSizeLocal && this.updateCurrentLocalCountry(value)
          },
        },
      })
    },
    // 上报打开加车弹窗事件
    reportOpenQuickView(target, item, index) {
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target: target,
        extraData: {
          goodsListParams: {
            sku: item.goods_sn,
            spu: item.productRelationID || item.goods_sn,
            goodsId: item.goods_id,
            originPrice: item.retailPrice.usdAmount,
            price: item.salePrice.amount,
            mallCode: item.mall_code,
            index,
          },
          need_tab_list: true,
        },
      })
    },
    goToStore(item, isViewMore = false) {
      const { src_module, src_identifier } = this.productItemsConfig?.sa
      const params = {
        src_module,
        src_identifier,
        src_tab_page_id: getSaPageInfo.tab_page_id || '',
        main_goods_id: item.goods_id || '',
        main_cate_id: item.cat_id || '',
        rule_poskey: 'DetailShopItemList',
      }
      
      if(['fourshort', 'fourtall'].includes(this.storeJumpType)) {    
        if(isViewMore){
          
          daEventCenter.triggerNotice({
            daId: '1-6-4-89',
            extraData: {
              ...this.fourProdecutItemConfig?.sa,

            }
          })
        }
        let url = `${langPath}/store/home?store_code=${this.signData.storeCode || this.signData.store_code}&tabId=items&isStoreTabSticky=1&adp=${isViewMore ? this.detail?.goods_id : item.goods_id}&${stringifyQueryString({
          queryObj: params
        })}`
        this.$router.push(url)
      }
    },
    clickItemHandle({ goodsUrl = '', goods_id, cat_id }) {
      const { src_module, src_identifier } = this.productItemsConfig?.sa
      const params = {
        src_module,
        src_identifier,
        src_tab_page_id: getSaPageInfo.tab_page_id || '',
        main_goods_id: goods_id || '',
        main_cate_id: cat_id || '',
        rule_poskey: 'DetailShopItemList',
      }
      let url = `${goodsUrl}${!!~goodsUrl.indexOf('?') ? '&' : '?'}${stringifyQueryString({
        queryObj: params
      })}`
      this.$router.push(url)
    }
  }
}
