<template>
  <div
    :class="{
      'brand-outer-box': true,
      'brand-box-new_bff': true,
      'brand-type': STYLE_V1,
      'brand-type_new': STYLE_V2,
    }"
  >
    <div
      v-ada="{ level: 1, pos: [0] }"
      class="mgds-brand j-sa-brand-view-products"
      :class="{
        'mgds-storebrand': STYLE_V1,
        'mgds-storebrand_new': STYLE_V2
      }"
      :aria-label="store_brand_entry_box.name"
      :data-name="store_brand_entry_box.name"
      :data-brand_code="store_brand_entry_box.id"
      :data-goods_id="sign_data.goods_id"
      :data-brand_type="store_brand_entry_box.type"
      :data-designer_id="store_brand_entry_box.designerId"
      :data-sc_id="store_brand_entry_box.scId"
      :data-is_alone_store_brand="STYLE_V2 || STYLE_V1"
      role="text"
      @click="viewProducts()"
    >
      <!-- 主内容 -->
      <div
        v-ada="{ level: 1, pos: [0] }"
        :class="['mgds-brand__detail', { center: !descriptionsShow }]"
        :aria-label="store_brand_entry_box.name"
        role="text"
        aria-hidden="true"
      >
        <div
          v-if="showBrandLogo"
          class="mid-size"
          :class="{
            'mgds-brand__detail-logo': true,
          }"
        >
          <img
            class="mid-size"
            :src="store_brand_entry_box.logo"
          />
        </div>
        <div class="mgds-brand__detail-info">
          <div class="mgds-brand__detail-info-title">
            <div class="text">
              <span class="text_first">{{ store_brand_entry_box.name }}</span>
              <!-- 品牌集成店铺 -->
              <template v-if="STYLE_V2">
                <div class="store-brand_tip split-left">
                  <img
                    :src="`${PUBLIC_CDN}/pwa_dist/images/store/brand_tips_icon-bd0d0de393.svg`"
                  />
                </div>
              </template>
              <!-- 展示100%正品标签 -->
              <div
                v-if="isAbtAuthenticbrandShow"
                v-expose="{ id: '1-6-4-74', data: authenticbrandShowAnalysisData }"
                class="storebrand__authentic"
              >
                {{ sign_board_text.authenticLang }}
              </div>
            </div>
          </div>

          <!-- 描述信息在logo右侧 -->
          <template v-if="descriptionsShow && !STYLE_V2">
            <!-- 
            当前模块公用 
            if 品牌、系列模块 
            else if 店铺模块新样式
            else if 店铺独立品牌模块
            -->
            <div 
              v-if="!STYLE_V1"
              class="desc-brand"
            >
              {{ store_brand_entry_box.desc }}
            </div>
            <div
              v-else-if="STYLE_V1"
              class="storebrand-desc-wrap"
            >
              <div class="storebrand-desc-wrap__content">
                {{ store_brand_entry_box.desc }}
              </div>
              <!-- 右侧跳转箭头 -->
              <div
                v-if="store_brand_entry_box.link"
                aria-hidden="true"
                class="mgds-brand__view-products"
              >
                <i :class="viewMoreIconClass"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 右侧跳转箭头 -->
      <div
        v-if="store_brand_entry_box.link"
        aria-hidden="true"
        class="mgds-brand__view-products"
        :class="{
          hidden: descriptionsShow && STYLE_V1
        }"
      >
        <i :class="viewMoreIconClass"></i>
      </div>
    </div>
    <!-- 描述信息在logo底部-->
    <template v-if="descriptionsShow">
      <div
        v-if="STYLE_V2"
        :class="[
          'storebrand__descriptions',
          {
            ellipsis: !storeBrandDescExpend
          }
        ]"
        @click="storeBrandDescExpend = true"
      >
        {{ store_brand_entry_box.desc }}
      </div>
    </template>
    <div
      v-if="showBrandsZone"
      class="storebrand__zone"
      @click="goToBrandZone"
    >
      <span class="storebrand__zone-title">{{ sign_board_text.zoneLang }}</span>
      <span class="storebrand__zone-desc">{{ sign_board_text.newBrandsLang }}</span>
      <i class="suiiconfont sui_icon_more_right_12px_2 storebrand__zone-icon"></i>
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapGetters } from 'vuex'
import { CccInit } from 'public/src/pages/common/ccc_public_v2'
import moreMixin from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/mixins/more.js'
const { SiteUID, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'StoreBusinessBrandEntrace',
  mixins: [moreMixin],
  props: {
    config: {
      type: Object,
      default: () => ({
        v1: true, // 是否展示独立品牌组件
        v2: false // 品牌集成店品牌新样式是否展示新版独立品牌组件（命中abt的 标签商品或独立品牌商品）
      })
    },
  },
  data() {
    return {
      SiteUID,
      storeBrandDescExpend: false,
      cccPublic: null,
      PUBLIC_CDN
    }
  },
  computed: {
    // NEW BFF ===============
    ...mapGetters('productDetail/DetailSignBoard', [
      'sign_data',
      'store_brand_entry_box',
      'sign_board_text',
      'store_quality_label',
      'label_list',
      'is_brand_has_target_tag',
      'brand_zone_link',
      'brand_zone_status',
      'store_code'
    ]),
    STYLE_V1() {
      return !!this.config?.v1
    },
    STYLE_V2() {
      return !!this.config?.v2
    },
    tipsData() {
      // 标签数据
      const { store_brand_entry_box, sign_board_text, STYLE_V2 } = this
      const { type } = store_brand_entry_box
      if (['brand', 'series'].includes(store_brand_entry_box.type)) {
        return {
          show: !!store_brand_entry_box.logoTips,
          labelText: type == 'brand' ? sign_board_text.brandLang : sign_board_text.collectionLang,
          type,
          isNewStoreBrand: STYLE_V2
        }
      }
      return { show: false }
    },
    // 店铺id
    storeId() {
      const { store_code } = this
      return store_code
    },
    descriptionsShow() {
      const { store_brand_entry_box } = this
      if (!store_brand_entry_box?.desc) return false
      return true
    },
    salesArea() {
      return this.store_brand_entry_box?.salesArea || null
    },
    // 品牌logo展示规则
    showBrandLogo() {
      return this.store_brand_entry_box.logo
    },
    viewMoreIconClass() {
      return ['suiiconfont', 'sui_icon_more_right2_16px']
    },
    authenticbrandShowAnalysisData() {
      const { store_brand_entry_box, sign_data} = this
      return {
        // 商品id 提交的goods_id
        goods_id: sign_data.goods_id,
        // 品牌code 当前的品牌模块的品牌brand_code
        brand_code: store_brand_entry_box?.brand_code
      }
    },
    // 符合标签的商品且满足abt 展示 100%正品标签
    isAbtAuthenticbrandShow() {
      return this.is_brand_has_target_tag && this.STYLE_V2
    },
    // 展示品牌馆入口
    showBrandsZone() {
      return this.isAbtAuthenticbrandShow && this.brand_zone_status
    },
  },
  methods: {
    viewProducts(tabId) {
      const { type, id, link, name, designerId, scId } = this.store_brand_entry_box
      const { analysis, sign_data } = this
      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : `itemPicking_${scId}`
        }`
      ].join('`')
      // 点击跳转店铺上报
      this.clickStoreReports({ tabId, type, id, src_identifier })    
      this.boardMoreHandle({
        sort: '',
        isStoreTabSticky: tabId ? '1' : '',
        tabId,
        type,
        id,
        link,
        goods_id: sign_data.goods_id,
        cat_id: sign_data.cat_id,
        adp: !this.setAdpParams ? '' : this.recommendAdp,
        analysis: { src_identifier, src_module: analysis?.src_module }
      })
    },
    clickStoreReports({ tabId, type, id, src_identifier }) {
      daEventCenter.triggerNotice({
        daId: tabId ? '1-6-1-194' : '1-6-4-17',
        extraData: {
          brand_type: type,
          brand_code: id,
          page_nm: 'page_goods_detail',
          src_identifier,
          ...(tabId ? {} : { store_qualitylabel: this.store_quality_label })
        }
      })
    },
    goToBrandZone() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-82',
        extraData: {
          goods_id: this.sign_data.goods_id,
          brand_code: this.storeId,
        }
      })
      if(!this.cccPublic) {
        this.cccPublic = new CccInit({
          context: this.brand_zone_link,
          propData: this.brand_zone_link.content,
          index: 1,
        })
      }
      this.cccPublic.clickLink({
        item: this.brand_zone_link.content,
        cccCateInfo: {},
        gaIndex: 0,
        scIndex: 0,
      })
    },
  }
}
</script>

<style lang="less">
.base-text-ellipsis {
  // 一行超出省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brand-outer-box {
  padding: 0.2667rem 0.346666rem;
  // background: #fff;
  &.brand-type {
    padding: 0.2667rem 0;
  }
  // 新样式
  &.brand-type_new {
    padding: 0.32rem;
    // 为了渐变色覆盖父元素设置的padding-left right间距
    margin: 0 -0.32rem;
    background: linear-gradient(180deg, rgba(218, 231, 246, 0.6) 0%, rgba(218, 231, 246, 0) 21.79%),
      #fff;
    // 防止渐变色切换展开时闪烁
    background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
    background-size: 100% 2.08rem !important; /* stylelint-disable-line declaration-no-important */
  }
  
}

.mgds-brand {
  .flexbox();
  .space-between();
  .align-center();
  &__detail {
    .flexbox();
    overflow: hidden;
    &.center {
      .align-center();
    }
  }
  &__detail-logo {
    .margin-r(8/75rem);
    width: 88/75rem;
    height: 88/75rem;
    .border-dpr(border, 1px, #e5e5e5);
    .flexbox();
    .align-center();
    flex-shrink: 0;
    &.large-size {
      width: 1.4133rem;
      height: 1.4133rem;
    }
    &.mid-large-size{
      width: 1.226666rem;
      height: 1.226666rem;
    }
    &.mid-size {
      width: 1.067rem;
      height: 1.067rem;
    }
    > img {
      width: 100%;
    }
  }
  &__detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 6.76rem;
    overflow: hidden;

    .storebrand-desc-wrap {
      display: flex;
      align-items: center;
      &__content {
        .base-text-ellipsis();
        color: #818181;
        font-size: 0.32rem;
      }
    }
  }
  &__detail-info-title {
    .text {
      .font-dpr(28px);
      color: #333;
      font-weight: bold;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      gap: 0.1067rem;

      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      i {
        color: @sui_color_gray_light1;
        margin: 0 0.106667rem;
      }
    }

    .store-brand_tip {
      img {
        width: 0.37333rem;
        height: 0.37333rem;
      }
    }
  }
  &__view-products {
    padding: 0.3rem 0;
    white-space: nowrap;
    color: #999;
    > i {
      color: #999;
      .margin-l(0.12rem);
      .font-dpr(24px);
    }

    &.hidden {
      display: none;
    }
  }
  .split-left {
    margin-left: 0.1067rem;
  }

  .storebrand__authentic {
    color: #000;
    font-size: 0.29333rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline;
    margin-right: 0.15rem;
    &::before {
      content: '';
      display: inline-block;
      width: 0.01333rem;
      height: 0.21333rem;
      opacity: 0.4;
      background: #000;
      margin: 0 0.16rem;
    }
  }
}

.mgds-storebrand,
.mgds-storebrand_new {
  .mgds-brand__detail {
    align-items: center;
  }
  .mgds-brand__detail-logo {
    width: 0.8533rem;
    height: 0.8533rem;
    overflow: hidden;
  }
  .mgds-brand__detail-info {
    max-width: 100%;
  }
  .mgds-brand__view-products {
    padding: 0;
  }
  .mgds-brand__detail-info-title {
    display: inline-flex;
    align-items: center;
    .text {
      color: #000;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.mgds-storebrand {
  .mgds-brand__detail-logo {
    border-radius: 50%;
    margin-right: 0.32rem;
  }
}

.mgds-storebrand_new {
  .mgds-brand__detail-logo {
    margin-right: 0.21rem;
  }
}

/* 多行文本超出显示省略号*/
.n-ellipsis(@n){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @n;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
}
.desc-brand {
  // 二行超出省略号
  .n-ellipsis(2);
  color: #818181;
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.brand-type,
.brand-type_new {
  .mgds-brand__detail-info-title {
    .text_first {
      color: #000;
      font-size: 0.37333rem;
      font-weight: 700;
    }
  }
}

.storebrand {
  &__descriptions {
    color: #666;
    font-size: 0.32rem;
    font-weight: 400;
    margin-top: 0.21rem;
    &.ellipsis {
      .base-text-ellipsis();
    }
  }
  &__zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.0533rem;
    margin-top: 0.2133rem;
    padding: 0.08rem;
    background: linear-gradient(90deg, #E5EEF9 0.01%, rgba(229, 238, 249, 0.29) 48.74%, rgba(229, 238, 249, 0.00) 82.65%);
  }
  &__zone-title {
    border-radius: 0.0533rem;
    background: linear-gradient(90deg, #739BD8 0%, #9CBBEB 100%);
    color: #FFF;
    font-family: SF UI Text;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 0 0 auto;
    padding: 0.0533rem 0.08rem 0.0533rem 0.1067rem;
  }
  &__zone-desc {
    color: #243F6A;
    font-family: SF UI Text;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.1067rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__zone-icon {
    margin-left: 0.1067rem;
    color: #243F6A;
  }
}
</style>
