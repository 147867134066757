import { abtservice } from 'public/src/services/abt'

/** 
 * 获取abt信息
 * @param {String} posKeys
*/
const getAnalysisAbtInfo = (posKeys) => {
  let abtInfo = {}
  let gaAbt = 0 // ga没有实验默认是0

  let saAbt = ''

  if (posKeys) {
    // eslint-disable-next-line @shein-aidc/abt/abt
    abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys: posKeys })
    // 自有的abt信息拼接
    saAbt = abtInfo.sa || ''
    // ga的abt信息拼接
    gaAbt = abtInfo.ga || 0
  }

  return {
    abtInfo,
    saAbt,
    gaAbt
  }
}

const pageInfoMapping = (code) => {
  const info = {}
  info['pageName'] = 'Other'
  switch (code) {
    case 'goodsDetail':
      info['pageName'] = 'GoodsDetail'
      break
    case 'orderDetailCccRecommend':
      info['pageName'] = 'OrderDetail'
      break
    case 'cart':
      info['pageName'] = 'ShopBag'
      break
    case 'emptyCart':
      info['pageName'] = 'EmptyShopBag'
      break
    case 'paymenSuccess':
      info['pageName'] = 'PaySuccess'
      break
    case 'homePage':
      info['pageName'] = 'HomePage'
      break
    case 'listPage':
      info['pageName'] = 'ListPage'
      break
    case 'orderRepurchasePage':
      info['pageName'] = 'RepurchaseResults'
      break
    case 'orderConfirmDeliveryPage':
      info['pageName'] = 'ConfirmDeliverySuccess'
      break
    case 'orderUnpaidPage':
      info['pageName'] = 'CancelOrderSuccessUnpaid'
      break
    case 'orderUnshippedCancelPage':
      info['pageName'] = 'CancelOrderSuccessPaid'
      break
  }
  return info
}

// gaList
const getGaParams = ({ code, ruleId = 0, pageId = 0, floorId = 0, comId = 0, gaAbt = 0, cateId = 0, cateNm = 0 }) => {
  if (!code) return ''
  // `CCCRI={模板规则id}_PN={页面名称}_PI={页面id}_CI={组件id}_FI={楼层id}__ABT={poskey_分支类型}_CS={tab名称 分类类型 分类id}`
  const list = [
    `CCCRI=${ruleId}`,
    `PN=${pageInfoMapping(code).pageName}`,
    `PI=${pageId}`,
    `CI=${comId}`,
    `FI=${floorId}`,
    `ABT=${gaAbt}`
  ]

  const cataInfo = cateId ? `CS=${cateNm} 0 ${cateId}` : 'CS=0'
  
  return {
    gaImpList: [...list, cataInfo].join('_'),
    ici: [...list, `PS=1`].join('_')
  }
}

const getCccAnalysisRecInfo = ({ code, ruleId = 0, pageId = 0, floorId = 0, comId = 0, gaAbt = 0, cateId = 0, cateNm = 0, }) => {
  if (!code) return ''
  // `CCCRI={模板规则id}_PN={页面名称}_PI={页面id}_CI={组件id}_FI={楼层id}__ABT={poskey_分支类型}_CS={tab名称 分类类型 分类id}`
  const list = [
    `CCCRI=${ruleId}`,
    `PN=${pageInfoMapping(code).pageName}`,
    `PI=${pageId}`,
    `CI=${comId}`,
    `FI=${floorId}`,
    `ABT=${gaAbt}`
  ]

  const cataInfo = cateId ? `CS=${cateNm} 0 ${cateId}` : 'CS=0'

  return {
    gaImpList: [...list, cataInfo].join('_'),
    ici: [...list, `PS=1`].join('_'),
  }
}

export {
  pageInfoMapping,
  getGaParams,
  getAnalysisAbtInfo,
  getCccAnalysisRecInfo
}
