<template>
  <div
    class="mgds-recommend"
    :da-expose-code="componentsCode"
  >
    <PlaceholderItem
      v-if="tabData.length == 0"
      :brand="brand"
    />
    <div
      v-else
      ref="RecommendSwiperSlider"
      class="s-swiper-container rec-swiper-slider"
    >
      <swiper-container
        ref="swiper"
        init="swiper"
        class="goodsl-wrap goodsl-item-wrap-recommend s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(tabItem, tabIndx) in tabData"
          :key="tabIndx"
          ref="swiperSlider"
          class="s-swiper-slide swiper-rec-slider"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
        >
          <SProductItem
            v-for="(item, index) in tabIdProduct[tabItem.sku_cate_id]"
            :key="`${index}_${item.goods_id}`"
            ref="goodsItem"
            :index="index"
            :item="item"
            :language="itemLanguage"
            :constant-data="constantData"
            :config="config.items || {}"
            :column="rowNum"
            :scroll-timers="scrollTimers"
            class="recommend__goods-item"
            :class="{
              'recommend__goods-item-two': rowNum === 2,
              'recommend__goods-item-two-potin-three': rowNum === 2.3,
              'recommend__goods-item-three': rowNum === 3,
              'recommend__goods-item-three-point-five': rowNum === 3.5,
            }"
            @clickItem="clickItem"
            @openQuickAdd="openQuickAdd"
            v-on="$listeners"
          />
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script>
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem.vue'
import SProductItem from 'public/src/pages/components/product/item_v2/Item'
import useItemMixin from './use-item-mixin'
// import ProductItem from 'public/src/pages/components/product/item.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()

const { GB_cssRight = false, IS_RW } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
/**
 * swiper 要执行自身的活动切换行为
 * true 执行swiper的滑动行为
 * false 执行容器自带的滚动行为
 */
const swiperTouchMove = (target, dir, cb) => {
  if (!target) return false
  const scrollWidth = target.scrollWidth
  const scrollLeft = target.scrollLeft
  cb && cb(scrollLeft)
  const clientWidth = target.clientWidth
  // 如果滚动宽度小于  本身宽度
  if (scrollWidth <= clientWidth + 1) {
    return true
  }

  // 正常从左网友读
  if (!GB_cssRight) {
    if (dir === 'left' && (scrollWidth <= clientWidth + 1 + scrollLeft)) {
      return true
    }
    // ios有个黏性效果，scrollLeft 将会为负数
    if (scrollLeft <= 0 && dir === 'right') {
      return true
    }
  } else {
    if (dir === 'right' && (scrollWidth <= clientWidth + 1 + Math.abs(scrollLeft))) {
      return true
    }
    if (scrollLeft >= 0 && dir === 'left') {
      return true
    }
  }
  // index
  return false
}
let touches = {
  startX: 0,
  currentX: 0,
}

export default {
  name: 'SwiperSlider',
  components: {
    PlaceholderItem,
    // ProductItem,
    SProductItem
  },
  mixins: [useItemMixin],
  inheritAttrs: false,
  props: {
    tabData: {
      type: Array,
      require: true,
      default: () => []
    },
    tabProduct: {
      type: Array,
      require: true,
      default: () => []
    },
    activeTab: {
      type: Object,
      default() {
        return {}
      }
    },

    rowNum: {
      type: [String, Number],
      default: 3.5
    },

    /**
     * @param {Boolean} showViewMore 展示加载按钮
     */
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 多tab推荐组件是否需要在activated后保持横向滚动距离
    needActivatedScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brand: IS_RW ? 'romwe' : 'shein',
      swiper: null,
      scrollTimers: 0,
      swiperScrollLeft: 0,
    }
  },
  computed: {
    componentsCode() {
      return `auto_rec_swiper_slider_${ new Date().getTime() }`
    },
    tabIdProduct() {
      let listInfo = {}
      this.tabProduct.forEach(_ => {
        listInfo[_.id] = _.data
      })
      return listInfo
    },
    tabActiveIndex() {
      return this.tabData.findIndex(i => i.sku_cate_id === this.activeTab.sku_cate_id)
    },
  },
  watch: {
    tabData: {
      handler(val) {
        if (val.length > 1) {
          this.initSwiper()
        } else {
          this.slideTo(0)
          this.swiper?.destroy(false)
        }
      },
      immediate: true
    },
    tabActiveIndex: {
      handler(val) {
        this.slideTo(val)
      },
      immediate: true
    },
    tabIdProduct: {
      handler() {
        this.$nextTick(() => {
          this.handleExposeEvent()
        })
      },
      immediate: true
    }
  },
  activated() {
    // 重制，曝光
    daEventExpose.reset(this.componentsCode)
    if(this.needActivatedScroll && this.$refs.swiperSlider && this.$refs.swiperSlider[this.tabActiveIndex]) {
      this.$refs.swiperSlider[this.tabActiveIndex].scrollLeft = this.swiperScrollLeft || 0
    }
  },
  beforeDestroy() {
    daEventExpose?.remove?.(this.componentsCode)
    this.swiper?.destroy()
  },
  methods: {
    slideTo(index) {
      // 切换到对应的tab
      this.swiper && this.swiper.slideTo(index)
    },
    initSwiper() {
      if (this.swiper) {
        this.swiper?.destroy(false)
      }
      this.swiper = null
      const _this = this

      const swiperConfig = {
        dir: GB_cssRight ? 'rtl' : 'ltr',
        on: {
          slideChangeTransitionEnd() {
            // this === swiper
            _this.swiperToTab(this.activeIndex)
            _this.sliderAllowTouchMove(this.activeIndex)
          }
        }
      }
      setTimeout(() => {
        const swiperEl = this.$refs.swiper
        if (!swiperEl) return

        Object.assign(swiperEl, swiperConfig)
        swiperEl.initialize()
        this.swiper = swiperEl.swiper
        this.slideTo(this.tabActiveIndex)
      }, 0)


    },

    /**
     * slider touch start
     */
    onTouchStart(e) {
      if (!this.swiper ) return
      // 记录开始的触摸位置
      touches.startX = e.targetTouches[0].pageX
    },

    /**
     * slider touch move
     */
    onTouchMove(e) {
      if (!this.swiper ) return
      const targetTouch = e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])
      touches['currentX'] = targetTouch.pageX // 记录移动的触摸位置
      const diffX = touches['currentX'] - touches['startX']
      //手势 = diffx <= 0 ?  <--- : --->
      const dir = diffX <= 0 ? 'left' : 'right'
      this.scrollTimers = this.scrollTimers + 1
      this.sliderAllowTouchMove(this.tabActiveIndex, dir)
    },

    /**
     * 设置swiper是否需要touch来切换滚动
     */
    sliderAllowTouchMove(activeIndex, dir) {
      if (!this.swiper ) return
      const slider = this.swiper.slides[activeIndex]
      const allowTouchMove = swiperTouchMove(slider, dir, (scrollLeft) => {
        this.swiperScrollLeft = scrollLeft || 0
      }) 
      this.swiper.allowTouchMove = allowTouchMove
    },

    /**
     * 切换到对应的slider
     */
    swiperToTab(activeIndex) {
      if (!this.swiper ) return
      this.swiper.slideTo(activeIndex)
      const item = this.tabData.find((i, index) => index == activeIndex)
      item && this.$emit('sliderToTab', item)
    },

    /**
     * 处理曝光事件
     */
    handleExposeEvent() {
      const { itemDAEventExposeId } = this.config?.items || {}

      if (!itemDAEventExposeId || !daEventExpose) return
      // 设置元素上的埋点信息
      const goodsItems = this.$refs?.goodsItem
      if (Array.isArray(goodsItems)) {
        goodsItems.forEach((goodsItem) => {
          goodsItem.setElAttr()
        })
      } else {
        goodsItems?.setElAttr?.()
      }

      daEventExpose.subscribe({
        keycode: `${this.componentsCode}\`${itemDAEventExposeId}`,
        type: 'list',  // 当前code是属于列表类型的吗
        horizontal: true,
      })
    },

    clickItem(payload) {
      const { openQuickAdd, disableMainimgJump } = this.config?.items || {}
      if (openQuickAdd && disableMainimgJump) {
        this.$emit('open-quick-add', payload)
        return
      }
      this.$emit('clickItem', payload)
    },

    openQuickAdd(payload) {
      this.$emit('open-quick-add', {
        ...(payload?.item || {}),
        index: payload?.index,
        target: payload?.target,
        imgRatio: payload?.imgRatio,
      })
    }
  },
}
</script>

<style lang="less" scoped>
.mgds-recommend {
  padding: 0 0.32rem;
  background-color: #fff;
}

.swiper-rec-slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  // .padding-l(0.32rem);
  .padding-r(0.32rem);
  min-height: 3.7333rem;
}
</style>
