<template>
  <div
    v-if="firstLabelItem"
    :class="{
      'buybox-itemlabel': true,
      'green-type': isQuickShip,
    }"
  >
    <div class="new-style-iconbox">
      <Icon
        v-if="isQuickShip"
        name="sui_icon_quickship_flat_12px"
        size="12px"
        style="margin-right: 2px"
      />
      <div
        :class="{
          'label-text': true,
          'new-label-qstext': isQuickShip
        }"
      >
        {{ firstLabelItem.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BuyBoxIndexLabel',
}
</script>
<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
})
const firstLabelItem = computed(() => {
  return props.item?.labelList?.[0]
})
const isQuickShip = computed(() => {
  return firstLabelItem.value?.type === 'quick_ship'
})
</script>
<style lang="less" scoped>
.buybox-itemlabel {
  border-radius: 0.05333rem;
  background: #FFF8EB;
  color: #CC6E00;
  display: inline-block;
  padding: 0 3px;
  max-width: 96px;
  font-size: 10px;
  font-weight: 400;
  border: 0.5px solid rgba(204, 110, 0, 0.20);
  .text-overflow();

  &.green-type {
    background: #ECFCF3;
    color: #198055;
    border: 0.5px solid rgba(25, 128, 85, 0.20);
  }
  .new-style-iconbox {
    display: flex; 
    padding: 1px 0;
    align-items: center;
  }
  .label-text {
    color: #CC6E00;
    font-style: normal;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    .text-overflow();
  }
  .new-label-qstext {
    color: #198055;
    font-style: italic;
    font-weight: 590;
  }
}
</style>
