<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import salePriceWordSize from 'public/src/pages/goods_detail/utils/salePriceWordSize'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import ProductCardPriceSimpleSuggested from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimpleSuggested.vue'
import { baseExposeData, setBuyboxProductLabelList } from './utils'
import { getFormatBuyboxProductList } from 'public/src/pages/common/BuyBoxPopup/utils.js'
import { getBuyBoxMixins } from './mixins'
import BuyBoxIndexLabel from './BuyBoxIndexLabel.vue'

export default {
  name: 'BuyBoxIndex',
  components: {
    Icon,
    ProductCardPriceSimple,
    ProductCardPriceSimpleSuggested,
    BuyBoxIndexLabel,
    BuyBoxDrawer: () => 
      import(
        /* webpackChunkName: "plv2_BuyBoxDrawer" */ 'public/src/pages/common/BuyBoxPopup/BuyBoxDrawer.vue'
      ),
  },
  mixins: [getBuyBoxMixins({ isPopup: false })],
  data() {
    return {
      show: false,
      showDrawer: false,
      noneLowerPriceTag: false,
      innerProductsNum: 0,
      products: [], // 当前外露的商品item，最大十个
      allProducts: [], // 所有商品
      lowerPrice: '',
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['buyBoxRecInfo']),
    ...mapGetters('newProductDetail', ['fsAbt', 'isPaidUser']),
    ...mapGetters('newProductDetail/ShippingEnter', [
      'buyBoxFloorQuickshipTitle',
    ]),
    ...mapGetters('newProductDetail/common', [
      'language',
      'productItemsLanguage',
      'detail',
      'complianceTipsMode',
      'complianceModeDe',
      'isHitComplianceMode',
    ]),
    ...mapGetters('productDetail/common', ['mall_code']),
    buyBoxRecInfoNum() {
      return this.innerProductsNum ? this.innerProductsNum - 1 : this.buyBoxRecInfo.length ? this.buyBoxRecInfo.length - 1 : 0
    },
    // 是否外露商品
    hasFloatItem() {
      const { BuyBoxSwitch } = this.fsAbt?.BuyBoxSwitch?.param || {}
      return BuyBoxSwitch === 'B' || BuyBoxSwitch === 'C'
    },
    isItemHasImg() {
      const { BuyBoxSwitch } = this.fsAbt?.BuyBoxSwitch?.param || {}
      return BuyBoxSwitch === 'C'
    },
    isNewStyle() {
      const { style806 } = this.fsAbt?.BuyBoxSwitch?.param || {}
      return style806 === 'new'
    },
    isShowNewStoreIcon(){
      return this.fsAbt?.storeiconchange?.param?.storeiconchange === 'new'
    },
  },
  watch: {
    buyBoxRecInfo: {
      handler(list = []) {
        this.initData(list)
      },
      immediate: true,
      deep: true
    },
    'detail.goods_id'(val) {
      if (val) {
        this.refreshData()
      }
    },
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateLoading']),
    ...mapActions('newProductDetail', ['oneSkuAddToBag']),
    
    /**
     * 切skc初始化数据，避免出现上一个缓存
     */
    refreshData() {
      this.products = []
      this.innerProductsNum = 0
      this.show = false
    },
    /**
     * 初始化推荐数据 __main__
     */
    initData(list = []) {
      if(!list.length) return this.show = false
      const { _newList, lowerPrice, noneLowerPriceTag } = getFormatBuyboxProductList(list, false, this.productItemsLanguage)

      // 设置商品项标签
      setBuyboxProductLabelList(_newList, this.language)

      this.allProducts = _newList
      this.products = _newList.slice(0, 11)
      this.lowerPrice = lowerPrice
      this.noneLowerPriceTag = noneLowerPriceTag
      this.show = this.products.length > 1
    },
    handleClickItem(item, index){
      const target = this.$refs[`detail_item_${item.goods_id}`]
      daEventCenter.triggerNotice({
        daId: '2-3-1',
        target,
      })
      this.handleQuickAdd({ ...item, target, index })
    },
    handleClickCart(item, index) {
      const onlyOneSku = item?.sale_attr_list?.sku_list?.length == 1
      const { style806 } = this.fsAbt?.BuyBoxSwitch?.param || {}
      const target = this.$refs[`detail_item_${item.goods_id}`]
      if(onlyOneSku && style806 == 'new'){
        let analysisConfig = {
          sa: {
            from: 'buy_box_detail',
          }
        }
        this.oneSkuAddToBag({ productItem: item, target, analysisConfig  })
        return
      }
      this.reportOpenQuickView(target)
      this.handleQuickAdd({ ...item, target, index })
    },
    async handleShowDrawer() {
      const { estimatedswitch } = this.fsAbt?.BuyBoxSwitch?.param || {}
      const list = estimatedswitch === 'off' ? [] : this.allProducts
      this.showDrawer = true
      daEventCenter.triggerNotice({
        daId: '1-6-4-84',
        extraData: {
          buy_box_style: this.hasFloatItem ? ( this.isItemHasImg ? 3 : 1 ) : 2
        }
      })
      const { cat_id = '', goods_id, goods_sn } = this.detail || {}
      const hasNextPageData = this.products[0]._serFormatInfo.hasMoreData
      this.$refs.refBuyBoxDrawer?.open({
        isPaidUser: this.isPaidUser,
        requestParams: {
          goods_id,
          cat_id,
          goods_sn,
          mall_code: this.mall_code,
          pageKey: 'page_real_class',
          cccParams: {
            displayWindowAbtBranch: this.fsAbt?.listgoodsCard?.p?.listGoods || '' // 开启新商卡橱窗ABT
          },
        },
        callback: {
          updateCartNum: () => {
            window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', {
              animation: false
            })
          }
        },
        hasNextPageData,
      }, list)
    },
    renderTop() {
      const topProps = {
        directives: [
          {
            name: 'expose',
            value: {
              id: '1-6-4-83',
              data: { buy_box_style: this.hasFloatItem ? ( this.isItemHasImg ? 3 : 1 ) : 2 },
              callback: () => {
                this.showDrawer = true
              }
            }
          }
        ]
      }
      const { language, handleShowDrawer, hasFloatItem, buyBoxRecInfoNum, isNewStyle } = this
      const viewAllLang = template(buyBoxRecInfoNum, language.SHEIN_KEY_PWA_29762 || 'View all {0} sellers')
      return (
        <div
          class="buybox__top"
          {...topProps}
        >
          <div class="buybox__top-title">{language.SHEIN_KEY_PWA_29761 || 'Other Sellers On SHEIN'}</div>
          {hasFloatItem && (
            <div 
              class={{
                'buybox__top-text': true,
                'buybox__top-newtext': isNewStyle,
              }}
              onClick={() => {
                handleShowDrawer(true)
              }}
            >
              <div class='text'>
                {viewAllLang}
              </div>
              <i class="suiiconfont sui_icon_more_right_12px_2"></i>
            </div>
          )}
        </div>
      )
    },
    renderContent() {
      const { renderContentA, renderContentB, hasFloatItem } = this
      return (
        <div
          class="buybox__content"
        >
          {hasFloatItem ? renderContentB() : renderContentA()}
        </div>
      )
    },
    renderContentA() {
      const { handleShowDrawer, language, buyBoxRecInfoNum, lowerPrice } = this
      const contentTextArr = template( buyBoxRecInfoNum, language.SHEIN_KEY_PWA_29763).split('{1}')
      const contentTextOne = contentTextArr?.[0] || ''
      const contentTextTwo = contentTextArr?.[1] || ''
      const priceHtml = salePriceWordSize({ priceStr: lowerPrice, gate: true })
      return (
        <div 
          class="buybox__content-astyle"
          onClick={() => {
            handleShowDrawer(true)
          }}
        >
          <span>{contentTextOne}<i class="astyle-price" domPropsInnerHTML={priceHtml}></i>{contentTextTwo}</span>
          <i class="suiiconfont sui_icon_more_right_16px_2"></i>
        </div>
      )
    },
    renderContentB() {
      const { products, language, cRenderBindData, productItemsLanguage, isPaidUser, noneLowerPriceTag, isHitComplianceMode, complianceTipsMode, complianceModeDe, isItemHasImg, isNewStyle, isShowNewStoreIcon, fsAbt } = this
      if(!products.length) return null
      const priceCardConfig = {
        direction: 'column',
        showS3VipPriceOnSale: fsAbt?.EstimatedPrice?.p?.S3newprice === 'new' // 主价格显示会员 s3 价格
      }
      if(complianceTipsMode || complianceModeDe) {
        priceCardConfig.priceColor = '#000'
        priceCardConfig.hidePriceBottomLabel = true
      }
      const itemProps = {
        directives: [
          {
            name: 'expose',
            value: {
              id: '2-3-2',
              code: baseExposeData.reportCode
            }
          }
        ]
      }
      const wrapProps = {
        attrs: cRenderBindData
      }
      const lowerPriceLang = isHitComplianceMode ? (language.SHEIN_KEY_PWA_30650 || 'Lowest in all sellers') : (language.SHEIN_KEY_PWA_29764 || 'Lowest price')
      const defaultTitle = language.SHEIN_KEY_PWA_29994

      const useBlackColorPrice = complianceTipsMode || complianceModeDe

      // 使用合规价价格组件
      const ProductCardPriceSimpleComponent = isHitComplianceMode && !useBlackColorPrice ? ProductCardPriceSimpleSuggested : ProductCardPriceSimple
      return (
        <div 
          class={{ 'buybox__content-bstyle j-da-event-box': true, 'short-top-pading': noneLowerPriceTag }}
          {...wrapProps}
        >
          {products.map((item, index) => {
            return item._serFormatInfo.isMainGoods ? null : (
              <div 
                ref={`detail_item_${item.goods_id}`}
                {...itemProps}
                class={{
                  'buybox__content-item': true,
                  'is-lowest': item._serFormatInfo.isLowerPice && !isNewStyle,
                  'is-img-style': isItemHasImg,
                }}
                data-sku={item.goods_sn || ''}
                data-spu={item.productRelationID || ''}
                data-id={item.goods_id || ''}
                data-name={item.goods_name || ''}
                data-cat_id={item.cat_id || ''}
                data-price={item._serFormatInfo.curPrice?.amount || ''}
                data-us-price={item._serFormatInfo.curPrice?.usdAmount || ''}
                data-us-origin-price={item?.retailPrice?.usdAmount || ''}
                data-index={index}
                data-series-brand={item?.pretreatInfo?.seriesOrBrandAnalysis || ''}
                data-brand={item?.brand?.name || ''}
                data-brand-code={item?.brand?.brand_code || ''}
                data-mall_tag_code={`${item?.pretreatInfo.mallTagsInfo?.mall_tags || ''}_${item?.mall_code || '-'}`}
                data-store_code={item?.store_code || ''}
                vOn:click={() => this.handleClickItem(item, index)}
              >
                {item._serFormatInfo.isLowerPice ? (
                  <div class="lowest-tag-wrap">
                    <span class="lowest-tag">
                      {isNewStyle && (
                        <Icon name="sui_icon_best_deal_12px_color" size="12px" class="icon"/>
                      )}
                      {!isNewStyle && (
                        <Icon class="icon" name="sui_icon_best_deal_12px" size="12px" />
                      )}
                      <span>{lowerPriceLang}</span>
                    </span>
                  </div>
                ) : null
                }
                <div class="buybox__content-item-top">
                  {!isNewStyle && isItemHasImg && <div class="item-img">
                    <img src={item.goods_img} />
                  </div>}
                  <div class="item-content">
                    <ProductCardPriceSimpleComponent
                      class="item-price"
                      goods-info={item}
                      language={productItemsLanguage}
                      isPaid={isPaidUser} 
                      config={priceCardConfig}
                    />
                    <div style="font-size:0;margin-top: 3px;">
                      <BuyBoxIndexLabel item={item} buyBoxFloorQuickshipTitle={this.buyBoxFloorQuickshipTitle} isNewStyle={this.isNewStyle}/>
                    </div>
                  </div>
                  { !isItemHasImg && <div class="item-cart-btn top" vOn:click_stop={() => this.handleClickCart(item, index)}>
                    <Icon name="sui_icon_addtocart_20px" size="20px" />
                  </div>}
                  {isNewStyle && isItemHasImg && <div class={{
                    'item-img': true,
                    'new-style-img': isNewStyle,
                  }}>
                    <img src={item.goods_img} />
                  </div>}
                </div>
                <div class="buybox__content-item-bottom">
                  {
                    !isNewStyle && item._storeInfo.storeScore ? (
                      <span class="seller-rate">
                        <Icon class="seller-rate-icon" name="sui_icon_star_5_12px_2" color="#facf19" size="12px" />
                        {item._storeInfo.storeScore}
                      </span> 
                    ) : null
                  }
                  {isNewStyle && !isShowNewStoreIcon && (
                    <Icon name="sui_icon_store_14px" size="14px" class="icon" />
                  )}
                  {isNewStyle && isShowNewStoreIcon && (
                    <Icon name="sui_icon_store2_32px" size="14px" class="icon" />
                  )}
                  <span class={{
                    'seller-text': true,
                  }}>{item._storeInfo.title || defaultTitle}</span>
                  {isItemHasImg && <div class="item-cart-btn bottom" vOn:click_stop={() => this.handleClickCart(item, index)}>
                    <Icon name="sui_icon_addtocart_20px" size="20px" />
                  </div>}
                </div>
              </div>
            )
          })
          }
        </div>
      )
    },
    renderPopup() {
      const { showDrawer } = this
      if (!showDrawer) return null
      return (
        <BuyBoxDrawer
          ref="refBuyBoxDrawer"
          vOn:updateListLength={num => {
            this.innerProductsNum = num
          }}
        />
      )
    },
  },
  render() {
    if (!this.show) return null
    const { renderTop, renderContent, renderPopup, isNewStyle } = this
    return (
      <div 
        class={{ 'buybox': true, 'new-style-buybox': isNewStyle }}>
        {renderTop()}
        {renderContent()}
        {renderPopup()}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.buybox {
  background-color: #fff;
  margin-top: .213333rem;
  margin-bottom: .213333rem;
  padding: .32rem 0 .32rem 0;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 16px;
    padding-left: .32rem;
    overflow: hidden;
  }
  &__top-title {
    color: @sui_color_gray_dark1;
    .font-dpr(28px);
    font-style: normal;
    font-weight: 700;
    flex: 0 0 60%;
    .text-overflow();
  }
  &__top-text {
    text-align: right;
    display: flex;
    color: @sui_color_gray_dark2;
    .font-dpr(22px);
    font-weight: 400;
    padding-right: .32rem;
    flex: 0 0 40%;
    overflow: hidden;
    & .text {
      width: 100%;
      .text-overflow();
    }
    & .sui_icon_more_right_16px_2 {
      color: #959595;
    }
  }
  &__top-newtext {
    color: var(---sui_color_gray_dark1, #000);
    & .sui_icon_more_right_16px_2 {
      color: #000000;
    }
  }
  &__content-astyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @sui_color_gray_dark2;
    .font-dpr(24px);
    font-weight: 400;
    line-height: normal;
    padding-right: .32rem;
    margin-top: .213333rem;
    & .astyle-price {
      color: @sui_color_discount;
      margin-left: 2px;
      font-weight: 700;
    }
    & .sui_icon_more_right_16px_2 {
      color: #959595;
    }
  }
  &__content-bstyle{
    display: flex;
    padding-top: .32rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  &__content-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-right: .2133rem;
    border: 0.5px solid @sui_color_gray_weak1;

    &:first-child {
      margin-left: .32rem;
    }

    &.is-lowest {
      background: linear-gradient(0deg, rgba(255, 246, 243, 0.00) 27.72%, #FFF6F3 100%);
    }
   
    & .lowest-tag-wrap {
      position: relative;
      top: -0.2133rem;
      margin-left: -0.5px;
      margin-right: -0.5px;
      height: 0;
    }
    & .lowest-tag {
      white-space: nowrap;
      height: 14px;
      border-radius: 2px;
      border: 0.5px solid #C44A01;
      background: linear-gradient(92deg, rgba(250, 99, 56, 0.10) 0%, rgba(250, 99, 56, 0.15) 98.45%), #FFF;
      color: @sui_color_discount_dark;
      padding: 0 .1067rem;
      .font-dpr(20px);
      font-weight: 400;
      width: auto;
      display: inline-flex;
      align-items: center;

      .icon {
        margin-right: 2px;
      }
    }
  }
  &__content-item-top {
    flex: 1;
    display: flex;
    padding: .2667rem .2133rem;
    min-height: 1.19rem;
    align-items: flex-start;
    box-sizing: border-box;
    min-width: 3.28rem;

    .item-img {
      width: 1.28rem;
      height: 1.28rem;
      margin-right: 12/75rem;
      border-radius: 2px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .new-style-img {
      margin-right: 0px;
    }

    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0 45px;
      margin-right: .2133rem;
      height: 100%;
    }
    /deep/ .price-content {
      padding-right: 0;
    }
    /deep/ .product-card__text-label {
      width: 100%;
      white-space: nowrap;
    }
    /deep/ .sale-price-container {
      flex-direction: column;
    }
  }
  &__content-item-bottom {
    display: flex;
    align-items: center;
    .font-dpr(20px);
    line-height: 40/75rem;
    font-weight: 400;
    padding: .16rem .2133rem;
    color: @sui_color_brand;
    background: #FAFAFA;
    border-top: 0.5px solid @sui_color_gray_weak1;
    .icon {
      margin-right: 2px;
    }
    .seller-text {
      color: @sui_color_gray_dark3;
      opacity: 0.8;
      .text-overflow();
      padding-right: 4px;
      max-width: 2.5rem;
    }
    .seller-rate {
      display: flex;
      align-items: center;
      &::after {
        content: "";
        display: inline-block;
        width: 0.02667rem;
        height: 0.26667rem;
        background: #E5E5E5;
        margin: 0 0.11rem;
      }
    }
    .seller-rate .seller-rate-icon{
      margin-right: 2px;
    }
  }
  .short-top-pading {
    padding-top: 0.1867rem;
  }
}

.is-img-style {
  .buybox__content-item-bottom {
    height: 0.8533rem;
    border-top: none;
    .seller-text {
      max-width: 2rem;
    }
  }

  .buybox__content-item-top {
    min-width: 3.7rem;
    padding: .2133rem;
  }

  /deep/ .buybox-itemlabel {
    max-width: 92px;
  }
}

.item-cart-btn {
  display: flex;
  flex-shrink: 0;
  width: 35px;
  height: 24px;
  color: #000;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 5000px;

  &.bottom {
    margin-left: auto;
  }
}

.new-style-buybox {
  border-top: 0.5px solid rgba(250, 99, 56, 0.05);
  border-bottom: 0.5px solid rgba(250, 99, 56, 0.05);
  box-shadow: 0px 0.5px 0.5px 0px #FFF;
  background: linear-gradient(180deg, rgba(250, 99, 56, 0.20) 0%, rgba(250, 99, 56, 0.00) 127.33%), #FFF;
  .lowest-tag {
    background:#FA6338;
    border: none;
    border-radius: 2px 2px 2px 0px;
    color: #FFFDD3;
    font-size: 10px;
    font-weight: 590;
    font-family: 'SF Pro',
  }
  .buybox__content-item {
    border-radius: 4px;
    background-color: #FFF;
    border: none;
    box-shadow: 0px 1px 4px 0px rgba(255, 194, 179, 0.10);
  }
  .buybox__content-item-bottom {
    background-color: #FAFAFA;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0.5px solid #fff;
    .seller-text {
      color:#000;
      opacity: 0.8;
    }
  }
}
</style>

