import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
import DETAIL_API_LOCATION from 'public/src/pages/product_app/store/modules/product_detail/api'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { getFormatCommonCartProductItem, setCommonCartProductSkuInfo, isSkuCodeCanSale } from '../CommonCart/utils'
import { getNewCardAtomGoodsByGoods } from 'public/src/pages/goods_detail/RecommendRelatives/utils.js'
import { useAtomConfig, EAtomFieldsType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'
// import { resMock } from './mock'

/**
 * 最少主商品 + 8推荐
 */
export const MIN_COMBOBUY_RECOMMEND_NUM = 9

/**
 * 获取组合购files
 * @param {*} options 
 * @returns 
 */
export const getComboBuyNewCardAtomParams = async (options = {}) => {
  const {
    subPageKey = 'other_recommend_3pic',
    recPosKey
  } = options
  const abtRules = await getUserAbtData()

  const recReqParams = {}
  if (recPosKey) {
    recReqParams.location = recPosKey
    recReqParams.jsonRuleId = JSON.stringify(abtRules?.[recPosKey]?.param || {})
  }

  const isPic3 = subPageKey === 'other_recommend_3pic'
  const { atomConfigRec } = await useAtomConfig({
    useNewAbt: false,
    type: isPic3 ? EAtomFieldsType.sPic3Sku : EAtomFieldsType.Pic1,
    isRecommend: true
  })

  return {
    ...recReqParams,
    ...atomConfigRec,
    atomScene: {
      pageKey: 'page_goods_detail_rec',
      subPageKey
    }
  }
}

/**
 * 获取推荐数据
 */
export const getComboBuyRecommendList = async (options = {}) => {
  const { goods_id, cat_id, mall_code, itemConfig, price_abt } = options
  const scene_id = 144
  const recPosKey = 'FrequentBoughtTogether'
  // 外部一行三图
  const subPageKey = 'other_recommend_3pic'
  const url = '/api/recommend/facadeAtom/get'

  const atomParams = await getComboBuyNewCardAtomParams({
    itemConfig,
    subPageKey,
    recPosKey
  })
  /**
   * 组合购场景只返回11个商品，不进行分页，page_enable 设置为0， limit设置为11，req_num=120
   * 返回值中添加promotion_group标识商品是优惠券组合商品， 标识为空或者0则表示为折扣商品。
   */

  /**
   * 主商品信息混入推荐数据前
   */
  const mixinProducts = [
    {
      goods_id,
      mall_code
    }
  ]

  const data = {
    scene_id,
    req_num: 120, // 请求数量
    limit: 11,
    pageEnable: 0,
    offset: 0,
    pageNum: 1,
    contextParams: {
      goods_ids: goods_id,
      cate_ids: cat_id,
      mall_code,
      price_abt,
    },
    caller: 'Recommend',
    ...atomParams,
    mixinProducts,
    filterSoldOutConfig: {
      on: true,
      isPatch: true, // 过滤后补齐
      ignoreGoodsIds: [goods_id] // 主商品不过滤
    },
  }

  const res = await schttp({ url, method: 'POST', data })
  // const res = resMock

  if (!res?.info?.products?.length) return []

  // 新商品卡片的场景
  const products = await getDetailRecPreTreat({
    products: res?.info?.products,
    itemConfig
  })

  return products
}

/**
 * 根据外部一行三推荐数据获取弹窗一行一图数据
 */
const ROWS_KEY_MAP = {
  1: ['other_recommend_1pic', 'ONE_IN_A_ROW'],
  3: ['other_recommend_3pic', 'THREE_IN_A_ROW']
}
export const getComboBuyPopupProductListByGoods = async (options = {}) => {
  const { goods, itemConfig, rows = 1 } = options

  const [subPageKey, sceneKey] = ROWS_KEY_MAP[rows] || []
  const atomParams = await getComboBuyNewCardAtomParams({
    itemConfig,
    subPageKey
  })

  return await getNewCardAtomGoodsByGoods({
    goods,
    fields: atomParams.atomFields,
    cccParams: atomParams.atomCccParams,
    abtInfo: atomParams.abtInfoV3,
    itemConfig,
    subPageKey,
    sceneKey,
    isPaid: atomParams.isPaid,
  })
}

export const getFormatComboBuyProductList = (list = []) => {
  if (!list.length) return []
  const _newList = list.map(getFormatCommonCartProductItem)
  _newList[0]._cartInfo.isMainGoods = true
  // TODO 后接简易价格组件配置
  _newList.forEach((item, index) => {
    if(index !== 0) {
      item.estimatedPriceInfo = {}
    }
    // 同步一行一图角标
    if (item.mobileBroadwiseView && item.mobileBroadwiseView.locateLabels) {
      item.mobileVerticalView.locateLabels = item.mobileBroadwiseView.locateLabels
    }
  })
  return _newList
}

/**
 * 首次打开时自动勾选
 */
export const setComboBuyProductListAutoSelect = (list = []) => {
  if (!list.length) return []
  list.forEach(d => {
    /**
     * skc售罄忽略
     */
    if (d._cartInfo.isSoldOut) return
    /**
     * 主商品
     */
    if (d._cartInfo.isMainGoods) {
      d._cartInfo.selectStatus = true
      /**
       * 组合商品
       */
    } else if (d._cartInfo.isPromotionGroup) {
      d._cartInfo.selectStatus = true
    }
  })

  return list
}

/**
 * 首次打开弹窗时自动选择sku
 */
export const setComboBuyProductListAutoSkuInfo = (list = [], ignoreMainGoods = false) => {
  if (!list.length) return []
  list.forEach(d => {
    if (ignoreMainGoods && d._cartInfo.isMainGoods) return
    // skc维度售罄
    if (d._cartInfo.isSoldOut) return
    const skuCode = d?.sku_code || ''
    if (!skuCode) return

    setCommonCartProductSkuInfo(skuCode, d)
  })

  return list
}

/**
 * 设置排序 选中的在前，主商品永远在最前
 */
export const setComboBuyProductListSort = (list = []) => {
  list.sort((a, b) => {
    if (a._cartInfo.isMainGoods) return -1
    if (b._cartInfo.isMainGoods) return 1
    if (a._cartInfo._selectStatusOutSide && b._cartInfo._selectStatusOutSide)
      return 0
    if (a._cartInfo._selectStatusOutSide) return -1
    if (b._cartInfo._selectStatusOutSide) return 1
    return 0
  })
  return list
}

/**
 * 批量加车前请求售罄状态
 */
export const setComboBuyProductListSoldStatus = async (options = {}) => {
  const { itemConfig, goods } = options
  if (!goods.length) return
  // 每次点击加车更新库存数据
  const popupProductMap = getFormatComboBuyProductList(
    await getComboBuyPopupProductListByGoods({
      goods,
      itemConfig,
    })
  ).reduce((map, d) => {
    map[d.goods_id] = d
    return map
  }, {})

  const selectSoldOut = []
  const diffSoldOutList = []
  goods.forEach((d, i) => {
    const newItem = popupProductMap[d.goods_id]
    const _isSelect = d._cartInfo.selectStatus
    const _isSkcSoldOut = d._cartInfo.isSoldOut && !d._cartInfo.isOnlySkuSoldOut
    const _hasDiffSoldOut = _isSkcSoldOut !== newItem._cartInfo.isSoldOut

    if (_hasDiffSoldOut) {
      diffSoldOutList.push(newItem)
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      _isSelect && selectSoldOut.push(newItem)
      return
    }

    // 选择状态检查sku是否售罄
    if (_isSelect && d._cartInfo.skuCode && !isSkuCodeCanSale(d._cartInfo.skuCode, newItem)) {
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      selectSoldOut.push(newItem)
      return
    }
  })

  return { selectSoldOut, diffSoldOutList }
}

/**
 * ---------- 埋点相关 ----------
 */
export const baseExposeData = {
  activity_from: 'togetherbuy',
  reportCode: 'togetherbuy',
  exposeCallback: params => {
    recommendExposureListInstance.pushQueen({
      targets: params?.targets || []
    })
  }
}

export const getExposeBoxData = (options = {}) => {
  const { isPopup, poskey, tspABTest } = options
  const _bindData = {
    module: 'module',
    'review-location': '-',
    location: isPopup ? 'popup' : 'page',
    style: isPopup ? 'popup' : 'detail',
    tab_list: '-',
    'active-from': baseExposeData.activity_from
  }

  if (poskey) {
    _bindData.poskey = poskey
  }
  if (tspABTest) {
    _bindData['tsp-abtest'] = tspABTest
  }
  const bindData = {}
  for (let key in _bindData) {
    bindData[`data-${key}`] = _bindData[key]
  }
  return bindData
}

/**
 * 异步获取商品列表skc/sku售罄状态
 */
export async function setProductListSoldOut({ list = [] }) {
  const productCheckData = list
    .map(d => ({
      goodsId: String(d.goods_id),
      goodsSn: d.goods_sn,
      mallCode: d.mall_code,
      skuCode: d._cartInfo.skuCode,
    }))

  if (!productCheckData.length) return

  try {
    const result = await schttp({
      url: DETAIL_API_LOCATION.BATCH_CHECK_GOODS_STOCK,
      method: 'POST',
      data: { productCheckData },
      useBffApi: true
    })
  
    if (result?.code === '0') {
      const { productDetailData = [] } = result?.info || {}
      list?.forEach(item => {
        const checkItem = productDetailData?.find(d => d.goodsId == item.goods_id)
        if (checkItem?.isOnSale == '0' || Number(checkItem?.stock) < 1) {
          item._cartInfo.isSoldOut = true
          item.is_on_sale = Number(checkItem.isOnSale)
          item.stock = checkItem.stock
        }
      })
    }
  } catch (error) {
    console.error('setProductListSoldOut err', error)
  }

}

/**
 * 传入价格字符串,返回价格的整数(numberBeforeDot)、小数部分(numberAfterDot)、前后的币种符号(currencySymbolPrefix、currencySymbolSuffix)
 * @param {*} originString 
 * @returns 
 */
export const handlePriceHtml = (originString) => {
  // 货币符号在前
  const currencySymbolPrefix = originString?.match(/(^\D+)/)?.[0] || ''
  // 货币符号在后
  const currencySymbolSuffix = originString?.match(/(\D+)$/)?.[0] || ''
  // 去除货币符号的价格
  const priceWithoutSymbol =
    originString
      ?.replace(currencySymbolPrefix, '')
      ?.replace(currencySymbolSuffix, '') || ''
  // 价格是否有小数
  const isDecimal = !!priceWithoutSymbol?.includes('.') || !!priceWithoutSymbol?.includes(',')
  // 价格小数部分
  const numberAfterDot = isDecimal ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || '' : ''
  // 价格整数部分
  const numberBeforeDot = priceWithoutSymbol.replace(numberAfterDot, '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix }
}
