import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { ProductCardPriceAndDiscountInfo } from 'public/src/pages/components/product/item_v3/js/ProductCardPriceAndDiscountInfo/index.js'

export const getBffBuyBoxInfo = (list = [], { 
  productBffDependData = {},
  language = {},
} = {}) => {
  if (!list?.length) return []
  return list.map(item => {
    const productCardData = goodsTransformation(item, productBffDependData || {})
    const productCardPrice = new ProductCardPriceAndDiscountInfo({
      goodsInfo: productCardData,
      ...(productBffDependData || {}),
    })
    const labelList = getBffLabelInfo(item, { language })
    const { finalPrice: productCardFinalPrice = {} } = productCardPrice || {}
    const { isLowestPrice, lowestPriceDesc } = item || {}
    return {
      productCardData,
      productCardFinalPrice,
      isLowestPrice,
      lowestPriceDesc,
      labelList,
    }
  })
}

// 优先级：销量最高＞服务类标签>销量标签
export const getBffLabelInfo = (data, { language = {} } = {}) => {
  const labels = []
  const { 
    isHighestSales,
    productInfoLabels,
    dataTypeExtendProductMaterialMap,
  } = data || {}
  const { THREE_IMAGE_RECOMMEND } = dataTypeExtendProductMaterialMap || {}
  const { salesLabel, productServiceLabelList } = THREE_IMAGE_RECOMMEND || {}
  const { labelLang: salesLabelLang } = salesLabel || {}
  if (isHighestSales) {
    labels.push({ text: language?.SHEIN_KEY_PWA_30389 })
  } 
  productServiceLabelList?.forEach?.(label => {
    const { contentType } = label || {}
    if (contentType === 'quick_ship') {
      const { tag_val_name_lang, tag_text_color, tag_bg_color } = productInfoLabels?.quickShipLabel || {}
      tag_val_name_lang && labels.push({ text: tag_val_name_lang, textColor: tag_text_color, bgColor: tag_bg_color, type: 'quick_ship' })
    }else {
      const { labelLang, bakcgroundColor, fontColor } = label || {}
      labelLang && labels.push({ 
        text: labelLang,
        textColor: fontColor,
        bgColor: bakcgroundColor,
      })
    }
  })
  if (salesLabelLang) {
    labels.push({ 
      text: salesLabelLang,
      textColor: salesLabel?.fontColor,
      bgColor: salesLabel?.bgColor,
    })
  }
  return labels
}

export const baseExposeData = {
  activity_from: 'buy_box',
  reportCode: 'buy_box_v2',
}

export const getExposeBoxData = (options = {}) => {
  const { isPopup, poskey, feed_type = '' } = options
  const _bindData = {
    module: 'module',
    'review-location': '-',
    location: isPopup ? 'popup' : 'page',
    style: 'popup',
    tab_list: '-',
    feed_type,
    'active-from': baseExposeData.activity_from
  }
  if (poskey) {
    _bindData.poskey = poskey
  }
  const bindData = {}
  for (let key in _bindData) {
    bindData[`data-${key}`] = _bindData[key]
  }
  return bindData
}

export const getExposeItemDom = (data, {
  index,
  productCardFinalPrice,
} = {}) => {
  if (!data) return {}
  const { 
    goods_sn,
    productRelationID,
    goods_id,
    goods_name,
    cat_id,
    pretreatInfo,
    brand,
    mall_code,
    store_code,
    retailPrice,
  } = data
  const { seriesOrBrandAnalysis, mallTagsInfo } = pretreatInfo || {}
  const { name, brand_code } = brand || {}
  const { amount, usdAmount } = productCardFinalPrice || {}
  const mall_tag_code = `${mallTagsInfo?.mall_tags || ''}_${mall_code || '-'}`
  return {
    'data-sku': goods_sn || '',
    'data-spu': productRelationID || '',
    'data-id': goods_id || '',
    'data-name': goods_name || '',
    'data-cat_id': cat_id || '',
    'data-price': amount || '',
    'data-us-price': usdAmount || '',
    'data-us-origin-price': retailPrice?.usdAmount || '',
    'data-index': index || 0,
    'data-series-brand': seriesOrBrandAnalysis || '',
    'data-brand': name || '',
    'data-brand-code': brand_code || '',
    'data-mall_tag_code': mall_tag_code || '',
    'data-store_code': store_code || '',
  }
}
