<template>
  <div 
    class="goods-review__guidance-wrapper"
    :style="{
      'max-width': !haveDividingLine?'5.00366rem':'3.73333rem',
      'margin-left': !haveDividingLine?'0.106666rem':'0px',
      'margin-top': haveDividingLine?'0.106666rem':'0px'
    }"
  >
    <span
      v-if="!showPopover"
      class="goods-review__guidance"
      @click="goToGuidance"
    >
      <span class="goods-review__guidance-text">{{ language.SHEIN_KEY_PWA_30986 || 'Richtlinie für Kundenbewertungen' }}</span>
      <i
        class="suiiconfont sui_icon_doubt_12px_2"
      ></i>
    </span>
    <span
      v-if="showPopover"
      class="goods-review__guidance"
    >
      <span
        class="goods-review__guidance-text"
        @click="goToGuidance"
      >{{ language.SHEIN_KEY_PWA_30986 || 'Richtlinie für Kundenbewertungen' }}</span>
      <s-popover
        :append-to-body="false"
        theme="light"
        placemen="bottom"
        trigger-type="click"
        :prop-style="{
          width: '201px',
          'overflow-wrap': 'break-word',
          'word-break': 'break-word',
          'white-space': 'pre-wrap',
          'border-radius': '2px',
          'z-index': '11'
        }"
        :show-close-icon="true"
        :content="language.SHEIN_KEY_PWA_32328 || 'Wszystkie recenzje pochodzą ze zweryfikowanych transakcji.'"
      >
        <div
          v-if="!isDetail && !showReviewsGuidance"
          slot="reference"
          v-expose="{ id: '1-6-2-55' }"
          class="star-info"
        >
          <i class="suiiconfont sui_icon_doubt_12px_2"></i>
        </div>
      </s-popover>
    </span>
  </div>
</template>

<script>
const { SiteUID } = gbCommonInfo
export default {
  name: 'CommentDoubt',
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    reviewPolicyInfo: {
      type: Object,
      default: () => {},
    },
    haveDividingLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPopover() {
      return this.reviewPolicyInfo?.reviewPolicyHasPopover
    }
  },
  methods: {
    goToGuidance() {
      window.location.href =  this.reviewPolicyInfo?.reviewPolicyUrl
    },
  }
}
</script>

<style lang="less" scoped>
.goods-review {
  &__guidance-wrapper{
    display: inline-block;
    font-weight: 400;
    flex:1;
    flex-grow:1;
    color:#666666;
    .suiiconfont {
      display: inline-block;
      margin-right: 4px;
    }
  }
  &__guidance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__guidance-text {
    color: #666;
    font-size: 0.32rem;
    line-height: 0.42666rem;
    margin-right: 0.10666rem;
    flex:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
