<!--曝光埋点 店铺：j-sa-brand-store-view-products-->
<template>
  <div
    :class="{
      'short-brand-container': true,
      'follow-have-list': isStore && hasRecommendList,
      'local-type': isStore,
      'store-left-pad': showStoreQualityTags,
    }"
  >
    <div
      v-ada="{ level: 1, pos: [0] }"
      class="mgds-brand"
      :class="{
        'j-sa-brand-store local-shop': isStore,
        'j-sa-brand-store-view-products': isStore
      }"
      :aria-label="sign_data.name"
      :data-name="sign_data.name"
      :data-brand_code="sign_data.id"
      :data-goods_id="sign_data.goods_id"
      :data-brand_type="sign_data.type"
      :data-designer_id="sign_data.designerId"
      :data-sc_id="sign_data.scId"
      :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
      :data-brand_info="extAnalysisInfo.brandInfo"
      :data-brand_label="extAnalysisInfo.brandLabel"
      :data-button_label="extAnalysisInfo.buttonLabel"
      :data-store_popularity="extAnalysisInfo.storePopularity"
      :data-flash_sale_stype="flashEntryMap[flashEntryType] || 0"
      :data-is_alone_store_brand="false"
      :data-store_quality_label="store_quality_label"
      role="text"
      @click="viewProducts()"
    >
      <!-- 主内容 -->
      <div
        v-ada="{ level: 1, pos: [0] }"
        :class="['mgds-brand__detail', { center: !descriptionsShow }]"
        :aria-label="sign_data.name"
        role="text"
        aria-hidden="true"
      >
        <div
          v-if="showBrandLogo"
          class="short-brand-container__detail-logo"
        >
          <div class="img-box">
            <img :src="sign_data.logo" />
          </div>
          <div 
            v-if="formatShopScore"
            class="rate-box"
          >
            <Icon  
              class="rate-box-star"
              name="sui_icon_star_5_15px_2" 
              size="14px"
              color="#FACF19"
            />
            {{ formatShopScore }}
          </div>
        </div>
        <div class="mgds-brand__detail-info">
          <div class="mgds-brand__detail-info-title">
            <div class="text">
              <span 
                class="text_first"
                :class="{
                  'text_first_label': hasLabelsData
                }"
              >{{ sign_data.name }}</span>
              <!-- 店铺人气氛围&店铺评分信息滚动显示 -->
              <StoreAtmosphereSwiper
                v-if="showStoreAtmosphere"
                class="short-brand-container__atmosphere"
                :hasFollowInfo="hasLabelsData"
                position="showscroll"
              >
                <template #followInfo>
                  <BrandSeriesTips
                    v-if="hasLabelsData"
                    class="brand-short-tips split-top"
                    :tips-data="tipsData"
                    :display-three="true"
                    :in-fashion-store-img="in_fashion_store_img"
                    :in-quality-store-img="in_quality_store_img"
                    :text-combo="sign_board_text"
                  />
                </template>
              </StoreAtmosphereSwiper>
              <BrandSeriesTips
                v-if="!showStoreAtmosphere && hasLabelsData"
                class="brand-short-tips split-top"
                :tips-data="tipsData"
                :display-three="true"
                :in-fashion-store-img="in_fashion_store_img"
                :in-quality-store-img="in_quality_store_img"
                :text-combo="sign_board_text"
              />
            </div>
          </div>

          <!-- 描述信息在logo右侧 -->
          <template v-if="descriptionsShow && !showStoreSign">
            <div
              v-if="storeRelativeInNewStyle"
              class="desc-new"
              style="margin-top: 4px;"
            >
              {{ sign_data.desc }}
            </div>
          </template>

          <div
            v-if="storeRelativeInNewStyle"
            class="short-brand-btn"
          >
            <!-- 底部关注按钮块 -->
            <!-- language -->
            <StoreFollowBtnBox
              scene="detail"
              class-name="follow-btn-box_new"
              :store-id="storeId"
              :follow-state="storeFollowState"
              :auto-login="true"
              :text-combo="sign_board_text"
              :showLoginModal="show_login_modal"
              @state-changed="handleFollowStateChange"
              @state-changing="handleFollowStateChanging"
              @state-change-fail="handleFollowStateChangeFail"
            >
              <template slot="button">
                <div
                  v-expose="{ id: '1-6-4-63', data: followBtnAnalysisData }"
                  :class="{
                    'follow-btn': true,
                    'show-symbol': followBtnShowSymbol,
                    followed: storeFollowState
                  }"
                  @click="followBtnClick"
                >
                  <FollowStoreIcon />
                  <b class="whitespace"></b>
                  <span class="follow-btn-text">{{ storeFollowBtnText || 'Follow' }}</span>
                  <b 
                    v-if="!storeFollowState && store_follow_info.followCount" 
                    class="whitespace"
                  ></b>
                  <span 
                    v-if="!storeFollowState && store_follow_info.followCount"
                    class="follow-count"
                  >({{ store_follow_info.followCount }})</span>
                </div>
              </template>
            </StoreFollowBtnBox>
            <!-- 底部跳转按钮 -->
            <div
              class="follow-btn-box_new follow-btn-allitems"
              aria-hidden="true"
              @click.stop="viewProducts(flashEntryType === 'showflashsale' || flashEntryType === 'showflashbutton' ? 'promotion' : 'items')"
            >
              <div
                v-if="flashEntryType === 'showflashsale'"
                class="store_flash-sale"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z"
                    fill="#161616"
                  />
                </svg>
                <span>{{ sign_board_text.flashSaleLang || 'Flash Sale' }}</span>
              </div>

              <div 
                v-if="flashEntryType === 'showflashbutton'"
                class="follow-btn with-flash-icon"
              >
                <img
                  class="flash-gif-icon"
                  :src="`${PUBLIC_CDN}/pwa_dist/images/flash-sale/flash-gif-097c8939e4.gif`"
                />
                <div
                  ref="flashScrollBox"
                  class="flash-scroll-wrap"
                  :class="{
                    'has-animation': hasAnimation
                  }"
                >
                  <span 
                    ref="flashScrollContent"
                    class="flash-scroll-item"
                    :class="{
                      'has-animation': hasAnimation
                    }"
                    :style="{'animation-duration': millisecond }"
                  >
                    {{ sign_board_text.storeFlashLang || 'Store Flash Sale' }}
                  </span>
                </div>
              </div>
              <div
                v-else 
                class="follow-btn"
              >
                <p class="follow-btn-text">{{ sign_board_text.allItemLang || 'All Items' }}<b
                  v-if="store_follow_info.salesVolume"
                  class="whitespace"
                >
                </b>
                  <span 
                    v-if="store_follow_info.salesVolume"
                    class="follow-count"
                  >({{ store_follow_info.salesVolume }})</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店铺卖点信息 -->
    <StoreQualityTags
      v-if="showStoreQualityTags"
      :store-quality-list="store_quality_list"
      @goToStore="viewProducts()"
    />
  </div>
</template>

<script>

import moreMixin from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/mixins/more.js' // TODO 新组件

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'

import BrandSeriesTips from './BrandSeriesTips'
import StoreFollowBtnBox from './StoreFollowBtnBox'
import FollowStoreIcon from './FollowStoreIcon'
import StoreAtmosphereSwiper from './StoreAtmosphereSwiper'
import StoreQualityTags from './StoreQualityTags'
import { CccInit } from 'public/src/pages/common/ccc_public_v2'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { markPoint } from 'public/src/services/mark/index.js'
const { SiteUID, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'BrandSeriesEnter',
  components: {
    // 都是新组件了
    BrandSeriesTips,
    StoreFollowBtnBox, // xhr 接口还没替换
    FollowStoreIcon,
    StoreAtmosphereSwiper,
    StoreQualityTags,
  },
  mixins: [moreMixin],
  props: {
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    hideJumpText: {
      type: Boolean,
      default: false
    },
    // 是否有推荐列表
    hasRecommendList: {
      type: Boolean,
      default: false
    },
    // 跳转商品列表时带上adp参数
    setAdpParams: {
      type: Boolean,
    },
    flashEntryType: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      SiteUID,
      storeBrandDescExpend: false,
      cccPublic: null,
      PUBLIC_CDN
    }
  },
  computed: {
    // NEW BFF
    ...mapGetters('productDetail/DetailSignBoard', [
      'sign_data',
      'sign_board_text',
      'store_recommend_info',
      'label_list',
      'is_in_fashion_store',
      'in_fashion_store_img',
      'brand_zone_link',
      'store_code',
      'shop_rank',
      'storehotnews_type',
      'store_atmosphere_info',
      'store_follow_info',
      'is_quality_store',
      'store_quality_list',
      'in_quality_store_img',
      'is_show_store_quality_list',
      'store_quality_label'
    ]),
    recommendAdp() {
      return this.store_recommend_info?.recommendAdp || ''
    },
    // 店铺id
    storeId() {
      const { store_code } = this
      return store_code
    },
    formatShopScore() {
      const storeRateNum = Number(this.shop_rank) || 0
      if (!storeRateNum) {
        return ''
      }
      return storeRateNum.toFixed(2)
    },
    tipsData() {
      // 标签数据
      const { showLocalTips, label_list, saleTagText, sign_data, sign_board_text, shouldStoreRelativeShow, is_in_fashion_store, is_quality_store } = this
      const { type } = sign_data
      if (showLocalTips) {
        return {
          show: true,
          isBestSeller: !!label_list.find(item => item.labelCode == 'preferredSeller'),
          isInFashionStore: is_in_fashion_store,
          saleTagText,
          sellerText: sign_board_text.preferredLang,
          isQualityStore: is_quality_store, // 品质店铺
          type, // 可能将来还会其他店铺...
          isNewStoreBrand: false
        }
      } else if(shouldStoreRelativeShow){
        return { 
          show: true,
          isInFashionStore: is_in_fashion_store,
          isQualityStore: is_quality_store, // 品质店铺
          type
        }
      }
      return { show: false }
    },
    hasLabelsData() {
      // 识别有没有标签
      const { isBestSeller, sellerText, show } = this.tipsData
      if(!show) return false
      // 第三方店铺判断标签数量
      if (this.showLocalTips) {
        return  [isBestSeller, this.is_in_fashion_store, this.saleTagText, sellerText, this.is_quality_store].filter(item => !!item).length > 0
      }
      // 其他场景标签长度判断
      return [this.is_in_fashion_store, this.is_quality_store].filter(v => !!v).length > 0
    },
    // 店铺人气氛围显示
    showStoreAtmosphere() {
      return ['showscroll'].includes(this.storehotnews_type)
    },
    // 展示本地买家标签
    showLocalTips() {
      const { shouldStoreRelativeShow, isThirdPartyStore } = this
      if (!shouldStoreRelativeShow) return false
      return isThirdPartyStore
    },
    saleTagText() {
      let saleText = ''
      if (this.sign_data?.salesArea == 1) {
        saleText =
          this.SiteUID == 'pwus'
            ? this.sign_board_text?.['3PSellerLang'] || ''
            : this.sign_board_text?.localSellerLang || ''
      } else if (this.sign_data?.salesArea == 2) {
        saleText =
          this.SiteUID == 'pwus'
            ? this.sign_board_text?.['3PSellerLang'] || ''
            : this.sign_board_text?.marketPlaceLang || ''
      }
      return saleText
    },
    // 第三方本地平台店铺
    isThirdPartyStore() {
      return this.sign_data?.storeType == 1
    },
    // 展示店铺描述需满足4个条件，1.有描述 2.命中实验 3.shein 4.店铺还需命中标签
    descriptionsShow() {
      const { sign_data, isStore, label_list } = this
      if (!sign_data?.desc) return false
      if (isStore) {
        const hasLabel = label_list?.some(d => d.labelCode === 'Show_Description')
        if (!hasLabel) return false
      }
      return true
    },
    // 当是店铺时，展示店铺新样式，新交互
    showStoreSign(){
      return true
    },
    // 店铺相关标签、评分、商品数是否展示
    shouldStoreRelativeShow() {
      const { sign_data } = this
      return sign_data && this.isStore
    },
    // 店铺关注信息是否展示
    // TODO-remove 对应店铺230620新样式，abt全量后永远返回true，相关逻辑需要移除
    storeFollowRelativeShow() {
      const { store_follow_info } = this
      return store_follow_info?.show
    },
    storeRelativeInNewStyle() {
      const {
        storeFollowRelativeShow,
        shouldStoreRelativeShow,
      } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后storeFollowRelativeShow永远返回true
        return false
      }

      return shouldStoreRelativeShow 
    },
    salesArea() {
      return this.sign_data?.salesArea || null
    },
    // 自营店铺 / 1P店铺
    isOBMStore() {
      return [2, 6].includes(+this.sign_data?.storeType)
    },
    // 是否是店铺
    isStore() {
      return this.sign_data?.type === 'store'
    },
    // 品牌logo展示规则
    showBrandLogo() {
      return this.sign_data.logo
    },
    // 用户店铺关注状态
    storeFollowState() {
      const { storeFollowRelativeShow, store_follow_info } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后删除此逻辑
        return false
      }
      return store_follow_info?.userFollowState == 1
    },
    // 店铺关注按钮是否展示+符号
    followBtnShowSymbol() {
      const { store_follow_info } = this
      const { userFollowState, followStateChanging } = store_follow_info || {}
      return !followStateChanging && userFollowState != 1
    },
    // 店铺关注按钮文案
    storeFollowBtnText() {
      const { store_follow_info, sign_board_text } = this
      const { followStateChanging, userFollowState } = store_follow_info || {}
      if (followStateChanging) return '...'
      if (userFollowState == -1) {
        return sign_board_text?.followLang
      }
      return userFollowState == 1 ? sign_board_text?.followIngLang : sign_board_text?.followLang
    },
    viewMoreIconClass() {
      return ['suiiconfont', 'sui_icon_more_right2_16px']
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { isStore, store_follow_info, formatShopScore, tipsData, store_atmosphere_info } = this
      const { followCount, salesVolume } = store_follow_info || {}
      const brandInfo = isStore
        ? [
            `ratings_${formatShopScore}`,
            salesVolume && salesVolume !== '0' ? `sold_in_30d_${salesVolume}` : '-',
            followCount && followCount !== '0' ? `followers_${followCount}` : '-'
        ]
        : []
      const { isBestSeller, saleTagText, isQualityStore } = tipsData
      const brandLabelArr = isStore
        ? [
          isBestSeller ? 'label_id_-\'label_content_Star Seller' : '',
          saleTagText ? 'label_id_-\'label_content_Local Seller' : '',
          isQualityStore ? 'label_id_-\`label_content_choice' : ''
        ]
        : []
      const brandLabel = brandLabelArr.filter(i => !!i).join(',') || ''
      this.$emit('handleStoreBrandLabel', brandLabel)
      const buttonLabel = '-'
      // 店铺人气氛围
      let storePopularity = ''
      if(store_atmosphere_info.length){
        const popTypes = new Set()
        store_atmosphere_info.forEach(e => {
          if (e.popType) {
            popTypes.add(e.popType)
          }
          storePopularity = Array.from(popTypes).join('\'')
        })
      }else {
        storePopularity = '0'
      }
      const info = {
        abtAnaInfo: store_follow_info?.abtAnaInfo || '',
        brandInfo: brandInfo.join('`') || '',
        brandLabel,
        buttonLabel,
        storePopularity,
      }
      this.$emit('handleStoreBrandAnalysis', info)
      return info
    },
    // 店铺关注按钮埋点信息
    followBtnAnalysisData() {
      const { store_follow_info, storeId, sign_data } = this
      const { userFollowState } = store_follow_info || {}
      const { type = '' } = sign_data || {}
      return {
        action_tp: userFollowState == 1 ? 'followed' : 'unfollowed',
        brand_code: storeId,
        // 实际上肯定是店铺
        brand_type: type
      }
    },
    authenticbrandShowAnalysisData() {
      const { sign_data } = this
      return {
        // 商品id 提交的goods_id
        goods_id: sign_data.goods_id,
        // 品牌code 当前的品牌模块的品牌brand_code
        brand_code: sign_data?.brand_code
      }
    },
    // 显示店铺卖点评分
    showStoreQualityTags() {
      return this.isStore && this.is_show_store_quality_list
    }
  },
  methods: {
    ...mapMutations('productDetail/DetailSignBoard', ['update_store_follow_info']),

    ...mapActions('productDetail/common', ['show_login_modal', 'detail_to_store_data']),
    viewProducts(tabId) {
      const { type, id, link, name, designerId, scId } = this.sign_data
      const { shop_rank, analysis } = this
      const { sign_data } = this
      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : this.isStore
              ? `thirdPartyStoreHome_${id}`
              : `itemPicking_${scId}`
        }`
      ].join('`')
      // 新店招交互
      if(this.showStoreSign) {
        markPoint('toNextPageClick', 'public')
        const params = {
          src_module: analysis?.src_module,
          src_identifier: src_identifier,
          src_tab_page_id: getSaPageInfo.tab_page_id || ''
        }
        // 新店招交互点击allitems上报
        this.clickStoreReports({ tabId, type, id, src_identifier })
        if(!tabId){  // 不是allitems 列表的入口点击
          // 往店铺塞数据
          this.detail_to_store_data()
        }

        const { langPath } = gbCommonInfo
        const queryObj = {
          adp: sign_data.goods_id,
          store_code: this.store_code,
          ...params,
        }
        if (tabId) {
          queryObj.tabId = tabId
          queryObj.isStoreTabSticky = '1'
        }
        const url = `${langPath}/store/home?${stringifyQueryString({
          queryObj
        })}`
        storePageDataManager.prerenderRequest({
          fullPath: url,
          query: queryObj,
        })
        this.$router.push(url)
        return 
      }
      // 点击跳转店铺上报
      this.clickStoreReports({ tabId, type, id, src_identifier })    
      this.boardMoreHandle({
        sort: '',
        isStoreTabSticky: tabId ? '1' : '',
        tabId,
        type,
        id,
        link,
        shop_rank,
        goods_id: sign_data.goods_id,
        cat_id: sign_data.cat_id,
        adp: !this.setAdpParams ? '' : this.recommendAdp,
        analysis: { src_identifier, src_module: analysis?.src_module }
      })
    },
    clickStoreReports({ tabId, type, id, src_identifier }) {
      const { brandInfo, brandLabel, buttonLabel } = this.extAnalysisInfo
      const flash_sale_stype = this.flashEntryType === 'showflashsale' ? 2 : (this.flashEntryType === 'showflashbutton' ? 3 : 0)
      daEventCenter.triggerNotice({
        daId: tabId ? '1-6-1-194' : '1-6-4-17',
        extraData: {
          brand_type: type,
          brand_code: id,
          // abtest: abtAnaInfo,
          brand_info: brandInfo,
          brand_label: brandLabel,
          button_label: tabId ? buttonLabel : '',
          page_nm: 'page_goods_detail',
          src_identifier,
          flash_sale_stype: tabId ? flash_sale_stype : undefined,
          ...(tabId ? {} : { store_qualitylabel: this.store_quality_label })
        }
      })
    },
    // 关注按钮点击
    // * 此处只处理埋点事件，关注状态的切换冒泡至 StoreFollowBtnBox 组件进行处理
    followBtnClick() {
      const { followBtnAnalysisData, store_follow_info } = this
      const { userFollowState } = store_follow_info || {}
      const notLogin = typeof SHEIN_COMMON !== 'undefined' && SHEIN_COMMON?.isLogin?.() === false
      daEventCenter.triggerNotice({
        daId: '1-6-4-64',
        extraData: {
          ...followBtnAnalysisData,
          action_tp: notLogin ? 'follow' : !userFollowState ? 'follow' : 'cancel'
        }
      })
    },
    // 更改关注状态成功
    handleFollowStateChange({ state, count }) {
      const { store_follow_info, followBtnAnalysisData } = this
      const userFollowState = state ? 1 : 0
      this.update_store_follow_info({
        ...store_follow_info,
        userFollowState,
        followCount: count
      })
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 1,
          reason_tp: '-',
          action_tp: userFollowState ? 'follow' : 'cancel'
        }
      })
    },
    // 变更关注状态失败
    handleFollowStateChangeFail(err) {
      const { followBtnAnalysisData, store_follow_info } = this
      const { userFollowState } = store_follow_info || {}
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 0,
          reason_tp: err?.code || '-1',
          action_tp: !userFollowState ? 'follow' : 'cancel'
        }
      })
    },
    goToBrandZone() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-82',
        extraData: {
          goods_id: this.sign_data.goods_id,
          brand_code: this.storeId,
        }
      })
      if(!this.cccPublic) {
        this.cccPublic = new CccInit({
          context: this.brand_zone_link,
          propData: this.brand_zone_link.content,
          index: 1,
        })
      }
      this.cccPublic.clickLink({
        item: this.brand_zone_link.content,
        cccCateInfo: {},
        gaIndex: 0,
        scIndex: 0,
      })
    },
    // 关注状态变更中
    handleFollowStateChanging(state) {
      const { store_follow_info } = this
      this.update_store_follow_info({
        ...(store_follow_info || {}),
        followStateChanging: state
      })
    }
  }
}
</script>

<style lang="less">
.base-text-ellipsis {
  // 一行超出省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.short-brand-container {
  padding: 0.2667rem 0.346666rem;
  // background: #fff;
  &__detail-logo {
    .margin-r(16/75rem);
    width: 1.3867rem;
    .border-dpr(border, 1px, #e5e5e5);
    .flexbox();
    .align-center();
    flex-shrink: 0;
    flex-direction: column;
    background-color: #fff;
    .img-box {
      width: 1.3333rem;
      height: 1.3333rem;
      > img {
        width: 100%;
      }
    }
    .rate-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #222;
      .font-dpr(22px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      height: 0.48rem;
      .border-dpr(border-top, 1px, #e5e5e5);
      background: linear-gradient(0deg, #FFFCF3 0%, #FFFCF4 100%);
    }
    .rate-box-star {
      margin-right: 2px;
    }
  }
  &.local-type {
    padding: 0.2667rem 0.32rem;
  }
  /* 调整底部有列表时的店铺间距, 在外层定义class的值 */
  &.have-list {
    padding-bottom: 16/75rem;
    .mgds-brand__detail-info-descriptions {
      margin-bottom: 0.1067rem;
    }
  }
  
  &.follow-have-list {
    padding-bottom: 0.32rem;
  }

  .text {
    justify-content: flex-start;
  }

  .text_first {
    flex: 1;
    max-width: fit-content;
  }
  .text_first_label {
    flex: 0 0 47%;
  }
  &.store-left-pad {
    padding-right: 0;
    .mgds-brand {
      padding-right: 0.32rem;
    }
  }
  &__atmosphere {
    flex: 1;
  }
}

.mgds-brand {
  .flexbox();
  .space-between();
  .align-center();
  &__detail {
    .flexbox();
    overflow: hidden;
    .align-center();

    &.center {
      .align-center();
    }
  }
  &__detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
  }
  &__detail-info-title {
    .text {
      .font-dpr(28px);
      color: #333;
      font-weight: bold;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      gap: 0.1067rem;
      width: 100%;
      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      i {
        color: @sui_color_gray_light1;
        margin: 0 0.106667rem;
      }
    }
    .brand-short-tips {
      flex: 0 0 53%;
      .local_tip {
        max-width: 1.3333rem;
        display: inline-block;
        line-height: 0.3733rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  &__detail-info-descriptions {
    font-size: 12px;
    margin-top: 0.32rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  }
  &__tip-container{
    display: flex;
    flex-direction: column;
    margin-top: 0.1067rem;
    gap: 0.1067rem;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .split-left {
    margin-left: 0.1067rem;
  }

  .store-info-inline {
    .key {
      color: @sui_color_gray_dark2;
      font-weight: 400;
    }
    .value {
      color: @sui_color_gray_dark1;
      font-weight: 500;
    }
  }
}

// 本地店铺
.local-shop {
  .mgds-brand {
    &__detail-info-title {
      .text {
        line-height: 0.4533rem;
      }
    }
  }
}
.desc-new {
  // 一行超出省略号
  .base-text-ellipsis();
  color: #818181;
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.short-brand-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.1067rem;
  margin-left: -0.05rem;

  .follow-btn-box_new {
    margin: 0.11rem;

    .follow-btn {
      position: relative;
      display: flex;
      width: 3.52rem;
      height: 0.64rem;
      padding: 0 0.11rem;
      justify-content: center;
      align-items: center;
      .font-dpr(24px);
      line-height: 0.32rem;
      border: 1px solid #959595;
      border-radius: 0.32rem;
      color: @sui_color_gray_dark1;
      /* stylelint-disable-next-line */
      &.show-symbol {
        /* stylelint-disable-next-line */
        svg,
        .whitespace {
          display: inline-block;
        }
      }
      /* stylelint-disable-next-line */
      &.followed {
        color: @sui_color_gray_light1;
        border-color: @sui_color_gray_weak1;
      }
      &.with-flash-icon{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .flash-scroll-wrap{
          overflow: hidden;
          white-space: nowrap;
          vertical-align: top;
          &.has-animation{
            position: relative;
            flex: 1;
            height: 0.3733rem
          }
        }
        .flash-scroll-item {
          display: inline-block;
          &.has-animation{
            position: absolute;
            left: 0;
            animation: flashScroll linear 4s alternate infinite;
          }
        }
        @keyframes flashScroll {
          0% {
            left: 0;
            transform: translateX(0);
          }
          35% {
              left: 0;
              transform: translateX(0);
          }
        
          65% {
              left: 100%;
              transform: translateX(-100%);
          }
          100% {
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }
      .flash-gif-icon{
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.1067rem;
        flex: 0 0 auto;
      }
      span {
        display: inline-block;
      }
      /* stylelint-disable-next-line */
      svg {
        display: none;
      }
      /* stylelint-disable-next-line */
      .whitespace {
        width: 0.106667rem;
        height: 0.106667rem;
      }
      .follow-count {
        color: #666;
      }
    }
    .follow-btn-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .whitespace {
        display: inline-block;
        width: 0.106667rem;
        height: 0.106667rem;
      }
      .follow-count {
        color: #666;
      }
    }
  }
  .follow-btn-allitems{
    position: relative;
    margin: 0.11rem;

    .store_flash-sale{
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0 0.1067rem 0 0.08rem;
      height: 0.347rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      font-size: 0.24rem;
      color: #000;
      z-index: 1;
      background-image: url('/pwa_dist/images/flash-sale/scan_flash-6a3950325b.gif');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .store_new-arrival{
      position: absolute;
      display: inline-block;
      height: 0.37333rem;
      line-height: 0.37333rem;
      padding: 0 0.05333rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      border-radius: 0.08rem 0.08rem 0.08rem 0;
      border: 1px solid #FFF;
      background: #3CBD45;
      font-size: 0.26667rem;
      color: #fff;
      z-index: 1;
    }
  }
}

</style>
