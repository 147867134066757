<template>
  <div class="recommend-v2">
    <!-- 标题占位 -->
    <div 
      v-if="originConfig.title"
      class="recommend-v2__title"
    >
      <p 
        class="recommend-v2__title-main"
        style="width: 48%"
      >
        <span 
          class="recommend-v2__title-txt recommend-v2__title-txtholder"
          :style="{
            color: '#f6f6f6',
            backgroundColor: '#f6f6f6'
          }"
        > holder </span>
      </p>
    </div>
    <!-- tab占位 -->
    <!--  -->
    <RecommendSliderList 
      v-if="sliderRecommend"
      :row-num="rowNum"
      :placeholder-status="true"
    />
    <!-- 商品占位 -->
    <RecommendList
      v-else
      :row-num="rowNum"
      :placeholder-status="true"
    />
  </div>
</template>

<script>
// import RecommendSliderList from './components/sliderList.vue'
import RecommendList from './components/list.vue'
export default {
  name: 'RecPlaceHolder',
  components: {
    // RecommendSliderList,
    RecommendList,
    RecommendSliderList: () => import(/* webpackChunkName: "recommend-sliderList" */'./components/sliderList.vue'),
    // RecommendList: () => import(/* webpackChunkName: "recommend-list" */'./components/list.vue'),
  },
  props: {
    sliderRecommend: {
      type: Boolean,
      default: false,
    },
    originConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    originProductConfig() {
      return this.originConfig?.product || this.originConfig?.multiProduct?.[0] || {}
    },
    rowNum() {
      return this.originProductConfig?.rowNum || 2
    },
  }
}
</script>

