<template>
  <div
    v-expose="exposeProps"
    class="buybox-item"
    v-bind="exposeItemDom"
    @click="() => handleClickItem(item, index)"
  >
    <div
      v-if="lowestDesc"
      class="buybox-item__lowest-wrap"
    >
      <span class="buybox-item__lowest-tag">
        <Icon
          name="sui_icon_best_deal_12px_color"
          size="12px"
          class="icon"
        />
        <span>{{ lowestDesc }}</span>
      </span>
    </div>
    <div class="buybox-item__content-top">
      <div class="buybox-item__content">
        <components 
          :is="ProductCardPriceSimpleComponent" 
          :goods-info="item && item.productCardData"
          v-bind="productPriceProps"
        />
        <div
          :style="{
            fontSize: 0,
            marginTop: '3px',
          }"
        >
          <BuyBoxProductLabel
            :item="item"
          />
        </div>
      </div>
      <div class="buybox-item__content-img">
        <img :src="goodsImg" />
      </div>
    </div>
    <div class="buybox-item__bottom">
      <Icon
        :name="isShowNewStoreIcon ? 'sui_icon_store2_32px' : 'sui_icon_store_14px'"
        :is-responsive-name="true"
        size="14px"
        class="icon"
      />
      <span class="seller-text">{{ storeTitle }}</span>
      <div
        class="buybox-item__bottom-btn"
        @click.stop="() => handleClickCart(item, index)"
      >
        <i class="suiiconfont-critical suiiconfont sui_icon_addtocart_20px product-card__add-bag j-icon-addtobag"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { baseExposeData, getExposeItemDom } from './utils.js'
import { Icon } from '@shein-aidc/icon-vue2'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import ProductCardPriceSimpleSuggested from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimpleSuggested.vue'
import BuyBoxProductLabel from './BuyBoxProductLabel.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => { }
  },
  productPriceProps: {
    type: Object,
    default: () => {}
  },
  defaultTitle: {
    type: String,
    default: ''
  },
  index: {
    type: Number,
    default: 0
  },
  isShowNewStoreIcon: {
    type: Boolean,
    default: false
  },
  isSuggestPrice: {
    type: Boolean,
    default: false
  },
  handleClickItem: {
    type: Function,
    default: () => { }
  },
  handleClickCart: {
    type: Function,
    default: () => { }
  },
})
const exposeProps = {
  id: '2-3-2',
  code: baseExposeData.reportCode,
}
const exposeItemDom = computed(() => {
  const { productCardData, productCardFinalPrice } = props.item || {}
  return getExposeItemDom(productCardData, { index: props.index, productCardFinalPrice })
})
const lowestDesc = computed(() => {
  const { isLowestPrice, lowestPriceDesc } = props.item || {}
  return isLowestPrice && lowestPriceDesc
})
const ProductCardPriceSimpleComponent = computed(() => props?.isSuggestPrice ? ProductCardPriceSimpleSuggested : ProductCardPriceSimple)
const goodsImg = computed(() => props.item?.productCardData?.goods_img)
const storeTitle = computed(() => props.item?.productCardData?.storeInfo?.title || props.defaultTitle || '')
</script>

<style lang="less" scoped>
.buybox-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-right: .2133rem;
    border-radius: 4px;
    background-color: #FFF;
    border: none;
    box-shadow: 0px 1px 4px 0px rgba(255, 194, 179, 0.10);

    &:first-child {
      margin-left: .32rem;
    }

    &__lowest-wrap {
        position: relative;
        top: -0.2133rem;
        margin-left: -0.5px;
        margin-right: -0.5px;
        height: 0;
    }

    &__lowest-tag {
        white-space: nowrap;
        height: 14px;
        padding: 0 .1067rem;
        width: auto;
        display: inline-flex;
        align-items: center;

        background:#FA6338;
        border: none;
        border-radius: 2px 2px 2px 0px;
        color: #FFFDD3;
        font-size: 10px;
        font-weight: 590;
        font-family: 'SF Pro';

        .icon {
            margin-right: 2px;
        }
    }

    &__content-top {
        flex: 1;
        display: flex;
        min-height: 1.19rem;
        align-items: flex-start;
        box-sizing: border-box;
        min-width: 3.7rem;
        padding: .2133rem;

        /deep/ .price-content {
            padding-right: 0;
        }
        /deep/ .product-card__text-label {
            width: 100%;
            white-space: nowrap;
        }
        /deep/ .sale-price-container {
            flex-direction: column;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 45px;
        margin-right: .2133rem;
        height: 100%;
    }

    &__content-img {
        width: 1.28rem;
        height: 1.28rem;
        margin-right: 0px;
        border-radius: 2px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        .font-dpr(20px);
        line-height: 40/75rem;
        font-weight: 400;
        padding: .16rem .2133rem;
        color: @sui_color_brand;
        height: 0.8533rem;
        border-top: none;
        background-color: #FAFAFA;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0.5px solid #fff;

        .icon {
            margin-right: 2px;
        }

        .seller-text {
            color:#000;
            opacity: 0.8;
            .text-overflow();
            padding-right: 4px;
            max-width: 2rem;
        }
    }

    &__bottom-btn {
        display: flex;
        flex-shrink: 0;
        width: 35px;
        height: 24px;
        color: #000;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        border-radius: 5000px;
        margin-left: auto;
    }
}
</style>
