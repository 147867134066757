<script lang="jsx">
import { mapState, mapGetters, mapActions } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { ImageBox } from '@shein/sui-mobile'
import SProductItemV3 from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'

import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index.js'
import { getQuickAddCartInstance, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common'
import { SCENE_MAP } from 'public/src/pages/components/product/quick_add_v3/optionsConfig' 
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'NewGetTheLook',
  components: {
    Icon,
    SImageBox: ImageBox,
    SProductItemV3,
  },
  props: {
    
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState('newProductDetail/common', [
      'addToBagMainGoods',
    ]),
    ...mapGetters('productDetail/Recommend', [
      'get_the_lookA_info',
      'product_bff_depend_data',
    ]),
    ...mapGetters('productDetail/common', [
      'common_page_info',
      'language_v2',
    ]),
    lookAGtlInfo() {
      return this.get_the_lookA_info || {}
    },
    // 除主商品以外的套装商品
    gtlProducts() {
      const { productInfoList } = this.lookAGtlInfo
      const { language, lang, isPaid, suggestedSaleType } = this.product_bff_depend_data
      const products = productInfoList?.slice(1).map(v => {
        return {
          ...goodsTransformation(v, {
            language: language,
            lang: lang ?? 'us',
            isPaid: isPaid,
            suggestedSaleType,
          }),
        }
      })
      return products || []
    },
    mainGoods() {
      const { productInfoList } = this.lookAGtlInfo
      return productInfoList?.[0]
    },
    productItemsConfig() {

      const { itemConfig } = useItemConfig({
        name: 'NewGetTheLookAlone',
        type: EItemConfigType.Pic3,
        isRecommend: true,
        mergeConfig: {
          style: {
            pricesNewline: true
          },
          showAddBagBtn: false,
          showAddBagBtnBottomRight: false,
          disableMainimgJump: true,
          hideDiscountBadge: true,
          hideDiscountLabel: true, // 隐藏折扣角标
          showDiscountLabelAfterPrice: false,
          hideBottomCccTspBadge: true,
          showBadge: false,
        }
      })
      return itemConfig.value
    },
    getExposeBoxData() {
      const { detailSeriesNo } = this.lookAGtlInfo

      const _bindData = {
        module: 'module',
        'review-location': '-',
        location: 'popup',
        style: 'popup',
        tab_list: '-',
        'active-from': 'newoutfit_alone',
        series_no: detailSeriesNo || '-',
      }
      const bindData = {}
      for (let key in _bindData) {
        bindData[`data-${key}`] = _bindData[key]
      }
      return bindData
    },
    cPriceConfig() {
      const { complianceModeDe } = this
      const { itemConfig } = useItemConfig({ // todo 移出计算属性放setup
        name: 'NewGetTheLookPriceSimple',
        type: EItemConfigType.sSimplePrice,
        mergeConfig: {
          hidePriceBottomLabel: true,
          ...(complianceModeDe ? { // de合规价需要设置价格黑色
            priceColor: '#000'
          } : {})
        }
      })

      return itemConfig.value
    },
    
  },
  watch: {
    gtlProducts: {
      handler(list) {
        this.$nextTick(() => {
          const renderItems = list?.map((d, i) => this?.$refs[`newGetTheLook__item_${i}`])?.filter(d => d)
          if (Array.isArray(renderItems) && renderItems.length) {
            renderItems.forEach(goodsItem => {
              goodsItem.setElAttr()
            })
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
    // daEventCenter.triggerNotice({
    //   daId: '1-6-6-4',
    //   extraData: this.getBaseAnalysisData()
    // })
  },
  destroyed() {
    
  },
  methods: {
    ...mapActions('newProductDetail', ['openGetTheLookNewPopup']),
    handleOpenOutfitPop() {
      this.openGetTheLookNewPopup({ initCateTab: '', activeFrom: 'outfitalone' })
      daEventCenter.triggerNotice({
        daId: '1-6-6-3',
        extraData: {
          click_type: 'button',
          ...this.getBaseAnalysisData(),
        }
      })
    },
    getBaseAnalysisData() {
      const { themeId, lookType, productInfoList, detailSeriesNo = '-' } = this.lookAGtlInfo || {}
      return {
        location: 'outfitalone',
        goods_num: productInfoList?.length || 0,
        theme_id: themeId,
        look_type: lookType,
        series_no: detailSeriesNo
      }
    },
    async handleQuickAdd(payload) {
      const { target, index } = payload
      const { imgRatio } = payload
      const itemDom = this.$refs['newGetTheLook__item_' + index]?.$el
      if (itemDom) {
        // daEventCenter.triggerNotice({
        //   daId: '2-3-1',
        //   target: itemDom,
        // })
      }
      // 只有主商品显示到手价
      const showEstimatedPrice = !!payload?._cartInfo?.isMainGoods
      if(canUseNewQuickAdd('details')) {
        getQuickAddCartInstance(SCENE_MAP.DETAILS_REC).open({
          goods_id: payload.goods_id,
          mallCode: payload.mall_code,
        }, {
          sourceIdentifier: 'detailPage',
          extendedParam: {
            imgRatio
          },
          featureConfig: {
            showBestDealLabel: true,
            showEstimatedPrice
          },
          analysisConfig: {
            sourceTarget: target,
            sa: {
              activity_from: 'newoutfit_alone',
            },
            index
          },
        })
      } else {
        this.$quickAdd.open({
          goods_id: payload.goods_id,
          imgRatio,
          mallCode: payload.mall_code,
          index,
          reducePrice: payload.reducePrice,
          addSource: 'detailPage',
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
          showEstimatedPrice,
          showAbPrice: true, // 展示ab价
          analysisConfig: {
            sourceTarget: target,
            sa: {
              activity_from: 'newoutfit_alone',
            }
          },
          clickCallBack: {
            isActive: status => {
              if (status) {
                // this.reportOpenQuickView(target, payload, index)
              }
            },
            // 加车成功后的回调
            complete: () => {
              setTimeout(() => {
                window.vBus &&
                  window.vBus.$emit('triggerAddCompletedFloatCart', {
                    animation: false
                  })
              }, 2000)
            }
          }
        })
      }
    },
    openQuickAdd(payload) {
      this.handleQuickAdd({ ...payload, ...payload?.item })
    },
  },
  render() {
    const { 
      lookAGtlInfo,
      mainGoods,
      gtlProducts,
      handleOpenOutfitPop,
      handleQuickAdd,
      openQuickAdd,
      productItemsConfig,
      language_v2,
      addToBagMainGoods,
      getExposeBoxData,
    } = this
   
    const showNewGetTheLook = !!lookAGtlInfo?.companionImageUrl && !!addToBagMainGoods?.skuCode
    const { language: product_items_language  } = this.product_bff_depend_data
    const { bundleUnitDiscount, companionImageUrl, productInfoList } = lookAGtlInfo
    const producstNum = gtlProducts?.length
    const isDiscount = !!bundleUnitDiscount &&  bundleUnitDiscount !== '0'
    const isDiscountText = template(producstNum, `<span style="color: #FA6338;font-weight: 590;">${bundleUnitDiscount}%</span>`, language_v2.SHEIN_KEY_PWA_34542 || 'Get {0} items to enjoy {1} off')
    const renderLookA = () => {
      return (
        <div 
          class="newGetTheLook__lookA"
          vOn:click={handleOpenOutfitPop} 
        >
          <img src={companionImageUrl} />
          {
            productInfoList?.length && <div class="newGetTheLook__lookA-total">
              { productInfoList?.length } {language_v2.SHEIN_KEY_PWA_20819 || 'Items'}
            </div>
          }
        </div>
      )
    }
    const renderMainGoods = () => {
      const { priceHtml, priceColor } = addToBagMainGoods
      
      return (
        <div class="newGetTheLook__goods">
          <div class="newGetTheLook__goods-content">
            <div class="newGetTheLook__img-content">
              <img src={mainGoods?.goods_img} />
            </div>
            <div 
              class="newGetTheLook__goods-price" 
              domPropsInnerHTML={priceHtml} 
              style={{ color: priceColor }}
            ></div>
            <div class="newGetTheLook__goods-modal"></div>
          </div>
          <div class="newGetTheLook__goods-add">
            <Icon name="sui_icon_selected_12px" size="12px" color="#198055" />
            { language_v2.SHEIN_KEY_PWA_34540 || 'Added' }
          </div>
        </div>
      )
    }

    const renderSProductItemV3 = () => {
      const itemProps = {
        directives: [
          {
            name: 'expose',
            value: { 
              id: '2-3-2', 
              code: 'newoutfit_alone',
              exposeCallback: params => {
                recommendExposureListInstance.pushQueen({
                  targets: params?.targets || []
                })
              }
            }
          }
        ],
        props: {
          language: product_items_language,
          column: 4.5
        },
      }
      const wrapProps = {
        attrs: getExposeBoxData
      }
      return (
        <div
          class="newGetTheLook__products j-da-event-box"
          {...wrapProps}
        >
          {
            gtlProducts.map((item, index) => {
              return (
                <SProductItemV3
                  ref={`newGetTheLook__item_${index}`}
                  item={item}
                  config={productItemsConfig}
                  {...itemProps}
                  vOn:clickItem={payload => handleQuickAdd(payload, index)}
                >
                  <template slot="imgBottomInTop">
                    <div class="newGetTheLook__addtobag-icon">
                      <Icon 
                        name="sui_icon_addtobag_20px" 
                        size="16px" 
                      />
                    </div>
                  </template>
                </SProductItemV3>
              )
            })
          }
        </div>
      )
    }
    const renderContent = () => {

      return (
        <div class="newGetTheLook__box">
          <div class="newGetTheLook__list">
            {renderLookA()}
            {renderMainGoods()}
            {renderSProductItemV3()}
          </div>
        </div>
      )
    }

    const itemProps = {
      directives: [
        {
          name: 'expose',
          value: { 
            id: '1-6-6-4',
            data: this.getBaseAnalysisData()
          }
        }
      ],
    }
    return (
      showNewGetTheLook ? 
        <div 
          class="newGetTheLook__wrapper" 
          data-floor-tab="newGetTheLook"
          {...itemProps}
        >
          <div 
            class="newGetTheLook__header"
            vOn:click={handleOpenOutfitPop}
          >
            <span class="newGetTheLook__left">
              <span class="newGetTheLook__title">{ language_v2.SHEIN_KEY_PWA_34538 || 'Get the Look' }</span>
              <span class="newGetTheLook__discount">
                {
                  isDiscount ? 
                    [
                      <Icon name="sui_icon_activity_orange_12px" size="12px" color="#FA6338" />,
                      <span class="newGetTheLook__discount-text" domPropsInnerHTML={isDiscountText} ></span>
                    ]
                    :
                    template(producstNum, language_v2.SHEIN_KEY_PWA_34543 || 'Get {0} items to complete the look') 
                }
              </span>
            </span>
            <span class="newGetTheLook__button">
              { language_v2.SHEIN_KEY_PWA_34539 || 'Get'}</span>
          </div>
          {renderContent()}
        </div>
        : null
    )
  }
}
</script>

<style lang="less">
.newGetTheLook{
  &__wrapper{
    padding: 0.32rem;
    background: #fff;
    margin-top: 0.32rem;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.21rem;
  }
  &__left{
    flex: 1;
    overflow: hidden;
  }
  &__title{
    font-weight: 700;
    font-size: 14px;
    color: #222;
    .text-overflow();
    width: 100%;
    display: inline-block;
  }
  &__discount{
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__discount-text{
    margin-left: 0.05rem;
    flex: 1;
    .text-overflow();
  }
  &__button{
    height: 0.64rem;
    line-height: 0.64rem;
    min-width: 1.28rem;
    border-radius: 0.72rem;
    background: #000;
    color: #fff;
    text-align: center;
    margin-left: 0.21rem;

  }
  &__list{
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  &__lookA{
    width: 2.52rem;
    height: 3.36rem;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    background: #F6F6F6;
  }
  &__lookA-total{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5553rem;
    line-height: 0.5553rem;
    text-align: center;
    color: #fff;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.60);
    border-radius: 4px;
  }
  &__lookA, &__goods{
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
  &__goods{
    width: 1.92rem;
    flex-shrink: 0;
    margin-left: 0.23rem;
  }
  &__goods-content{
    position: relative;
  }
  &__img-content{
    width: 100%;
    height: 2.56rem;
    display: flex;
    align-items: center;
    background: #F6F6F6;
  }
  &__goods-price{
    height: 0.5887rem;
    line-height: 0.5887rem;
    padding: .11rem 0 0;
    font-weight: 700;
    color: #222;
  }
  &__goods-modal{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.60);
  }
  &__goods-add{
    font-size: 10px;
    color: #000000;
    .text-overflow();
  }
  &__products{
    margin-left: 0.23rem;
    display: flex;
    .multiple-row-item{
      margin-left: 0.23rem;
    }
    .product-card__bottom-wrapper{
      padding: 0.11rem 0 0 0;
    }
  }
  &__addtobag-icon{
    width: 0.64rem;
    height: 0.64rem;
    border-radius: 50%;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.10667rem 0.10667rem 0;
  }
}
.newGetTheLook__products{
  .multiple-row-item{
    border-radius: 0;
  }
}
</style>
