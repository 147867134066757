<template>
  <div class="toy-warning">
    <div
      v-if="warningTextList.length === 1"
      class="toy-warning__text"
    >
      <Icon
        class="toy-warning__icon"
        name="sui_icon_caution_18px"
        size="14px"
        color="#EF9B00"
      />
      {{ warningTitle }}:
      {{ warningTextList[0] }}
    </div>
    <template v-else>
      <p>
        <Icon
          class="toy-warning__icon"
          name="sui_icon_caution_18px"
          size="14px"
          color="#EF9B00"
        />
        {{ warningTitle }}:
      </p>
      <ul class="toy-warning__list">
        <li
          v-for="item in warningTextList"
          :key="item"
          class="toy-warning__item"
        >
          <span class="toy-warning__dot"></span>
          <span class="toy-warning__text">{{ item }}</span>
        </li>
      </ul>
    </template>
  </div>
</template>

<script setup name="ToyWarningTips">
import { computed, defineProps } from 'vue'

const props = defineProps({
  toyWarningInfo: {
    type: Object,
    default: () => {}
  },
})

const warningTitle = computed(() => props.toyWarningInfo?.warningTitle || '')
const warningTextList = computed(() => props.toyWarningInfo?.warningTextList || [])
</script>

<style lang="less">
.toy-warning{
  &__icon{
    position: relative;
    top: -1px;
  }
  &__text{
    .line-camp(3);
  }
  &__item{
    display: flex;
  }
  &__dot{
    flex-shrink: 0;
    display: inline-block;
    width: .10667rem;
    height: .10667rem;
    border-radius: 50%;
    background-color: #000;
    margin: 0.16rem 0.2133rem 0;
  }
}
</style>
