<script lang="jsx">
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'


// 新组件
import DescriptionDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/DescriptionDrawer.vue'
import DescriptionEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/Enter.vue'
import DetailFPos from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/DetailFPos.vue'
import DetailOPos from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/DetailOPos.vue'
import UserManualEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/UserManualEnter/index.vue'
import UserManualDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/UserManualDrawer.vue'
import NewSizeGuideEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/SizeGuideEnter/index.vue'
import StoreBusinessBrandEntrace from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/StoreBusinessBrandEntrace.vue'

import NewModelIntro from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ModelIntro/index.vue'
import ToyWarningTips from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ToyWarningTips/index.vue'
import EuRespPerson from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/EuRespPerson/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-6-4' })

export default {
  name: 'MiddleAttr',
  components: {
    // 降级组件

    // 新组件
    DetailFPos,
    DetailOPos,
    DescriptionEnter,
    DescriptionDrawer,
    ToyWarningTips,
    EuRespPerson,
    NewSizeGuideEnter,
    StoreBusinessBrandEntrace,
    NewModelIntro,
    UserManualEnter,
    UserManualDrawer,
    NewIngredientsEnter: () => // TODO levi 为啥异步
      import(
        'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/IngredientsEnter/index.vue'
      ),
  },
  computed: {
    ...mapState('newProductDetail/common', ['currentLocalCountry']), // 新数据结构
    ...mapState('newProductDetail', ['descriptionReady', 'userManualReady']),
    ...mapState('newProductDetail/common', [
      'descriptionStatus',
      'currentLocalUnit',
      'detail',
      'userManualStatus'
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
    ...mapGetters('newProductDetail/SizeGuide', ['localsize']),
    ...mapGetters('newProductDetail/common', [
      'parentCats',
      // 'moduleRenderConfig',
      // 'showStoreAndBrand',
      // 'showAuthenticbrand',
      // 'isBrandHasTargetTag',
      'detailFPosContent',
      // 'isStoreBusinessBrand',
      // 'brandMapInfo',
      'language',
      'detail',
      'sellingPoints',
      'showSizeGuide',
      'trendsInfo'
    ]),
    ...mapGetters('newProductDetail/MiddleAttr', [
      'pageComponents',
      'sizeInfoDes',
      // 'showIngredientEnter',
      // 'showModelIntro',
      'newDetailsArr',
      'orginAttrArray',
      'detailsArr',
      'multiDescription',
      'descriptionList',
      'drawerContent',
      'showEvoluEntry',
      'cccDescAttrList'
    ]),
    // BFF新vuex
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/Description', [
      'description_status',
      'description_ready',
      // 'detail_o_pos_status' // 可能不需要了
    ]),
    ...mapState('productDetail/UserManual', [
      'user_manual_ready',
      'user_manual_status'
    ]),
    ...mapState('productDetail/EuRespPerson', [
      'eu_resp_persopn_detail',
    ]),
    ...mapGetters('newProductDetail/SizeBox', [
      'sizeConfig',
    ]),
    ...mapGetters('productDetail/common', [
      'language_v2',
      'goods_id',
      'current_local_country',
    ]),
    ...mapGetters('productDetail/MiddleAttr', [
      'ingredient_text',
      'models_info',
      'show_size_guide_enter',
      'toy_warning_info',
    ]),
    ...mapGetters('productDetail/EuRespPerson', [
      'eu_resp_person',
    ]),
    ...mapGetters('productDetail/Description', [
      'description_name',
      'trends_info',
      'out_side_points',
      'sell_points',
      'description_content',
      'evolu_info',
      'show_evolu',
      'detail_f_pos_box',
      'detail_o_pos_box'
    ]),
    ...mapGetters('productDetail/UserManual', [
      'show_user_manual',
      'user_manual_enter_box',
      'user_manual_drawer_box'
    ]),
    ...mapGetters('productDetail/DetailSignBoard', ['show_store_and_brand']),
    tabCountry() {
      let tabCountry =  this.currentLocalCountry
      if(this.sizeConfig.showOnlyOneLocalSize && this.currentLocalCountry){
        tabCountry = this.currentLocalCountry ===  this.sizeConfig?.sizeRule?.country_code ? this.currentLocalCountry : ''
      }
      return tabCountry
    },
    showEvolushein() {
      return this.fsAbt?.evolushein?.p?.evolushein === 'show'
    },
    showUserManual() {
      return (
        this.fsAbt?.usermanual?.param?.usermanual === 'show' &&
        this.detail.instruction_url
      )
    },
    // sizeGuidPlanA() {
    //   return (
    //     this.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
    //   )
    // },
    sizeGuideAlone() {
      return (
        this.fsAbt?.sizeguideyouhua520?.param?.sizeguide_alone === 'on'
      )
    },
    unitType() {
      const originUnit = this.sizeInfoDes?.sizeUnit != '1' ? 'cm' : 'inch'
      return this.currentLocalUnit || originUnit
    }
  },
  watch: {
    descriptionStatus(newVal) {
      // TODO 旧坑位组件的
      if (newVal) {
        // 卖点曝光埋点
        if (this.sellingPoints?.length) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-35',
            extraData: {
              sellingPoints: this.sellingPoints
            }
          })
        }
      }
    },
    description_status(newVal) {
      // 新坑位组件的
      if (newVal && this.sell_points?.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-2-35',
          extraData: {
            sellingPoints: this.sell_points
          }
        })
      }
    },
    userManualStatus(newVal) { // TODO 旧的说明书点击
      if (newVal) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-219'
        })
      }
    }
  },
  methods: {
    ...mapActions('newProductDetail', [
      'openDescriptionPop',
      'closeDescriptionPop',
      'openUserManualPop',
      'openSizeGuidePop',
      'closeUserManualPop'
    ]),

    // NEW BFF
    ...mapActions('productDetail/Description', [
      'open_description_pop',
      'close_description_pop'
    ]),
    ...mapActions('productDetail/UserManual', [
      'open_user_manual_pop',
      'close_user_manual_pop'
    ]),
    ...mapActions('productDetail/EuRespPerson', [
      'get_eu_resp_persopn',
    ]),
    ...mapMutations('productDetail/Description', [
      'update_detail_f_pos_status',
      'update_detail_o_pos_status'
    ]),
    clickManualHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-219'
      })
    }
  },
  render() {
    const { materialConf, descPosConf } =
      this.pageComponents || {}
    const { fPosData, oPosData = [] } = descPosConf || {}
    const showMaterial =
      materialConf?.isShow &&
      fPosData?.isShow &&
      materialConf?.materialList?.length

    const showDetailPos =
      fPosData?.isShow &&
      (this.detailFPosContent?.textAttr?.length ||
        this.detailFPosContent?.imgAttr?.length ||
        oPosData?.length) // 过滤cv属性存在没有值的情况
    const showModule =
      fPosData?.showModule == 1 && (showMaterial || showDetailPos)
    if (
      typeof window !== 'undefined' &&
      window.SaPageInfo &&
      window.SaPageInfo.page_param
    ) {
      window.SaPageInfo.page_param.is_include_cv = this.orginAttrArray.hasCVAttr
        ? 1
        : 0 // 商详页埋点设置page_name
    }

    // sizeGuideAlone为true，当没有模特信息时，隐藏Describtion下方的Size Guide入口
    const showSizeGuideEnter = this.show_size_guide_enter && !this.sizeGuideAlone
    // sizeGuide楼层入口
    const renderSizeGuideEnter = () => {
      return showSizeGuideEnter ? <NewSizeGuideEnter
        classStr="product-middle__has-border"
        goodsId={this.goods_id}
        title={this.language_v2?.SHEIN_KEY_PWA_15005}
        openSizeGuidePop={this.openSizeGuidePop}
      /> : null
    }

    // 美妆成分入口
    const renderIngredientEnter = () => {
      return this.ingredient_text ? (
        <NewIngredientsEnter
          language={this.language}
          ingredientText={this.ingredient_text}
        />
      ) : null
    }

    // 商详描述坑位信息降级
    const descriptionEnterContent = () => {
      return (
        <DescriptionEnter
          openDescriptionPopFn={this.open_description_pop}
          descriptionName={this.description_name}
          pointsData={this.out_side_points}
          box-style={{
            'border-top':
              showModule || this.show_store_and_brand || this.show_user_manual
                ? '1px solid #e5e5e5'
                : 'none'
          }}
          trendsInfo={this.trends_info}
          goodsId={this.detail.goods_id}
        />
      )
    }

    // 玩具警告提示
    const renderToyWarningTips = () => {
      if (!this.toy_warning_info?.showToyWarning) return null
      return (
        <ToyWarningTips 
          toyWarningInfo={this.toy_warning_info}
          style={{
            paddingBottom: this.eu_resp_person?.showGPSRTips ? '0' : '.32rem'
          }}
        />
      )
    }

    // 欧盟责任人提示
    const renderEuRespPerson = () => {
      if (!this.eu_resp_person?.showGPSRTips) return null
      return (
        <EuRespPerson
          euRespPerson={this.eu_resp_person}
          euRespPersonDetail={this.eu_resp_persopn_detail}
          language={this.language}
          getEuRespPersonFn={this.get_eu_resp_persopn}
        />
      )
    }

    const renderModelIntro = () => {
      if (!this.models_info?.attr) return null
      // if (!this.showModelIntro) return null
      return (
        <NewModelIntro
          currentLocalCountry={this.current_local_country}
          modelContent={this.models_info}
          language={this.language}
          isClick={this.sizeGuideAlone && this.show_size_guide_enter}
          class={this.sizeGuideAlone && this.show_size_guide_enter ? 'model-intro__alone' : ''}
          style={{
            paddingTop: this.show_size_guide_enter ? '0' : '.32rem'
          }}
          v-on:clickModelIntro={this.openSizeGuidePop}
        />
      )
    }

    const descriptionDrawerContent = () => {
      if (!this.description_status) return null
      return (
        <DescriptionDrawer
          show-drawer={this.description_status}
          closeDescriptionPop={this.close_description_pop}
          content={this.description_content}
          title={this.description_name}
          sellingPoints={this.sell_points}
          $router={this.$router}
          trendsInfo={this.trends_info}
          showEvolu={this.show_evolu}
          evoluInfo={this.evolu_info}
          goodsId={this.detail.goods_id}
          catId={this.detail.cat_id}
        />
      )
    }

    // 详情坑位模板
    const detailTemplatePositionContent = () => {
      return (
        <div>
          {this.detail_f_pos_box ? (
            <DetailFPos
              content={this.detail_f_pos_box}
              openDetail={this.update_detail_f_pos_status}
              openDescription={this.open_description_pop}
            />
          ) : null}
          {this.detail_o_pos_box ? (
            <DetailOPos
              content={this.detail_o_pos_box}
            />
          ) : null}
        </div>
      )
    }

    // 用户信息模板坑位
    const userManualEnterContent = (boxStyle = {}) => {
      if (!this.show_user_manual) return null
      return (
        <UserManualEnter
          title={this.user_manual_enter_box.title}
          content={this.user_manual_enter_box.content}
          openUserManualPopFn={() => this.open_user_manual_pop(this.clickManualHandle)}
          box-style={boxStyle}
        />
      )
    }

    // 用户信息弹窗
    const userManualDrawerContent = () => {

      // user_manual_ready

      if (!this.user_manual_ready) return null
      return (
        <UserManualDrawer
          show-drawer={this.user_manual_status}
          closeUserManualPop={this.close_user_manual_pop}
          content={this.user_manual_drawer_box}
        />
      )
    }

    // 品牌独立入口
    const storeBusinessBrandEntraceContent = () => {
      if (!this.show_store_and_brand) return null
      return <StoreBusinessBrandEntrace config={{ v1: true }}/>
    }

    return (
      <div>
        <div>
          {showModule ? (
            <div class="goods-attr__title">Product Details</div>
          ) : null}
          {/* 商品说明书信息模板坑位 */}
          {showModule
            ? userManualEnterContent({
              'border-bottom':
                  showModule || this.show_store_and_brand
                    ? '1px solid #e5e5e5'
                    : 'none'
            })
            : null}
          {/* 详情坑位模板 */}
          {detailTemplatePositionContent()}
        </div>
        {/* 商品说明书信息模板坑位 */}
        {!showModule ? userManualEnterContent({}) : null}
        {storeBusinessBrandEntraceContent()}
        {descriptionEnterContent()}
        {/* Drawer */}
        {descriptionDrawerContent()}
        {renderToyWarningTips()}
        {renderEuRespPerson()}
        {userManualDrawerContent()}
        {renderIngredientEnter()}
        {renderSizeGuideEnter()}
        {renderModelIntro()}
      </div>
    )
  }
}
</script>

<style lang="less">
.goods-attr {
  &__title {
    font-weight: bold;
    .font-dpr(28px);
    line-height: 1.14;
    padding: 0.2667rem 0 0;
    /* rw:begin */
    font-family: 'Adieu';
  }
}

.product-middle {
  &__has-border {
    border-top: 1px solid #e5e5e5;
  }
}
</style>
