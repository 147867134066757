<template>
  <div class="goods-attr__wrap">
    <div
      v-if="content.desc"
      ref="attrDesBox"
      class="goods-attr__des"
      :class="{more: desMore || desMore === null}"
    >
      <span
        ref="attrDes"
        v-html="content.desc"
      >
      </span>
      <span
        v-show="desMore"
        class="goods-attr__des-more"
        @click="changeDesMore"
      >...<span>{{ textContent.moreText }}</span></span>
      <span
        v-show="!desMore && desMore !== null"
        class="goods-attr__des-less"
        @click="changeDesMore"
      >{{ textContent.lessText }}</span>
    </div>
    
    <!-- 带图属性项 -->
    <PrdImageAttrGroup
      v-for="(group, index) in content.img_content"
      :key="index"
      :group="group"
    />

    <slot name="middle"></slot>

    <!-- 美妆前置 -->
    <div
      v-if="content.makeup_color_content && idx == 'description'"
      class="goods-attr__color"
      aria-hidden="true"
    >
      <div class="goods-attr__color-key">
        {{ content.makeup_color_content.attr_name }}:
      </div>
      <div class="goods-attr__color-val">
        <div
          class="goods-attr__color-obj"
          :style="{'max-height': descShowMore || !descShowMoreInit ? colorLineHeight + 'px': 'none',}"
        >
          <div
            class="goods-attr__color-word"
            v-html="content.makeup_color_content.attr_value"
          ></div>
        </div>
        <span
          v-if="descShowMore"
          class="goods-attr__color-more"
          @click="descShowMore = false"
        >
          {{ textContent.showMoreText }}
          <span class="iconfont icon-down"></span>
        </span>
      </div>
    </div>

    <div class="goods-attr__list-text">
      <template
        v-for="(item, index) in content.attr_content"
      >
        <div
          v-if="item.attr_value"
          :key="item.attr_name + index"
          class="goods-attr__list-text-li"
        >
          <div
            class="goods-attr__list-text-name"
          >
            {{ item.attr_name }}:
          </div>
          <div class="goods-attr__list-text-val">
            <span
              v-if="item.attr_id == '27'"
              v-html="item.attr_value"
            ></span>
            <span v-else>
              {{ !+item.isHeelHeight ? item.attr_value : item.heelHeightRangeDesc }}
            </span>
            <span
              v-if="item.attr_name === 'SKU'"
              v-tap="{id: '1-6-4-91'}"
              class="goods-attr__list-text-copy copy-code-btn"
              :data-clipboard-text="item.attr_value"
              dir="ltr"
              @click="copyCode"
            >
              <Icon
                name="sui_icon_copy_12px_2"
                size="12px"
                color="#2D68A8"
              />
              {{ textContent.copyText }}
            </span>
          </div>
        </div>
      </template>
    </div>

    <slot name="bottom"></slot>
  </div>
</template>
<script>
import PrdImageAttrGroup from './PrdImageAttrGroup'
import Clipboard from 'clipboard'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'DetailFPosContent',
  components: {
    Icon,
    PrdImageAttrGroup,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    },
    idx: {
      type: [Number, String],
      default: -1
    }
  },
  data () {
    return {
      desMore: null,
      descShowMore: false,
      descShowMoreInit: false,
      colorLineHeight: 0,
    }
  },
  computed: {
    textContent () {
      return this.content.common_text_content || {}
    }
  },
  watch: {
    idx: {
      immediate: true,
      handler (val) {
        if (val == 'description') {
          this.calcLineHeight()
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.content.desc) {
        this.desMore = this.$refs.attrDes.offsetHeight > this.$refs.attrDesBox.offsetHeight ? true : null
      }
    })
  },
  methods: {
    changeDesMore () {
      this.desMore = !this.desMore
    },
    calcLineHeight () {
      // description color属性描述超出两行显示view more，计算行高是否超出两行
      this.$nextTick(() => {
        const el = document.querySelector('.goods-attr__color-word')
        if (!this.colorLineHeight) {
          if (el) {
            const fontSize = parseFloat(document.querySelector('html').getAttribute('data-dpr') * window.getComputedStyle(el).getPropertyValue('font-size').replace('px', ''))
            if (fontSize) {
              this.colorLineHeight = fontSize * 3 // 2行 * 行高1.5
            }
          }
        }
        if (!this.descShowMoreInit) {
          if (el) {
            this.descShowMoreInit = true
            this.descShowMore = el.offsetHeight > this.colorLineHeight
          }
        }
      })
    },
    copyCode(){
      const { textContent } = this
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        this.$toast(textContent.copySuccessText || 'Copied Successfully!', 3000)
        clipboard.destroy()
      })
    },
  }
}
</script>
<style lang="less">
.goods-attr {
  &__color {
    display: flex;
    margin: 0 0 .533333rem;
  }
  &__color-key {
    color: #767676;
    .padding-r(.08rem);
  }
  &__color-val {
    color: #767676;
  }
  &__color-obj {
    word-break: break-word;
    overflow: hidden;
  }
  &__color-more {
    color: #1860A7;
    span {
        color: #1860A7;
    }
  }
  &__des {
    position: relative;
    margin-bottom: .32rem;
    color: #767676;
    .font-dpr(24px);
    line-height: 1.33;
    overflow-y: scroll;
    &.more {
      .line-camp(3);
      overflow: hidden;
    }
  }
  &__des-more {
    position: absolute;
    background: white;
    bottom: 0;
    right: 0;
    span {
      color: #2D68A8;
    }
  }
  &__des-less {
    color: #2D68A8;
  }
  &__list-text {
    display: table;
    width: 100%;
  }
  &__list-text-li {
    display: table-row;
    color: #767676;
    .font-dpr(24px);
    line-height: 1.2;
  }
  &__list-text-name {
    display: table-cell;
    width: 2.72rem;
    padding: 0 .32rem .213333rem 0;
    vertical-align: middle;
  }
  &__list-text-val {
    display: table-cell;
    padding: 0 0 .2133333rem;
    vertical-align: middle;
    position: relative;
  }
  &__list-text-copy {
    color: #2D68A8;
    margin-left: 0.26667rem;
    font-size: 11px;
    position: absolute;
    top: 0;
  }
}

</style>
