<!--曝光埋点  品牌系列：j-sa-brand-view-products 店铺：j-sa-brand-store-view-products-->
<template>
  <div>
    <div
      v-ada="{ level: 1, pos: [0] }"
      class="mgds-store-short"
      :class="[`j-sa-brand-store-view-products`]"
      :aria-label="sign_data.name"
      :data-name="sign_data.name"
      :data-brand_code="sign_data.id"
      :data-goods_id="sign_data.goods_id"
      :data-brand_type="sign_data.type"
      :data-designer_id="sign_data.designerId"
      :data-sc_id="sign_data.scId"
      :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
      :data-brand_info="extAnalysisInfo.brandInfo"
      :data-brand_label="extAnalysisInfo.brandLabel"
      :data-button_label="extAnalysisInfo.buttonLabel"
      :data-store_popularity="extAnalysisInfo.storePopularity"
      :data-flash_sale_stype="flashEntryMap[flashEntryType] || 0"
      :data-store_quality_label="store_quality_label"
      role="text"
      @click="viewProducts()"
    >
      <div
        v-ada="{ level: 1, pos: [0] }"
        :class="[
          'mgds-store__detail',
          'center'
        ]"
        :aria-label="sign_data.name"
        role="text"
        aria-hidden="true"
      >
        <div 
          v-if="sign_data.logo"
          class="mgds-store-short__detail-logo"
        >
          <div class="img-box">
            <img :src="sign_data.logo" />
          </div>
          <div 
            v-if="formatShopScore"
            class="rate-box"
          >
            <Icon  
              class="rate-box-star"
              name="sui_icon_star_5_15px_2" 
              size="14px"
              color="#FACF19"
            />
            {{ formatShopScore }}
          </div>
        </div>

        <!-- 中部店铺信息块 -->
        <div class="mgds-store__detail-info">
          <!-- 标题 -->
          <div class="detail-flex-box">
            <div
              :class="{
                'mgds-store__detail-info-title': true,
                'mgds-store__detail-info-store-sign': isSingleRowDisplay,
              }"
            >
              <div class="title-block">
                <span 
                  class="text"
                  :class="{
                    'text_first_label': showStoreAtmosphere
                  }"
                >{{ sign_data.name }}</span>
                <!-- 店铺人气氛围&店铺评分信息滚动显示 -->
                <StoreAtmosphereSwiper
                  v-if="showStoreAtmosphere"
                  class="shortstore__atmosphere"
                  :hasFollowInfo="true"
                  position="showscroll"
                >
                  <template #followInfo>
                    <div
                      class="store-label"
                    >
                      <div
                        v-if="is_in_fashion_store"
                        v-expose="{id: '1-6-4-75'}"
                        class="store-label__fashion"
                      >
                        <img
                          v-if="in_fashion_store_img"
                          class="store-label__bff-icon"
                          :src="in_fashion_store_img"
                        />
                        <span
                          v-else
                          class="store-label__fashion-default"
                        >
                          {{ sign_board_text.trendsLang || 'Trends' }}
                          <img
                            class="store-label__fashion-default-icon"
                            :src="`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`"
                          />
                        </span>
                      </div>
                      <div class="store-label__brand">
                        <img
                          :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                          class="branchIcon"
                        />
                        <span class="barnd-text">{{ sign_board_text.barndLang }}</span>
                      </div>
                      <div
                        v-if="is_quality_store"
                        class="store-label__choices"
                      >
                        <img :src="in_quality_store_img" />
                      </div>
                    </div>
                  </template>
                </StoreAtmosphereSwiper>
                <div
                  v-if="!showStoreAtmosphere"
                  class="store-label"
                >
                  <div
                    v-if="is_in_fashion_store"
                    v-expose="{id: '1-6-4-75'}"
                    class="store-label__fashion"
                  >
                    <img
                      v-if="in_fashion_store_img"
                      class="store-label__bff-icon"
                      :src="in_fashion_store_img"
                    />
                    <span
                      v-else
                      class="store-label__fashion-default"
                    >
                      {{ sign_board_text.trendsLang || 'Trends' }}
                      <img
                        class="store-label__fashion-default-icon"
                        :src="`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`"
                      />
                    </span>
                  </div>
                  <div class="store-label__brand">
                    <img
                      :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                      class="branchIcon"
                    />
                    <span class="barnd-text">{{ sign_board_text.barndLang }}</span>
                  </div>
                  <div
                    v-if="is_quality_store"
                    class="store-label__choices"
                  >
                    <img :src="in_quality_store_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="storeFollowRelativeShow"
            class="short-btn-container" 
          >
            <!-- 底部关注按钮块 -->
            <StoreFollowBtnBox
              scene="detail"
              class-name="follow-btn-box-new"
              :store-id="storeId"
              :follow-state="storeFollowState"
              :auto-login="true"
              :text-combo="sign_board_text"
              :showLoginModal="show_login_modal"
              @state-changed="handleFollowStateChange"
              @state-changing="handleFollowStateChanging"
              @state-change-fail="handleFollowStateChangeFail"
            >
              <template slot="button">
                <div
                  v-expose="{ id: '1-6-4-63', data: followBtnAnalysisData }"
                  :class="{
                    'follow-btn': true,
                    'show-symbol': followBtnShowSymbol,
                    'followed': storeFollowState
                  }"
                  @click="followBtnClick"
                >
                  <FollowStoreIcon />
                  <b class="whitespace"></b>
                  <span class="follow-btn-text">{{ storeFollowBtnText || 'Follow' }}</span>
                  <b 
                    v-if="!storeFollowState && store_follow_info.followCount" 
                    class="whitespace"
                  ></b>
                  <span 
                    v-if="!storeFollowState && store_follow_info.followCount"
                    class="follow-count"
                  >({{ store_follow_info.followCount }})</span>
                </div>
              </template>
            </StoreFollowBtnBox>
            <!-- 底部跳转按钮 -->
            <div
              class="follow-btn-box-new follow-btn-allitems"
              aria-hidden="true"
              @click.stop="viewProducts(flashEntryType === 'showflashsale' || flashEntryType === 'showflashbutton' ? 'promotion' : 'items')"
            >
              <div
                v-if="flashEntryType === 'showflashsale'"
                class="store_flash-sale"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z" fill="#161616"/>
                </svg>
                <span>{{ sign_board_text.flashSaleLang || 'Flash Sale' }}</span>
              </div>

              <div 
                v-if="flashEntryType === 'showflashbutton'"
                class="follow-btn with-flash-icon"
              >
                <img
                  class="flash-gif-icon"
                  :src="`${PUBLIC_CDN}/pwa_dist/images/flash-sale/flash-gif-097c8939e4.gif`"
                />
                <div
                  ref="flashScrollBox"
                  class="flash-scroll-wrap"
                  :class="{
                    'has-animation': hasAnimation
                  }"
                >
                  <span 
                    ref="flashScrollContent"
                    class="flash-scroll-item"
                    :class="{
                      'has-animation': hasAnimation
                    }"
                    :style="{'animation-duration': millisecond }"
                  >
                    {{ sign_board_text.storeFlashLang || 'Store Flash Sale' }}
                  </span>
                </div>
              </div>
              <div 
                v-else 
                class="follow-btn"
              >
                <p class="follow-btn-text">{{ sign_board_text.allItemLang || 'All Items' }}<b
                  v-if="store_follow_info.salesVolume"
                  class="whitespace"
                >
                </b><span 
                  v-if="store_follow_info.salesVolume"
                  class="follow-count"
                >({{ store_follow_info.salesVolume }})</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店铺卖点信息 -->
    <StoreQualityTags
      v-if="is_show_store_quality_list"
      class="shortstore-tags-outer"
      :store-quality-list="store_quality_list"
      @goToStore="viewProducts()"
    />
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import moreMixin from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/mixins/more.js'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'
import StoreFollowBtnBox from './StoreFollowBtnBox'
import FollowStoreIcon from './FollowStoreIcon'
import StoreAtmosphereSwiper from './StoreAtmosphereSwiper'
import StoreQualityTags from './StoreQualityTags'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { markPoint } from 'public/src/services/mark/index.js'

const { PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'StoreEnter',
  components: {
    StoreFollowBtnBox,
    FollowStoreIcon,
    StoreAtmosphereSwiper,
    StoreQualityTags,
  },
  mixins: [moreMixin],
  props: {
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    // 跳转商品列表时带上adp参数
    setAdpParams: {
      type: Boolean,
    },
    flashEntryType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      PUBLIC_CDN,
    }
  },
  computed: {
    // NEW BFF
    ...mapGetters('productDetail/common', ['fs_abt']),
    ...mapGetters('productDetail/DetailSignBoard', [
      'sign_data', // 用来替换data，detail
      'sign_board_text',
      'store_recommend_info',
      'label_list',
      'is_in_fashion_store',
      'in_fashion_store_img',
      'store_code',
      'shop_rank',
      'storehotnews_type',
      'store_atmosphere_info',
      'store_follow_info',
      'is_quality_store',
      'in_quality_store_img',
      'store_quality_list',
      'store_quality_label',
      'is_show_store_quality_list',
    ]),
    recommendAdp() {
      return this.store_recommend_info?.recommendAdp || ''
    },
    // 展示店铺新样式，新交互
    showStoreSign(){
      return true
    },
    inlineType(){
      return this.showStoreSign ? 'inline-store' : 'inline'
    },
    formatShopScore() {
      const storeRateNum = Number(this.shop_rank) || 0
      if (!storeRateNum) {
        return ''
      }
      return storeRateNum.toFixed(2)
    },
    // 店铺id
    storeId() {
      const { store_code } = this
      return store_code || ''
    },
    // 店铺关注信息是否展示
    storeFollowRelativeShow() {
      const { sign_data, store_follow_info } = this
      if (!sign_data || !store_follow_info) {
        return false
      }
      return store_follow_info.show 
    },
    // 用户店铺关注状态
    storeFollowState() {
      const { storeFollowRelativeShow, store_follow_info } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后删除此逻辑
        return false
      }
      return store_follow_info?.userFollowState == 1
    },
    // 店铺关注按钮是否展示+符号
    followBtnShowSymbol() {
      const { store_follow_info } = this
      const { userFollowState, followStateChanging } = store_follow_info || {}
      return !followStateChanging && userFollowState != 1
    },
    // 店铺关注按钮文案
    storeFollowBtnText() {
      const { store_follow_info, sign_board_text } = this
      const { followStateChanging, userFollowState } = store_follow_info || {}
      if (followStateChanging) return '...'
      if (userFollowState == -1) {
        return sign_board_text?.followLang
      }
      return userFollowState == 1
        ? sign_board_text?.followIngLang
        : sign_board_text?.followLang
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { store_follow_info, shop_rank, store_atmosphere_info } = this
      const { salesVolume, followCount } = store_follow_info || {}
      const brandInfo = [
        `ratings_${shop_rank}`,
        (salesVolume && salesVolume !== '0')
          ? `sold_in_30d_${salesVolume}`
          : '-',
        (followCount && followCount !== '0')
          ? `followers_${followCount}`
          : '-'
      ]
      const buttonLabel = '-'
      // 店铺人气氛围
      let storePopularity = ''
      if(store_atmosphere_info.length){
        const popTypes = new Set()
        store_atmosphere_info.forEach(e => {
          if (e.popType) {
            popTypes.add(e.popType)
          }
          storePopularity = Array.from(popTypes).join('\'')
        })
      }else {
        storePopularity = '0'
      }
      const info = {
        abtAnaInfo: store_follow_info?.abtAnaInfo || '',
        brandInfo: brandInfo.join('`') || '',
        // 此入口不存在本地卖家和优质卖家标签
        brandLabel: '',
        buttonLabel,
        storePopularity,
      }
      this.$emit('handleStoreBrandAnalysis', info)
      return info
    },
    // 店铺关注按钮埋点信息
    followBtnAnalysisData() {
      const { store_follow_info, storeId } = this
      const { userFollowState } = store_follow_info || {}
      return {
        action_tp: userFollowState == 1 ? 'followed' : 'unfollowed',
        brand_code: storeId,
        brand_type: 'store',
      }
    },
    // 单行展示
    isSingleRowDisplay(){
      //  命中新样式，并且没有额外的标签展示时，品牌标签与标题一行展示
      return this.showStoreSign && !this.is_in_fashion_store && !this.is_quality_store
    },
    // 店铺人气氛围显示
    showStoreAtmosphere() {
      return ['showscroll'].includes(this.storehotnews_type)
    },
  },
  methods: {
    ...mapMutations('productDetail/DetailSignBoard', ['update_store_follow_info']),
    ...mapActions('productDetail/common', ['show_login_modal', 'detail_to_store_data']),
    viewProducts(tabId) {
      const { type, id, link, name, designerId } = this.sign_data
      const { shop_rank, analysis, showNewArrival } = this
      const { sign_data } = this

      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : `thirdPartyStoreHome_${id}`
        }`
      ].join('`')
      // 新店招交互
      if(this.showStoreSign) {
        markPoint('toNextPageClick', 'public')
        const params = {
          src_module: analysis?.src_module,
          src_identifier: src_identifier,
          src_tab_page_id: getSaPageInfo.tab_page_id || '',
          main_cate_id: sign_data.cat_id,
          main_goods_id: sign_data.goods_id,
          rule_poskey: 'DetailShopItemList',
        }

        // 新店招交互点击allitems上报
        this.clickStoreReports({ tabId, type, id, src_identifier })
        if(!tabId){  // 不是allitems 列表的入口点击
          // 往店铺塞数据
          this.detail_to_store_data()
        }
        if(showNewArrival && tabId) {
          params.sort = '9'
          this.setNewArrivalStorage(id)
        }

        const { langPath } = gbCommonInfo
       
        const queryObj = {
          adp: this.sign_data.goods_id,
          store_code: this.store_code,
          ...params,
        }
        if (tabId) {
          queryObj.tabId = tabId
          queryObj.isStoreTabSticky = '1'
        }
        const url = `${langPath}/store/home?${stringifyQueryString({
          queryObj
        })}`
        storePageDataManager.prerenderRequest({
          fullPath: url,
          query: queryObj,
        })
        this.$router.push(url)
        return 
      }

      // 点击跳转店铺上报
      this.clickStoreReports({ tabId, type, id, src_identifier })
      
      let sort = ''
      if(showNewArrival && tabId) {
        sort = 9
        this.setNewArrivalStorage(id)
      }

      this.boardMoreHandle({
        isStoreTabSticky: tabId ? '1' : '',
        sort,
        tabId,
        type,
        id,
        link,
        shop_rank,
        goods_id: sign_data.goods_id,
        cat_id: sign_data.cat_id,
        adp: !this.setAdpParams ? '' : this.recommendAdp,
        analysis: { src_identifier, src_module: analysis?.src_module }
      })
    },
    setNewArrivalStorage(id) {
      const storageKey = `storeAllitems_hide_${id}`
      let val = getLocalStorage(storageKey)
      if(!val){
        // 上新标签缓存3天
        setLocalStorage({ key: storageKey, value: true, expire: 1000 * 60 * 60 * 24 * 3 })
      }
    },
    clickStoreReports({ tabId, type, id, src_identifier }) {
      const { brandInfo, brandLabel, buttonLabel } = this.extAnalysisInfo
      const flash_sale_stype = this.flashEntryType == 'showflashsale' ? 2 : (this.flashEntryType == 'showflashbutton' ? 3 : 0)
      daEventCenter.triggerNotice({
        daId: tabId ? '1-6-1-194' : '1-6-4-17',
        extraData: {
          brand_type: type,
          brand_code: id,
          // abtest: abtAnaInfo,
          brand_info: brandInfo,
          brand_label: brandLabel,
          button_label: tabId ? buttonLabel : '',
          page_nm: 'page_goods_detail',
          src_identifier,
          flash_sale_stype: tabId ? flash_sale_stype : undefined,
          ...(tabId ? {} : { store_qualitylabel: this.store_quality_label })
        }
      })
    },
    // 关注按钮点击
    // * 此处只处理埋点事件，关注状态的切换冒泡至 StoreFollowBtnBox 组件进行处理
    followBtnClick() {
      const { followBtnAnalysisData, store_follow_info } = this
      const { userFollowState } = store_follow_info || {}
      const notLogin = (typeof SHEIN_COMMON !== 'undefined')
        && (SHEIN_COMMON?.isLogin?.() === false)
      daEventCenter.triggerNotice({
        daId: '1-6-4-64',
        extraData: {
          ...followBtnAnalysisData,
          action_tp: notLogin
            ? 'follow'
            : !userFollowState ? 'follow' : 'cancel',
        },
      })
    },
    // 更改关注状态成功
    handleFollowStateChange({ state, count }) {
      const {
        store_follow_info,
        followBtnAnalysisData,
      } = this
      const userFollowState = state ? 1 : 0
      this.update_store_follow_info({
        ...store_follow_info,
        userFollowState,
        followCount: count
      })
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 1,
          reason_tp: '-',
          action_tp: userFollowState ? 'follow' : 'cancel',
        },
      })
    },
    // 变更关注状态失败
    handleFollowStateChangeFail(err) {
      const { followBtnAnalysisData, store_follow_info } = this
      const { userFollowState } = store_follow_info || {}
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 0,
          reason_tp: err?.code || '-1',
          action_tp: !userFollowState ? 'follow' : 'cancel',
        },
      })
    },
    // 关注状态变更中
    handleFollowStateChanging(state) {
      const { store_follow_info } = this
      this.update_store_follow_info({
        ...store_follow_info,
        followStateChanging: state,
      })
    }
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity, selector-class-pattern */
.mgds-store-short {
  padding: 0.267rem 0.32rem 0.13rem;
  .flexbox();
  .space-between();
  .align-center();
  background: transparent;

  &__detail {
    .flexbox();
    width: 100%;
    overflow: hidden;
    &.center {
      .align-center();
    }
  }
  &__detail-logo {
    .margin-r(16/75rem);
    width: 1.3867rem;
    .border-dpr(border, 1px, #e5e5e5);
    .flexbox();
    .align-center();
    flex-shrink: 0;
    flex-direction: column;
    background-color: #fff;
    .img-box {
      width: 1.3333rem;
      height: 1.3333rem;
      > img {
        width: 100%;
      }
    }
    .rate-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #222;
      .font-dpr(22px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      height: 0.48rem;
      .border-dpr(border-top, 1px, #e5e5e5);
      background: linear-gradient(0deg, #FFFCF3 0%, #FFFCF4 100%);
    }
    .rate-box-star {
      margin-right: 2px;
    }
  }
  &__detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    flex-grow: 1;

    .detail-flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__detail-info-title {
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    width: 0;
    &.inline {
      display: inline-flex;
    }
  }
  &__detail-info-store-sign{
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
  &__follow-info{
    display: flex;
    gap: 4px;
    margin-top: .106667rem;
  }
  .store-label {
    display: inline-flex;
    align-items: center;
    &__fashion {
      margin-right: 8/75rem;
    }
    &__fashion, &__choices{
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
      .store-label__bff-icon {
        height: 0.37333rem !important; // 14px
        width: auto !important;
      }
    }
    &__fashion-default{
      height: 14px;
      line-height: 14px;
      position: relative;
      display: inline-block;
      padding: 0 5px 0 2px;
      font-size: 11px;
      font-style: italic;
      font-weight: 858;
      color: #fff;
      z-index: 1;
    }
    &__fashion-default-icon{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &__brand{
      display: inline-flex;
      align-items: center;
      .border-dpr(border, 0.5px, #BCC8D3);
      .font-dpr(20px);
      height: 32/75rem;
      align-items: center;
      box-sizing: border-box;
      background: linear-gradient(128.64deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
      border-radius: 4/75rem;
      color: #2D3540;
      padding: 3/75rem 4/75rem;
      align-items: center;
      font-weight: 500;
      margin-right: 8/75rem;
    }

    &.inline {
      .margin-l(0.16rem);
      margin-top: 0;
    }

    .barnd-text {
      display: inline-block;
      height: 24/75rem;
      line-height: 24/75rem;
    }
    .branchIcon {
      width: 24/75rem;
      margin-right: 4/75rem;
    }
  }

  &__detail-info-footer {
    display: inline-flex;
    margin-top: 0.106667rem; //0.1733rem;
    .item {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #666;
      .margin-r(0.16rem);
      em {
        color: #000
      }
    }
    .split-line {
      .margin-r(0.16rem);
      font-size: 12px;
      line-height: 14px;
      color: #e5e5e5;
    }
  }
  &__view-products {
    padding: 0.3rem 0;
    white-space: nowrap;
    color: #999;
    > i {
      color: #999;
      .margin-l(0.12rem);
      .font-dpr(24px);
    }
  }

  .title-block {
    display: flex;
    flex-direction: row;
    .font-dpr(28px);
    color: #000;
    font-weight: 700;
    line-height: 17px;
    overflow: hidden;
    gap: 0.1067rem;
    width: 100%;
    .text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: fit-content;
    }
    .text_first_label {
      flex: 0 0 47%;
    }
    .store-label {
      margin-left: 0.11rem;
      flex-shrink: 0;
    }
  }
}

.short-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.1067rem;
  margin-left: -0.05rem;

  /* stylelint-disable-next-line */
  .follow-btn-box-new {
    margin: 0.11rem;

    .follow-btn {
      position: relative;
      display: flex;
      width: 3.52rem;
      height: 0.64rem;
      padding: 0 0.11rem;
      justify-content: center;
      align-items: center;
      font-size: 0.32rem;
      line-height: 0.32rem;
      .font-dpr(24px);
      color: @sui_color_gray_dark1;
      border: 1px solid #959595;
      border-radius: 0.32rem;
      /* stylelint-disable-next-line */
      &.show-symbol {
        /* stylelint-disable-next-line */
        svg, .whitespace {
          display: inline-block;
        }
      }
      /* stylelint-disable-next-line */
      &.followed {
        /* stylelint-disable-next-line */
        color: @sui_color_gray_light1;
        border-color: @sui_color_gray_weak1;
      }
      &.with-flash-icon{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .flash-scroll-wrap{
          overflow: hidden;
          white-space: nowrap;
          vertical-align: top;
          &.has-animation{
            position: relative;
            flex: 1;
            height: 0.3733rem
          }
        }
        .flash-scroll-item {
          display: inline-block;
          &.has-animation{
            position: absolute;
            left: 0;
            animation: flashScroll linear 4s alternate infinite;
          }
        }
        @keyframes flashScroll {
          0% {
            left: 0;
            transform: translateX(0);
          }
          35% {
              left: 0;
              transform: translateX(0);
          }
        
          65% {
              left: 100%;
              transform: translateX(-100%);
          }
          100% {
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }

      .flash-gif-icon{
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.1067rem;
        flex: 0 0 auto;
      }
      span {
        display: inline-block;
      }
      /* stylelint-disable-next-line */
      .whitespace {
        width: .106667rem;
        height: .106667rem;
      }
      .follow-count {
        color: #666;
      }
    }
    .follow-btn-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .whitespace {
        display: inline-block;
        width: 0.106667rem;
        height: 0.106667rem;
      }
      .follow-count {
        color: #666;
      }
    }
  }
  .follow-btn-allitems{
    position: relative;
    .store_flash-sale{
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0 0.1067rem 0 0.08rem;
      height: 0.347rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      font-size: 0.24rem;
      color: #000;
      z-index: 1;
      background-image: url('/pwa_dist/images/flash-sale/scan_flash-6a3950325b.gif');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .store_new-arrival{
      height: 0.37333rem;
      line-height: 0.37333rem;
      padding: 0 0.05333rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      border-radius: 0.08rem 0.08rem 0.08rem 0;
      border: 1px solid #FFF;
      background: #3CBD45;
      font-size: 0.26667rem;
      color: #fff;
      z-index: 1;
    }
  }
}
.shortstore-tags-outer {
  padding-top: 0.137rem;
  padding-left: 0.32rem;
}
.shortstore__atmosphere {
  flex: 1;
}
</style>
