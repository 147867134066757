/* eslint-disable require-atomic-updates */
import { getExposeBoxData } from './utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  getFormatCommonCartProductItem,
  setCommonCartProductSkuInfo,
  setCommonQuickAddSelectedAttrIndexData,
} from '../CommonCart/utils'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
/* eslint-disable @shein-aidc/abt/abt */
import { abtservice } from 'public/src/services/abt'
import { useProductCardUtils } from 'public/src/pages/goods_detail_v2/utils/recommend.js'

import { getQuickAddCartInstance, canUseNewQuickAdd, plugins } from 'public/src/pages/components/product/quick_add_v3/common'
import { SCENE_MAP } from 'public/src/pages/components/product/quick_add_v3/optionsConfig' 

/**
 * 套装价计算状态管理
 */
export const getCombobuyMixins = (options = {}) => {
  const { isPopup } = options

  return {
    data() {
      return {
        loadingPrice: false,
        products: [],
      }
    },
    computed: {
      // TODO Nolan Hu
      ...mapState('newProductDetail/common', ['detailPageOnloadStatus']),
      ...mapGetters('productDetail', ['product_detail_abt_info']),
      ...mapGetters('productDetail/common', [
        'language_v2',
        'product_items_language',
        'goods_id',
        'is_hit_compliance_mode',
        'sku_info',
        'is_paid_user',
      ]),
      ...mapState('productDetail/Recommend', ['combo_buy_products','combo_buy_price']),
      ...mapGetters('productDetail/Recommend', ['combo_buy_abt_info']),
      tspABTest() {
        // eslint-disable-next-line @shein-aidc/abt/abt
        const tspABTest = abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'buytogethernew': this.product_detail_abt_info?.buytogethernew || {} } })?.sa || ''
        return tspABTest
      },
      // 盒子属性
      cRenderBindData() {
        return getExposeBoxData({
          isPopup,
          tspABTest: this.tspABTest,
        })
      },
      cSelectItems() {
        return this.products.filter(d => d._cartInfo.selectStatus)
      },
      language() {
        return this.language_v2
      },
      productItemsLanguage() {
        return this.product_items_language
      },
      goodsId() {
        return +this.goods_id
      },
      cBundlePrice() {
        return this.combo_buy_price || {}
      }
    },
    methods: {
      ...mapActions('newProductDetail/common', [
        'setGoodsDetailScroll'
      ]),
      ...mapActions('newProductDetail', [
        'updateEstimatedCouponList',
      ]),
      ...mapActions('productDetail/Recommend', [
        'get_combo_buy_price',
        'get_together_better_deals_price',
        'product_bff_depend_data',
      ]),
      ...mapMutations('productDetail/Recommend', [
        'update_combo_buy_price',
      ]),
      async onSelectChange({ item, selected, target } = {}) {
        if (this.loadingPrice) return

        // 售罄状态不能选中
        if (item._cartInfo.isSoldOut) return

        if (target) {
          daEventCenter.triggerNotice({
            daId: '2-3-39',
            target,
            extraData: {
              click_type: selected ? 'tick' : 'tick_cancel'
            }
          })
        }

        item._cartInfo.selectStatus = selected
        // 如果取消勾选，需要移除警告信息
        if (!selected && item._cartInfo.warnStatus) {
          item._cartInfo.warnStatus = false
        }
        // 更新价格
        const updateOk = await this.updateComboBuyPrice()
        // 价格计算失败需要取消勾选
        if (!updateOk) {
          item._cartInfo.selectStatus = false
          item._cartInfo.warnStatus = false
        }
      },
      // 更新
      async updateSalesAttr({ skuCode, goods_id, mallCode }, options, next = null) {
        const { item, onAfterUpdateFn } = options
        const _isUptSkcOrSku = item.goods_id !== goods_id || skuCode !== item._cartInfo.skuCode

        let _item = null
        if (_isUptSkcOrSku) {
          const updateGoodsId = `${goods_id},${skuCode}-${mallCode}`
          const selectedGoodsIdSkus = this.cSelectItems?.map(d => d.sku_code ? `${d.goods_id},${d.mall_code},${d.sku_code}` : `${d.goods_id},${d.mall_code}` ).join('-')
          const { togetherBetterDealsProdList = []} = await this.get_together_better_deals_price({ updateGoodsId, selectedGoodsIdSkus })
          // BFF 商卡数据转换
          const { goodsTransformationProductsWithSetup } = useProductCardUtils(this.product_bff_depend_data)
          const productsList = goodsTransformationProductsWithSetup(togetherBetterDealsProdList) || []
          /**
           * 新样式且弹窗内需要把切换的skc同步到主商品
           */

          _item = getFormatCommonCartProductItem(productsList[0])
          // 同步主商品
          if (item._cartInfo.isMainGoods) _item._cartInfo.isMainGoods = true
        } else {
          _item = item
        }
        // 自动勾选商品
        _item._cartInfo.selectStatus = !_item._cartInfo.isSoldOut
        _item._cartInfo.warnStatus = false
        const uptSkuItem = setCommonCartProductSkuInfo(skuCode, _item)
        next && next()
        if (!uptSkuItem) return
        // 更新价格
        setTimeout(() => {
          this.updateComboBuyPrice()
        }, 100)
        onAfterUpdateFn && onAfterUpdateFn(uptSkuItem)
      },
      async baseSelectSizeClick(options = {}) {
        const { goodsId } = this
        const { item, index } = options

        const { goods_id, goods_img, mall_code } = item
        const imgRatio = await getImageRatio(goods_img)

        // 只有主商品显示到手价
        const showEstimatedPrice = !!item?._cartInfo?.isMainGoods && goodsId === item.goods_id && !this.is_hit_compliance_mode

        await setCommonQuickAddSelectedAttrIndexData(item) // 设置快车回显依赖的selectedAttrIndex参数

        if (canUseNewQuickAdd('details')) {
          getQuickAddCartInstance(SCENE_MAP.DETAILS_ATTR).open({
            goods_id: goods_id,
            mallCode: mall_code,
          }, {
            extendedParam: {
              imgRatio
            },
            featureConfig: {
              showEstimatedPrice,
              needQtySelect: false,
            },
            salesAttrConfig: {
              selectedAttrIndex: item._cartInfo.selectedAttrIndex
            },
            plugins: [plugins.salesAttrUpdatePlugin(context => {
              const { skuInfo, productInfo, mallCode } = context || {}
              const params = {
                mallCode,
                skuCode: skuInfo.sku_code || '',
                goods_id: productInfo?.goods_id,
              }
              this.updateSalesAttr(params, options)
            })],
            excludePlugins: ['FindSimilar'],
          })
        } else {
          this.$quickAdd.open({
            goods_id,
            index,
            imgRatio,
            selectedAttrIndex: item._cartInfo.selectedAttrIndex,
            mallCode: mall_code,
            isShowSelectUpdate: true, //  进入销售属性选择模式
            isShowWishBtn: true,
            showSimilarBtn: false, // 不显示相似推荐按钮
            hideQtySelect: true, // 不显示数量选择
            showEstimatedPrice,
            showAbPrice: true, // 展示ab价
            hideFindSimilarFloat: true, // 隐藏相似推荐
            // item_id: this.item.id,
            // quantity: this.item.quantity,
            clickCallBack: {
              // edit: this.editComplete,
              selectUpdateHook: async ({ state, next }) => {
                const params = {
                  skuCode: state?.attrs?.skuCode || '',
                  goods_id: state?.item?.goods_id,
                  mallCode: state?.item?.mallCode,
                }
                this.updateSalesAttr(params, options, next)
              }
            }
          })
        }

      },
      async baseAfterAddToCart({ ignoreSelectors = [] } = {}) {
        const { setGoodsDetailScroll } = this
        // 走加车后推荐逻辑
        setTimeout(() => {
          setGoodsDetailScroll({ ignoreSelectors })
        }, 500)
      },
      // 更新组合购价格
      async updateComboBuyPrice() {
        this.loadingPrice = true
        const selectedGoodsIdSkus = this.cSelectItems?.map(d => d.sku_code ? `${d.goods_id},${d.mall_code},${d.sku_code}` : `${d.goods_id},${d.mall_code}` ).join('-')
        // 计算套装价
        const price = await this.get_combo_buy_price({ selectedGoodsIdSkus })
        this.loadingPrice = false
        return price
      }
    }
  }
}
