<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'CommonCartSize',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleSelectSize(e) {
      e.stopPropagation()
      this.$emit('select-size')
    }
  },
  render() {
    const { item, handleSelectSize } = this
    const {
      _cartInfo = {},
      skuCombineName,
      skcImageUrl
    } = item
    const {
      skuCode,
      warnStatus,
    } = _cartInfo

    if (!skuCombineName) {
      return null
    }

    const isNotSelectSku = !skuCode

    return (
      <div class="common-cart-size_wrap" vOn:click={handleSelectSize}>
        <div
          class={`common-cart-size ${warnStatus ? 'is-warn' : ''} ${isNotSelectSku ? 'not-sku' : ''}`}
        >
          {skcImageUrl ? (
            <img
              class="color"
              src={skcImageUrl}
            />
          ) : null}
          <div class="text">
            {skuCombineName}
          </div>

          <i class="suiiconfont sui_icon_more_down2_12px more-icon" aria-hidden="true"></i>
        </div>
        { warnStatus && <Icon
          name="sui_icon_reported_18px"
          size="0.42667rem"
          color="#BF4123"
          class={{ 'warn-icon': true, show: warnStatus }}
        /> }
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.common-cart-size_wrap {
  display: flex;
  align-items: center;
  padding-top: 0.21rem;
  max-width: 5.733rem;

  .warn-icon {
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 0.11rem;
    &.show {
      opacity: 1;
    }
  }
}
.common-cart-size {
  height: 0.53333rem;
  padding: 0.10667rem;
  color: #666;
  font-size: 0.32rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.04rem;
  background: #f6f6f6;
  overflow: hidden;
  transition: color 0.3s;

  &.not-sku {
    border: 1px solid #E5E5E5;
    color: #000;
    background: #fff;
  }
  &.is-warn {
    background: #fff6f6;
    color: #C44A01;
    border: none;
  }

  .color {
    min-width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.1rem;
  }

  .text {
    // 超出省略
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .more-icon {
    margin-left: 0.08rem;
    font-size: 0.32rem;
  }
}
</style>
