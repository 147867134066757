<template>
  <div
    ref="reference"
    class="recommend-v2"
  >
    <template v-if="dataConfig && !componentsLoading">
      <!-- 标题 S -->
      <div
        v-if="dataConfig.title && dataConfig.title.data && show.title"
        :da-event-expose="recommendService.title.config && recommendService.title.config.titleDaExposeEventId"
        class="recommend-v2__title"
        :class="{
          'recommend-v2__title-c': recommendService.title.config && recommendService.title.config.titlePosition == 'center',
          'recommend-v2__title-r': recommendService.title.config && recommendService.title.config.titlePosition == 'right',
          'recommend-v2__title-pdb': show.tab || sliderRecommend,
          'recommend-v2__title-mtb': !tabSliderRecommend
        }"
        @click="handleClickTitle"
      >
        <!-- 主标题 -->
        <p
          class="recommend-v2__title-main"
          @click="onSliderItemClick"
        >
          <span
            class="recommend-v2__title-txt"
          >
            {{ dataConfig.title.data.name }}
          </span>
          <span
            v-if="sliderRecommend && initialProduct.config && initialProduct.config.showViewMore"
            data-localtion="up"
            :da-event-click="initialProduct.analysis && initialProduct.analysis.viewMoreItem && initialProduct.analysis.viewMoreItem.itemDAEventClickId || ''"
            class="recommend-v2__title-viewall"
          >{{ language.SHEIN_KEY_PWA_15040 }} <i class="suiiconfont sui_icon_more_right_16px"></i></span>
        </p>
        <!-- 副标题 -->
        <p
          v-if="dataConfig.title.data.subName"
          class="recommend-v2__title-sub"
        >
          {{ dataConfig.title.data.subName }}
        </p>
      </div>
      <!-- 标题 E -->

      <!-- tab S -->
      <RecommendTab
        v-if="show.tab"
        :tab-data="dataConfig.tab && dataConfig.tab.data || []"
        :is-tolerant="dataConfig.tab.isFaultTolerant"
        :active-tab="activeTab"
        v-bind="recommendService.tab"
        @onTabClick="onTabClick"
      />
      <!-- tab E -->

      <!-- list S -->
      <template v-if="tabSliderRecommend">
        <RecommendSwiper
          :tab-data="dataConfig.tab && dataConfig.tab.data || []"
          :tab-product="tabProduct"
          :active-tab="activeTab"
          :need-activated-scroll="needActivatedScroll"
          v-bind="recommendService.product"
          @sliderToTab="onTabClick"
          @open-quick-add="onOpenQuickAdd"
          v-on="$listeners"
        />
      </template>
      <template v-else>
        <template v-for="(list, key) in tabProduct">
          <template v-if="!sliderRecommend">
            <RecommendList
              v-if="!hideWaterfall"
              v-show="list.id == activeTab.sku_cate_id"
              :key="list.id || key"
              :ref="`RecProductList${list.id}`"
              :products="getListData(list.data)"
              :data-type="list.dataType || 'own'"
              :is-tolerant="list.isFaultTolerant"
              :is-end="list.end"
              :placeholder-status="!list.hideHolder"
              :hide-waterfall="hideWaterfall"
              :is-placeholder-optimize="isPlaceholderOptimize"
              :survey-question="key === 0 ? surveyQuestion : {}"
              v-bind="list.config || recommendService.product"
              @onViewMoreClick="onViewMoreClick"
              @onReRequest="onReRequest"
              @open-quick-add="onOpenQuickAdd"
              v-on="$listeners"
            />
          </template>

          <RecommendSliderList
            v-else
            v-show="list.id == activeTab.sku_cate_id"
            :key="list.id || key"
            :ref="`RecProductList${list.id}`"
            :products="list.data"
            :placeholder-status="!list.hideHolder"
            v-bind="list.config || recommendService.product"
            @onSliderItemClick="onSliderItemClick"
            @open-quick-add="onOpenQuickAdd"
            v-on="$listeners"
          />
        </template>
      </template>
      <!-- list E -->
    </template>
    <RecPlaceHolder
      v-else
      :slider-recommend="sliderRecommend || tabSliderRecommend"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import RecommendSwiper from './components/swiperSlider.vue'
import RecPlaceHolder from './placeHolder.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { checkOneClickPayQualification } from './index.js'
import Analysis from './analysis'

const { language } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'RecommendIndex',
  components: {
    RecPlaceHolder,
    RecommendSliderList: () => import(/* webpackChunkName: "recommend-sliderList" */'./components/sliderList.vue'),
    RecommendTab: () => import(/* webpackChunkName: "recommend-tab" */'./components/tab.vue'),
    RecommendList: () => import(/* webpackChunkName: "recommend-list" */'./components/list.vue'),
    RecommendSwiper,
  },
  inheritAttrs: false,
  props: {
    /**
     * 推荐服务的实例
     */
    recommendService: {
      require: true,
      type: Object,
      default() {
        return null
      }
    },

    /**
     * 是否是横向滑动的组件
     */
    sliderRecommend: {
      type: Boolean,
      default: false
    },
    /**
     * 是否是横向滑动的组件
     * 而且有tab
     */
    tabSliderRecommend: {
      type: Boolean,
      default: false
    },
    // 隐藏瀑布流组件，因渲染性能问题，瀑布流组件可从外部控制延期渲染
    hideWaterfall: {
      type: Boolean,
      default: false,
    },
    // 多tab推荐组件是否需要在activated中保持横向滚动距离
    needActivatedScroll: {
      type: Boolean,
      default: false,
    },
    isPlaceholderOptimize: {
      type: Boolean,
      default: false,
    },
    surveyQuestion: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      language,

      componentsLoading: true,
      dataConfig: null,
      analysis: null,
      activeTab: {
        sku_cate_id: ''
      },
      tabProduct: [],
      show: {
        tab: false,
        title: false
      },
    }
  },
  computed: {
    initialProduct() {
      return this.recommendService?.product || {}
    }
  },
  watch: {
    recommendService: {
      handler(v, o) {
        if (typeof window === 'undefined') return
        if (!o && v) this.init()
      },
      immediate: true
    }
  },
  methods: {
    async init() {
      const { tab: initialTab, analysis: initialAnalysis } = this.recommendService
      const dataConfig = await this.recommendService.init() || {}
      this.dataConfig = dataConfig
      //1. 如果有tab 当前的tab
      if (dataConfig.tab && dataConfig.tab.data && dataConfig.tab.data.length) {
        let tabIndex = 0
        dataConfig.tab.data.forEach(item => {
          if (item.hasOwnProperty('index')) return    // 写死优与遍历给默认
          tabIndex += 1
          item.index = tabIndex
        })
        this.activeTab = dataConfig.tab.data[0]

        // tab数量至少为2
        this.show.tab = dataConfig.tab.data.length > 1
        // 给一个默认的商品数据
        this.show.tab && dataConfig.tab.data.forEach(tab => { this.setProductListInfo({ id: tab.sku_cate_id, }) })
      }

      //2. 商品的数据
      if (dataConfig.product) {
        const { data = [], end, dataType, isFaultTolerant, total } = dataConfig.product
        // 无论有没有商品都应该set
        // 空数据的结果应该由列表里面自行决定展示空状态结果
        this.setProductListInfo({
          id: this.activeTab.sku_cate_id,
          hideHolder: true,
          data,
          end,
          dataType,
          isFaultTolerant: Boolean(isFaultTolerant),
        })
        // 有数据才能展示标题
        // this.show.title = data.length > 0
        this.show.title = true

        this.$emit('recommendListReady', {
          // code: this.code,
          list: data,
          faultTolerant: Boolean(isFaultTolerant),
          total,
        })
      } else {
        // 没有商品也要emit一个空结果
        this.$emit('recommendListReady', { list: [], })
      }
      // 2.1 tab的扩展配置 （必须放在商品数据之后/请求完商品之后）
      if (initialTab && initialTab.config) {
        const { settingTabAfterReady } = initialTab.config
        if (typeof settingTabAfterReady == 'function' && this.show.tab) {
          const { show = true } = settingTabAfterReady(this.tabProduct[0])
          this.show.tab = show
        }
      }

      // 3. 埋点初始化
      setTimeout(() => {
        this.componentsLoading = false
        this.analysis = new Analysis({
          container: this.$refs['reference'],
          analysis: initialAnalysis,              // 初始化公共的配置
        })
        this.setBoxAnalysisInfo()
      }, 0)
    },

    setBoxAnalysisInfo() {
      const { config: initialConfig, multiProduct, product: initialProduct } = this.recommendService
      if (this.analysis._once) return
      this.analysis._once = !multiProduct

      const tabProduct = this.tabProduct.find(i => i.id == this.activeTab.sku_cate_id)

      const { dataType, isFaultTolerant, config: curProductInitialConfig = null } = tabProduct || this.tabProduct[0] || {}
      this.analysis.setAnalysisInfo({
        analysisInfo: {           // 当前列表商品的配置
          ...(curProductInitialConfig?.analysis || initialProduct?.analysis || {}),
          isFaultTolerant,
          dataType
        },
        activeTab: this.activeTab,
        config: initialConfig,    // 整个推荐位的配置
      })
    },

    // 标题的点击
    handleClickTitle() {
      const { title = {} } = this.recommendService
      if (title?.config?.tapToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    // tab的点击事件
    async onTabClick(tab, event) {
      if (this.activeTab.sku_cate_id == tab.sku_cate_id) return
      const { tab: initialTab, } = this.recommendService
      const { tabClickPreChange, tabClickDone } = initialTab.config || {}
      const RreLisRef = this.$refs[`RecProductList${this.activeTab.sku_cate_id}`]
      typeof tabClickPreChange === 'function' && tabClickPreChange({
        id: this.activeTab.sku_cate_id,
        preLisRef: RreLisRef && RreLisRef[0]?.$el
      })

      // 1. 存当前tab
      this.activeTab = tab
      // 埋点
      const { analysis } = initialTab
      if (analysis) {
        this.analysis.setContainerAttr({
          'data-tab-list': `${tab.index}\`${tab.sku_cate_id}\`-\`${tab.sku_cate_nm}`
        })
        event?.target && analysis.tabDAClickEventId && daEventCenter.triggerNotice({
          daId: analysis.tabDAClickEventId,
          target: event.target
        })
      }
      this.$nextTick(() => {
        if (this.$el.getBoundingClientRect){
          const { top } = this.$el.getBoundingClientRect()
          typeof tabClickDone === 'function' ? tabClickDone({
            id: tab.sku_cate_id,
            top,
            recRef: this.$el
          }) : (top < 0 && this.$el.scrollIntoView())
        }
      })

      // 2. 是否已经获取过
      // 如果已经获取过 hideHolder 为true
      const result = this.tabProduct.find(item => (item.id == tab.sku_cate_id) && !!item.hideHolder)
      result && this.setBoxAnalysisInfo()
      if (!result) {
        this.setBoxAnalysisInfo()
        // 2.1 请求
        // 获取对应的商品
        const productExtParams = this.recommendService.productExtParams(tab)
        const { products, end, dataType, isFaultTolerant } = await this.recommendService.getProductData(productExtParams)
        // 2.2 商品存起来
        this.setProductListInfo({
          id: tab.sku_cate_id,
          hideHolder: true,
          data: products,
          end,
          dataType,
          isFaultTolerant: Boolean(isFaultTolerant),
        })
      }
    },

    // 设置tab 对应商品的数据以及信息
    setProductListInfo({ id, data = [], end = false, dataType, isFaultTolerant, hideHolder = false }) {
      const item = this.tabProduct.find(item => item.id == id)
      if (item) {
        // 如果已经有，则修改
        item.hideHolder = true
        item.data = data
        item.end = end
        item.dataType = dataType
        item.isFaultTolerant = Boolean(isFaultTolerant)
      } else {
        const { multiProduct, getRenderProductConfig } = this.recommendService
        let config = null

        // multiProduct 有存在多个不同的商品请求信息
        if (multiProduct) {
          // 对应的tab信息
          this.dataConfig?.tab?.data?.forEach(tab => {
            if (tab.sku_cate_id == id) {
              // 不同的tab可能会渲染不同的商品信息配置
              config = getRenderProductConfig.call(this.recommendService, tab)
            }
          }) 
        }

        this.tabProduct.push({
          id,
          data,
          end,
          dataType,
          hideHolder,
          isFaultTolerant: Boolean(isFaultTolerant),
          config
        })
      }
    },

    // 商品列表点击viewMore
    async onViewMoreClick(payload) {
      const { pageNum, loading = () => {} } = payload
      // 1. 请求更多的商品
      loading(true)
      const tab = this.activeTab
      const productExtParams = this.recommendService.productExtParams(tab)
      const { products, end, error = 0 } = await this.recommendService.getProductData(productExtParams, {
        pageNum
      })
      loading(false, error)
      // 2.2 商品存起来
      this.tabProduct.forEach(item => {
        if (item.id == tab.sku_cate_id) {
          item.data = item.data.concat(products)
          item.end = end
        }
      })
    },

    // 点击try it again
    async onReRequest(payload) {
      const tab = this.activeTab
      this.tabProduct.forEach(item => {
        if (item.id == tab.sku_cate_id) {
          item.hideHolder = false
        }
      })
      const { pageNum } = payload
      const productExtParams = this.recommendService.productExtParams(tab)
      const { products, end } = await this.recommendService.getProductData(productExtParams, {
        pageNum
      })
      // 商品存起来
      this.tabProduct.forEach(item => {
        if (item.id == tab.sku_cate_id) {
          item.data = item.data.concat(products)
          item.end = end
          item.hideHolder = true
        }
      })
    },

    // 横向滑动商品点击
    onSliderItemClick(payload = {}, isAllItems = false) {
      this.$emit('clickSliderItem', payload, isAllItems)
      // 如果不是横滑组件 返回
      if (!this.sliderRecommend || this.initialProduct?.config?.itemClickToDetail) return
      // 跳转到专题
      this.recommendService.onSliderItemClick(payload)
    },

    // 点击唤起加车
    onOpenQuickAdd(payload) {
      const { goods_id, index, target, mall_code = '', imgRatio = '3-4' } = payload
      // 推荐列表的商品加车traceId与商品曝光点击一致
      window.gbExposeTraceid && typeof window.gbExposeTraceid == 'function' && window.gbExposeTraceid('setProduct', {
        goods_id,
        traceid: this.analysis?.traceId || ''
      })

      const { config, product: initialProduct } = this.recommendService
      const { code } = config
      const curProductCon = this.tabProduct.find(i => i.id == this.activeTab.sku_cate_id)
      const renderProduct = curProductCon.config || initialProduct
      const { analysis, config: productConfig = {} } = renderProduct
      const { showQuickAddEstimatedPrice } = config?.sceneConfig || {}
      this.$quickAdd && this.$quickAdd.open({
        goods_id,
        index,
        imgRatio,
        mallCode: mall_code,
        // lockmall: true,
        showEstimatedPrice: showQuickAddEstimatedPrice,
        analysisConfig: {
          code: code || analysis?.code || '',
          sourceTarget: target || null,
          sa: {
            from: analysis?.activeFrom || '',
            activity_from: analysis?.activeFrom || '',
          },
        },
        clickCallBack: {
          complete: ({ data } = {}) => {
            if (data?.code == '0') {
              productConfig.afterQuickAddFilter && this.handleAfterAddcart(goods_id)
            }
            setTimeout(() => {
              window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
          },
          // 尺码选择
          selectSize: (params) => {
            analysis.sizeDaEventClickId && daEventCenter.triggerNotice({
              daId: analysis.sizeDaEventClickId,
              target: {
                dataset: {
                  attr_id: params.attr_id,
                  attr_value_id: params.attr_value_id || '',
                  attr_value: params.attr_value_name,
                  location: 'popup'
                }
              },
              extraData: params
            })
          },
          isActive: (open) => {
            this.$emit('quickAddActive', open)
          },
          handleOneClickPayComplete: (params) => {
            const { type, extraData = {} } = params || {}
            if (type === 'success') {
              const { merge_buy_billno = '' } = extraData?.addPurchaseRelation || {}
              this.handleVerifyOneClickPayBillno(merge_buy_billno)
            }
          },
        }
      })
    },
    handleAfterAddcart(goodsId) {
      const curProductCon = this.tabProduct.find(i => i.id == this.activeTab.sku_cate_id)
      // 过滤加车之后的商品
      curProductCon.data = curProductCon.data.filter(_ => {
        return _.goods_id != goodsId
      })
      // 过滤之后没商品
      if (curProductCon.data.length === 0) {
        // 需要隐藏当前的tab
        const tabData = this.dataConfig?.tab?.data
        if (!tabData || tabData.length <= 1) {
          // 如果没有tab || 最后一个tab的商品空了
          // 没有商品直接emit一个空结果
          this.$emit('recommendListReady', { list: [], })
        }

        // 如果有tab
        const activeTabIndex = tabData.findIndex(_ => _.sku_cate_id == this.activeTab.sku_cate_id)
        // 1. 干掉当前的tab
        tabData.splice(activeTabIndex, 1)
        // 1.2 同时干掉商品
        this.tabProduct = this.tabProduct.filter(_ => _.id != curProductCon.id)

        // 2. 当前tab的active
        const nextTab = tabData.length > activeTabIndex + 1 ? tabData[activeTabIndex] : tabData[tabData.length - 1]
        if (nextTab) this.onTabClick(nextTab)
        if (tabData.length <= 1) this.show.tab = false
      }
    },
    getListData(listData) {
      const renderCount = this.recommendService?.product?.config?.renderCount || 0
      if(renderCount) {
        return listData.slice(0, renderCount)
      }
      return listData
    },
    handleVerifyOneClickPayBillno(billno) {
      checkOneClickPayQualification({ billno }).then(qualificationInfo => {
        if (!qualificationInfo?.billno || !qualificationInfo?.support_one_click_pay) {
          this.$store.commit('newProductDetail/updateOneClickPayTimeStutas', false)
        }
      })
    }
  },
}
</script>

<style lang="less">
.recommend-v2 {
  margin-bottom: 0.2667rem;
  &__title {
    text-align: left;
    background-color: #fff;
    padding: 0.2667rem 0.32rem 0 0.32rem;
  }
  &__title-mtb {
    padding-bottom: 0.16rem;
  }
  &__title-pdb {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__title-r {
    text-align: right;
  }
  &__title-c {
    text-align: center;
    padding-left: 0.5333rem;
    padding-right: 0.5333rem;
  }

  &__title-main {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: @sui_color_gray_dark1;
    .font-dpr(28px);
  }
  &__title-sub {
    .font-dpr(24px);
    color: #999;
    /* rw:begin */
    color: @sui_color_gray_light1;
  }
  &__title-txt {
    flex: 1;
    /* rw:begin */
    font-family: Adieu;
    .font-dpr(24px);
    color: @sui_color_gray_dark1;
    margin-bottom: 0.16rem;
  }
  &__title-txtholder {
    color: #f6f6f6;
    background-color: #f6f6f6;
    border-radius: 4px;
  }
  &__title-viewall {
    line-height: .3867rem;
    font-size: 12px;
    font-weight: normal;
    color: @sui_color_gray_dark3;
    .suiiconfont {
      color: @sui_color_gray_light1;
    }
  }
  &__tab {
    .recommend-v2__tab-item {
      padding: 0.16rem 0.64rem 0.32rem 0;
    }
  }
}
.mgds-recommend {

  .mgds-goodsd-title {
    padding: .32rem;
    background: #fff;
    font-weight: 400;
    text-transform: capitalize;
    & when (@IS_RW) {
      font-family: 'Adieu';
    }
    /* stylelint-disable-next-line */
    &.mgds-goodsd-title-recommend-container {
      margin-top: .2667rem;
    }
    &.mgds-goodsd-title__align-c {
      text-align: center;
      padding-left: 0.5333rem;
      padding-right: 0.5333rem;
    }
    &.mgds-goodsd-title__align-r {
      .txt-r();
    }
    /* stylelint-disable-next-line */
    .mgds-goodsd-title-recommend {
      line-height: 0.5067rem;
      font-weight: bold;
      .font-dpr(28px);
      color: #333;
    }
    /* stylelint-disable-next-line */
    .mgds-goodsd-sub-title-recommend {
      line-height: 0.48rem;
      .font-dpr(24px);
      color: #999;
    }
  }

  .recommend {

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        &__info {
          flex: 1;
        }

        &__view-all {
          line-height: 0.3867rem;
          .font-dpr(24px);
          color:#999999;
          /* stylelint-disable-next-line */
          [class*="iconfont"] {
            color: #999;
          }
        }
      }
    }

    &__goodsset {
      position: sticky;
      z-index: @zindex-header;
      height: 1.1733rem;
      line-height: 1.1733rem;
      padding: 0 .32rem;
      overflow-y: hidden;
      overflow-x: scroll;
      white-space: nowrap;
      background:rgba(255,255,255,0.96);

      .goodsset {
        &__item {
          display: inline-block;
          position: relative;
          .font-dpr(24px);
          color: #999;
          width: auto;

          &_active {
            color: #222;
            font-weight: bold;
          }

          &_prompt {
            &:after {
              content: '';
              display: inline-block;
              width: .16rem;
              height: .16rem;
              background-color: @sui_color_guide;
              border-radius: 100%;
              vertical-align: text-top;
            }
          }
        }
      }

      &_width-average {
        .flexbox();
        justify-content: space-between;

        .goodsset__item {
          flex: 1;
          // max-width: 50%;
          padding: 0 0.2133rem;
          text-align: center;
          .text-overflow();
        }
      }

      &_equidistance {

        .goodsset__item {
          .padding-r(0.2667rem);
          /* stylelint-disable-next-line */
          + .goodsset__item {
            .padding-l(0.2667rem)
          }
        }
      }

      &_one-tab {
        .goodsset__item {
          padding: 0;
          text-align: left;
          .txt-l();
          .font-dpr(28px);
        }
      }
    }

    &__goods-container {
      padding: 0.2667rem .32rem 0.32rem .32rem;
      background-color: #fff;
      .goods-bottom-badges {
        >div,
        >span {
          margin-bottom: .2134rem;
        }
      }
    }

    &__goods-item-two {
      display: inline-block;
      .margin-r(.3rem);
      margin-bottom: 0.4267rem;
      &:nth-child(2n) {
        .margin-r(0);
      }
    }

    &__goods-item-three {
      display: inline-block;
      .margin-r(.213rem);
      margin-bottom: .32rem;
      &:nth-child(3n) {
        .margin-r(0);
      }
      .product-item__discount {
        font-size: 9px;
      }
    }

    /* stylelint-disable */
    &__goods-item-three-point-five {
      display: inline-block;
      width: 2.6133rem;
      .margin-r(0.2133rem);
      // .padding-r(0.0533rem);
      margin-bottom: 0;
      &:last-child {
        .padding-r(0);
        .margin-r(0);
      }
      &.swiper-slide {
        width: 2.6133rem;
      }
      .product-item__discount {
        font-size: 9px;
      }
    }
    /* stylelint-enable */

    /* stylelint-disable-next-line */
    &__goods-item-two-potin-three {
      display: inline-block;
      width: 4rem;
      .margin-r(0.21rem);
      margin-bottom: 0;
    }
  }

  &.recommend-tab-list {
    .mgds-goodsd {
      /* stylelint-disable-next-line */
      .goodsl-wrap {
        padding: 0;
      }
    }
  }

  // tags
  .filter-ctn {
    position: sticky;
    width: 100%;
    z-index: 2; /* stylelint-disable-line declaration-property-value-blacklist */
    .cloud-tags-ctn {
      padding-top: 0;
      padding-bottom: 0.32rem;
    }
  }
}
</style>
